<template>
  <div class="t-holder">
    <vue-masonry-wall
      :items="templates"
      v-if="templates.length > 0"
      :options="{ width: width, padding: 12 }"
    >
      <template v-slot:default="{ item }">
        <div class="card">
          <div class="card-image" @click="$emit('template-selected', item.blob)">
            <img :src="item.rendered" v-if="item.rendered" />
            <div v-else class="image-placeholder"></div>
          </div>

          <div class="loader-container" v-if="!item.rendered">
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-blue-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div><div class="gap-patch">
                  <div class="circle"></div>
                </div><div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vue-masonry-wall>

    <Editor
      v-if="templateId != ''"
      ref="editor"
      style="transform:translateX(-999%); position: fixed;"
      :prefillObject="prefillObject"
      :templateId="templateId"
      :preRender="true"
      @image-generated="imageGenerated"
    />
  </div>
</template>
<script>
import VueMasonryWall from "vue-masonry-wall";
import templates from "./templates";
import Editor from "./Editor";
export default {
  props: {
    categoryTypeId: {
      type: String,
      default: "2",
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 300,
    },
  },
  components: { VueMasonryWall, Editor },
  data() {
    return {
      templates: [],
      templateId: "",
    };
  },
  mounted() {
    for (var key in templates) {
      let item = templates[key];
      item.id = key;
      if (item.template.blocks.length == 0) continue;
      if (item.categoryId == this.categoryTypeId) this.templates.push(item);
    }

    this.$nextTick(() => {
      this.renderNext();
    });
  },
  methods: {
    imageGenerated(image) {
      let itemIndex = this.templates
        .map((item) => item.id)
        .indexOf(this.templateId);

      if (itemIndex == -1) return;
      this.templateId = "";
      this.templates[itemIndex].rendered = URL.createObjectURL(image)
      this.templates[itemIndex].blob = image
      this.$nextTick(() => {
        this.renderNext();
      });
    },
    renderNext() {
      for (let item in this.templates) {
        if (this.templates[item].rendered) continue;
        if (this.templates[item].template.blocks.length == 0) continue;
        this.templateId = this.templates[item].id;
        break;
      }
    },
  },
};
</script>
<style scoped>
.t-holder {
  padding: 10px;
}
.loader-container{
  position: absolute;
    left: 0;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-placeholder{
  width: 100%;
  padding-top: 100%;
}
</style>
<template>
  <div class="controls">
    <div
      class="btn"
      :class="{
        'white black-text': !selectedBlock.text.isBold,
        blue: selectedBlock.text.isBold,
      }"
      @click="selectedBlock.text.isBold = !selectedBlock.text.isBold"
    >
      Bold
    </div>
    <div
      class="btn"
      :class="{
        'white black-text': !selectedBlock.text.isItalics,
        blue: selectedBlock.text.isItalics,
      }"
      @click="selectedBlock.text.isItalics = !selectedBlock.text.isItalics"
    >
      Italics
    </div>
    <div
      ref="size-dropdown"
      class="dropdown-trigger btn blue"
      data-target="size-dropdown"
    >
      Size
    </div>
    <ul id="size-dropdown" class="dropdown-content">
      <li class="collection-header">
        <div>{{ selectedBlock.text.fontSize }} px</div>
        <input
          type="range"
          v-model="selectedBlock.text.fontSize"
          max="200"
          min="10"
          step="1"
        />
      </li>
      <li><a @click="selectedBlock.text.fontSize = 10">10</a></li>
      <li><a @click="selectedBlock.text.fontSize = 30">30</a></li>
      <li><a @click="selectedBlock.text.fontSize = 50">50</a></li>
      <li><a @click="selectedBlock.text.fontSize = 100">100</a></li>
      <li><a @click="selectedBlock.text.fontSize = 150">150</a></li>
    </ul>
    <div
      ref="font-dropdown"
      class="dropdown-trigger btn blue"
      data-target="font-dropdown"
    >
      Font
    </div>
    <ul id="font-dropdown" class="dropdown-content">
      <li v-for="(font, index) in fonts" :key="index">
        <a
          @click="selectedBlock.text.font = font.name"
          :style="'font-family: ' + font.name"
          >{{ font.family }}</a
        >
      </li>
    </ul>

    <div
      ref="color-dropdown"
      class="dropdown-trigger btn blue"
      data-target="color-dropdown"
    >
      Color
    </div>
    <ul id="color-dropdown" class="dropdown-content">
      <chrome-picker @input="updateColor" v-model="selectedBlock.text.color" />
    </ul>
    <div
      ref="background-dropdown"
      class="dropdown-trigger btn blue"
      data-target="background-dropdown"
    >
      Background
    </div>
    <ul id="background-dropdown" class="dropdown-content">
      <chrome-picker
        @input="updateBackgroundColor"
        v-model="selectedBlock.text.background"
      />
    </ul>
    <div
      ref="align-dropdown"
      class="dropdown-trigger btn blue"
      data-target="align-dropdown"
    >
      Align
    </div>
    <ul id="align-dropdown" class="dropdown-content">
      <li>
        <a @click="selectedBlock.text.alignment = 'left'"
          ><i class="material-icons">format_align_left</i></a
        >
      </li>
      <li>
        <a @click="selectedBlock.text.alignment = 'center'"
          ><i class="material-icons">format_align_center</i></a
        >
      </li>
      <li>
        <a @click="selectedBlock.text.alignment = 'right'"
          ><i class="material-icons">format_align_right</i></a
        >
      </li>
      <li>
        <a @click="selectedBlock.text.alignment = 'justify'"
          ><i class="material-icons">format_align_justify</i></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import fonts from "../fonts";
import {Chrome} from "vue-color";
export default {
  components: {
    'chrome-picker' : Chrome,
  },
  props: ["selectedBlock"],
  data() {
    return {
      fonts: fonts,
    };
  },
  mounted() {
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["size-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
    });

    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["font-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
    });
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["align-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
    });
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["color-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
      closeOnClick: false,
    });
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["background-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
      closeOnClick: false,
    });

  
  },
  watch: {
    selectedBlock(newValue) {
      this.$emit("update-selection", newValue);
    },
  },
  methods: {
    updateBackgroundColor(color) {
      this.selectedBlock.text.background = color.hex;
    },
    updateColor(color) {
      this.selectedBlock.text.color = color.hex;
    },
  },
};
</script>
<style scoped>
.controls {
  margin: 20px;
  text-align: center;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-holder"},[(_vm.historyTemplates.length > 0)?_c('h5',[_vm._v("History")]):_vm._e(),(_vm.historyTemplates.length > 0)?_c('vue-masonry-wall',{attrs:{"items":_vm.historyTemplates,"options":{ width: 300, padding: 12 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image",on:{"click":function($event){return _vm.$emit('template-selected', item._id)}}},[(item.rendered)?_c('img',{attrs:{"src":_vm.getURLFromBlob(item.rendered)}}):_c('div',{class:{
              'banner-placeholder': _vm.categoryTypeId == '1',
              'image-placeholder': _vm.categoryTypeId == '2',
            }})])])]}}],null,false,1714322321)}):_vm._e(),_c('h5',[_vm._v("Templates")]),(_vm.templates.length > 0)?_c('vue-masonry-wall',{attrs:{"items":_vm.templates,"options":{ width: 300, padding: 12 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image",on:{"click":function($event){return _vm.$emit('template-selected', item.id)}}},[(item.template.blocks.length == 0)?_c('img',{attrs:{"src":item.thumbnail}}):(item.rendered)?_c('img',{attrs:{"src":item.rendered}}):_c('div',{class:{
              'banner-placeholder': _vm.categoryTypeId == '1',
              'image-placeholder': _vm.categoryTypeId == '2',
            }})]),(!item.rendered && item.template.blocks.length != 0)?_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"preloader-wrapper big active"},[_c('div',{staticClass:"spinner-layer spinner-blue-only"},[_c('div',{staticClass:"circle-clipper left"},[_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"gap-patch"},[_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"circle-clipper right"},[_c('div',{staticClass:"circle"})])])])]):_vm._e()])]}}],null,false,311561329)}):_vm._e(),(_vm.templateId != '')?_c('Editor',{ref:"editor",staticStyle:{"transform":"translateX(-999%)","position":"fixed"},attrs:{"prefillObject":_vm.prefillObject,"templateId":_vm.templateId,"preRender":true},on:{"image-generated":_vm.imageGenerated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div ref="baseModal" class="baseModal modal" :class="{'fullscreen':fullscreen}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fullscreen: false
    };
  },
  methods: {
    isFullscreen(isFullscreen) {
      this.fullscreen = isFullscreen;
    },
    open() {
      const vm = this;
      var elems = vm.$refs.baseModal;
      // eslint-disable-next-line
      M.Modal.init(elems, {
          onCloseEnd:function(){
              vm.$emit("onClose",true)
          }
      });
      var elem = vm.$refs.baseModal;
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(elem);
      instance.open();
    },
    close(){
      const vm = this;
      var elem = vm.$refs.baseModal;
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(elem);
      instance.close();
    }
  }
};
</script>
<style scoped>
.fullscreen {
  width: 90vw !important;
  max-height: 90vh !important;
  height: 90vh !important;
  top: 5vh !important;
}
@media only screen and (max-width: 600px) {
  .fullscreen {
    width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    top: 0 !important;
  }
}
</style>
<template>
  <div class="controls">
    <div class="btn blue" @click="$emit('remove-background')">Remove Background</div>
  </div>
</template>

<script>
export default {
  props: ["selectedBlock"],
  watch: {
    selectedBlock(newValue) {
      this.$emit("update-selection", newValue);
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.controls {
  margin: 20px;
  text-align: center;
}
</style>
<template>
  <div class="row">
    <div class="col s12 m6" style="margin-top: 1rem">
      <div class="card" style="border-radius: 5px">
        <div class="card-content">
          <span class="card-title">App Details</span>
          <img :src="institutionDetails.logo" height="100" width="100" />
          <p>
            <b>Name :</b>
            {{ institutionDetails.name }}
          </p>
          <p>
            <b>Code :</b>
            {{ institutionDetails.code }}
          </p>
          <p>
            <b>Address :</b>
            {{ institutionDetails.address }}
          </p>
          <p>
            <b>Contact Name :</b>
            {{ institutionDetails.contact_person_name }}
          </p>
          <p>
            <b>Contact Number :</b>
            {{ institutionDetails.contact_person_mobile }}
          </p>
          <p>
            <b>Contact Email :</b>
            {{ institutionDetails.contact_person_email }}
          </p>
        </div>
        <div class="card-action" style="border-radius: 5px">
          <a
            class="waves-effect waves-dark blue-text btn-flat"
            @click="editDetails()"
          >
            <i class="material-icons left">edit</i>Edit details
          </a>
          <a class="waves-effect waves-light blue btn" @click="editLogo()">
            <i class="material-icons left">image</i>Change logo
          </a>
        </div>
      </div>
    </div>
    <div class="col s12 m6" style="margin-top: 1rem">
      <div class="card" style="border-radius: 5px">
        <div class="card-content">
          <router-link
            to="/share"
            class="btn-flat black-text"
            style="position: absolute; right: 0; top: 8px; z-index: 1"
          >
            <i class="left material-icons">share</i>Share
          </router-link>
          <div style="text-align: center">
            <div class="congrats-device">
              <img src="@/assets/img/congrats_device.jpg" />
              <img :src="institutionDetails.logo" class="congrats-logo" />
              <div class="congrats-title">{{ institutionDetails.name }}</div>
              <div class="congrats-subtitle">Powered by Mobishaala</div>
            </div>
            <p>
              Download
              <b>Mobishaala</b> from the link below and use Institute Code
              <b>{{ institutionDetails.code }}</b> to access your institute App.
            </p>
          </div>
          <div class="row" style="z-index: 10">
            <div class="center-align" style="padding-top: 16px">
              <a
                class="center-align"
                style="margin-right: 16px"
                href="https://play.google.com/store/apps/details?id=com.mobishaala.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
              >
                <img
                  height="40"
                  alt="Get it on Google Play"
                  src="@/assets/img/en_badge_web_generic copy.png"
                />
              </a>
              <a
                class="center-align"
                style="margin-left: 16px"
                href="https://itunes.apple.com/gb/app/mobishaala/id1316602075?mt=8"
                target="_blank"
              >
                <img
                  alt="Get it on App Store"
                  height="40"
                  src="@/assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12" style="border-radius: 5px; margin-top: 1rem">
      <div class="card">
        <div class="card-content">
          <span class="card-title">App Banner</span>
          <div class="card-action right-align">
            <!-- <a class="waves-effect waves-dark blue-text btn-flat" @click="uploadBanner()">
              <i class="material-icons left">cloud_upload</i>Upload banner
            </a> -->
            <a
              class="waves-effect waves-light blue btn"
              @click="createBanner()"
            >
              <i class="material-icons left">image</i>New banner
            </a>
          </div>
          <div class="row">
            <div
              class="row banner-holder"
              v-for="banner in bannerList.data"
              :key="banner.id"
            >
              <div class="col s12">
                <AspectRatioViewHolder
                  aspectRatio="2.1333333333"
                  style="position: relative"
                  class="banner-card"
                >
                  <img
                    width="100%"
                    height="100%"
                    :src="banner.image"
                    :class="{ 'disabled-grey': !banner.is_visible }"
                  />
                  <a
                    class="banner-button btn-floating red"
                    @click="deleteBanner(banner.id)"
                  >
                    <i class="material-icons">delete</i>
                  </a>
                  <a
                    class="btn-floating banner-button waves-effect waves-light purple tooltipped"
                    style="text-align: center"
                    data-position="top"
                    data-tooltip="Edit Order"
                    @click="editBannerOrder(banner.id, banner.order)"
                    >{{ banner.order }}</a
                  >
                  <a
                    class="btn-floating banner-button waves-effect waves-light green tooltipped"
                    style="text-align: center; margin-left: 110px"
                    data-position="top"
                    data-tooltip="Edit Action"
                    @click="editBannerAction(banner.id, banner.action)"
                  >
                    <i class="material-icons">insert_link</i>
                  </a>
                  <a
                    class="btn-floating banner-button waves-effect waves-light blue tooltipped"
                    style="text-align: center; margin-left: 165px"
                    data-position="top"
                    data-tooltip="Edit Action"
                    @click="
                      toggleBannerVisibility(banner.id, banner.is_visible)
                    "
                  >
                    <i class="material-icons">{{
                      banner.is_visible ? "visibility" : "visibility_off"
                    }}</i>
                  </a>
                  <a
                    class="btn-floating banner-button waves-effect waves-light yellow darken-3 tooltipped"
                    :class="{
                      'yellow darken-3': banner.is_prime,
                      grey: !banner.is_prime,
                    }"
                    style="text-align: center; margin-left: 220px"
                    data-position="top"
                    data-tooltip="Make Prime Banner"
                    @click="toggleBannerPrime(banner.id, banner.is_prime)"
                  >
                    <i class="material-icons">local_parking</i>
                  </a>
                </AspectRatioViewHolder>
              </div>
              <!-- <div class="col s4" >
                   <a class="waves-effect waves-dark" @click="deleteBanner(banner.id)"><i class="material-icons red-text">delete</i></a>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="congratsModal" class="modal">
      <div class="modal-content">
        <div class="row">
          <div class="col s10 offset-s1" style="text-align: center">
            <div class="congrats-device">
              <img src="@/assets/img/congrats_device.jpg" />
              <img :src="institutionDetails.logo" class="congrats-logo" />
              <div class="congrats-title">{{ institutionDetails.name }}</div>
              <div class="congrats-subtitle">Powered by Mobishaala</div>
            </div>
            <h5>Congrats!</h5>
            <p>
              Download
              <b>Mobishaala</b> from the link below and use Institute Code
              <b>{{ institutionDetails.code }}</b> to access your institute App.
            </p>
          </div>
        </div>
        <div class="row" style="z-index: 10">
          <div class="center-align" style="padding: 16px">
            <a
              class="center-align"
              style="margin-right: 16px"
              href="https://play.google.com/store/apps/details?id=com.mobishaala.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
            >
              <img
                height="50"
                alt="Get it on Google Play"
                src="@/assets/img/en_badge_web_generic copy.png"
              />
            </a>
            <a
              class="center-align"
              style="margin-left: 16px"
              href="https://itunes.apple.com/gb/app/mobishaala/id1316602075?mt=8"
              target="_blank"
            >
              <img
                alt="Get it on App Store"
                height="50"
                src="@/assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
              />
            </a>
          </div>
        </div>
      </div>
      <canvas
        id="congratsModalConfetti"
        style="z-index: -10; position: fixed; top: 0; left: 0"
      ></canvas>
      <div class="modal-footer" style="background-color: transparent">
        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
      </div>
    </div>
    <div id="editBannerOrderModal" class="modal">
      <div class="modal-content">
        <h4>Edit Order</h4>
        <div class="row">
          <div class="input-field col s12">
            <input id="bannerOrder" type="number" class="validate" />
            <label for="bannerOrder">
              Order
              <span class="red-text">&nbsp;*</span>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
        <a
          @click="editBannerOrderSubmit()"
          class="waves-effect waves-green btn-flat blue-text"
          >Save</a
        >
      </div>
    </div>
    <div id="editBannerActionModal" class="modal">
      <div class="modal-content">
        <h4>Edit Action</h4>
        <div class="row">
          <div class="input-field col s12">
            <input id="bannerAction" type="text" />
            <label for="bannerAction">
              Action
              <span class="red-text">&nbsp;*</span>
            </label>
          </div>
          <a class="waves-effect waves-teal btn-flat" @click="actionTab = 1">
            Whatsapp
            <i class="waIcon material-icons left">call</i>
          </a>
          <div class="row waHolder" v-if="actionTab == 1">
            <div class="input-field col s12">
              <input id="waNumber" type="text" />
              <label for="waNumber">
                10 digit Mobile Number
                <span class="red-text">&nbsp;*</span>
              </label>
            </div>
            <div class="input-field col s12">
              <textarea id="waMessage" class="materialize-textarea"></textarea>
              <label for="waMessage">Message</label>
            </div>
            <a class="waves-effect waves-teal btn-flat" @click="setWAAction()">
              Apply
              <i class="material-icons left">vertical_align_top</i>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
        <a
          @click="editBannerActionSubmit()"
          class="waves-effect waves-green btn-flat blue-text"
          >Save</a
        >
      </div>
    </div>

    <action-generator ref="actionGenerator" @generated="actionGenerated" />
  </div>
</template>
<style scoped>
.waHolder {
  background: rgba(37, 211, 102, 0.1);
  border-radius: 10px;
  margin-top: 16px;
}
.waIcon {
  color: white;
  background: rgb(37, 211, 102);
  border-radius: 50%;
  height: 30px;
  text-align: center;
  width: 30px;
  line-height: 30px;
}
.banner-button {
  position: absolute;
  left: 10px;
  top: -20px;
  color: white;
}

.banner-button:nth-child(2) {
  margin-left: 55px;
}
.congrats-device {
  position: relative;
}
.congrats-device .congrats-logo {
  width: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}
.banner-card img.disabled-grey {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.congrats-device .congrats-title {
  height: 80px;
  width: 90px;
  text-align: center;
  position: absolute;
  font-weight: 400;
  font-size: 1.14rem;
  left: calc(50% - 45px);
  top: calc(50% - 100px);
}
.congrats-device .congrats-subtitle {
  width: 80px;
  position: absolute;
  font-weight: 600;
  font-size: 0.64rem;
  left: calc(50% - 40px);
  top: calc(50% + 40px);
}
.banner-holder {
  margin-bottom: 40px;
}
.banner-holder:nth-child(odd) {
  background: #f1f7f9;
}
.banner-holder:nth-child(even) {
  background: #dfe6e9;
}
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 30px;
  line-height: 64px;
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}
</style>
<script>
import axios from "axios";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import "confetti-js";
import AspectRatioViewHolder from "@/components/Common/AspectRatioViewHolder.vue";
import ActionGenerator from "@/components/Common/ActionGenerator.vue";

export default {
  props: ["institutionDetails", "bannerList"],
  components: {
    AspectRatioViewHolder,
    ActionGenerator,
  },
  data() {
    return {
      currentBannerToEditId: "",
      actionTab: 1,
    };
  },
  mounted() {
    // eslint-disable-next-line
    M.Tabs.init(document.querySelectorAll(".tabs"), {});

    var elems = document.querySelectorAll(".modal");
    // eslint-disable-next-line
    var instances = M.Modal.init(elems, {});
    // localStorage.setItem(STORAGE.SHOW_CONGRATS_MESSAGE, true);
    var showCongratsMessage = localStorage.getItem(
      STORAGE.SHOW_CONGRATS_MESSAGE
    );
    if (showCongratsMessage == "true") {
      localStorage.setItem(STORAGE.SHOW_CONGRATS_MESSAGE, false);

      // eslint-disable-next-line
      var congratsModal = M.Modal.getInstance(
        document.getElementById("congratsModal")
      );
      congratsModal.open();
      var confettiSettings = {
        target: "congratsModalConfetti",
        width: document.getElementById("congratsModal").offsetWidth,
        height: document.getElementById("congratsModal").offsetHeight + 150,
        max: 30,
      };
      // eslint-disable-next-line
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }
  },
  methods: {
    uploadBanner: function () {
      this.$router.push("/app/uploadBanner");
    },
    createBanner: function () {
      this.$router.push("/app/addBanner");
    },
    editDetails: function () {
      this.$router.push("/app/editDetails");
    },
    editLogo: function () {
      this.$router.push("/app/editLogo");
    },
    toggleBannerVisibility: function (id, is_visible) {
      const vm = this;
      this.listLoaderState = 0;

      const formData = new FormData();
      formData.append("is_visible", !is_visible);
      axios
        .patch(URL.BANNER_DELETE + id + "/", formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          if (is_visible)
            // eslint-disable-next-line
            M.toast({ html: "Disabled Banner" });
          // eslint-disable-next-line
          else M.toast({ html: "Enabled Banner" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          handleNetworkError(error, vm);
          this.listLoaderState = -1;
        });
    },
    toggleBannerPrime(id, is_prime) {
      const vm = this;
      this.listLoaderState = 0;

      const formData = new FormData();
      formData.append("is_prime", !is_prime);
      axios
        .patch(URL.BANNER_DELETE + id + "/", formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          if (is_prime)
            // eslint-disable-next-line
            M.toast({ html: "Removed Prime Banner" });
          // eslint-disable-next-line
          else M.toast({ html: "Set Prime Banner" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          handleNetworkError(error, vm);
          this.listLoaderState = -1;
        });
    },
    deleteBanner: function (id) {
      const vm = this;
      axios
        .delete(URL.BANNER_DELETE + id, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
            "content-type": "multipart/form-data",
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => handleNetworkError(error, vm));
    },
    editBannerOrder: function (id, order) {
      this.currentBannerToEditId = id;
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(
        document.getElementById("editBannerOrderModal")
      );
      document.getElementById("bannerOrder").value = parseInt(order) || 0;
      // eslint-disable-next-line
      M.updateTextFields();
      instance.open();
    },
    editBannerAction: function (id, action) {
      this.currentBannerToEditId = id;
      this.$refs.actionGenerator.open(action);
      // eslint-disable-next-line
      // var instance = M.Modal.getInstance(
      //   document.getElementById("editBannerActionModal")
      // );
      // document.getElementById("bannerAction").value = action || "";
      // eslint-disable-next-line
      // M.updateTextFields();
      // instance.open();
    },
    editBannerOrderSubmit: function () {
      const order = document.getElementById("bannerOrder").value;
      if (order == "") {
        // eslint-disable-next-line
        M.toast({ html: "Fill all fields" });
        return;
      }
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(
        document.getElementById("editBannerOrderModal")
      );
      instance.close();
      const vm = this;
      const formData = {
        order: order,
      };
      axios
        .patch(URL.BANNER_DELETE + this.currentBannerToEditId + "/", formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          // eslint-disable-next-line
          M.toast({ html: "Edited Order" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.listLoaderState = -1;
          handleNetworkError(error, vm);
        });
    },
    actionGenerated(action) {
      const vm = this;
      const formData = {
        action: action || "",
      };
      axios
        .patch(URL.BANNER_DELETE + this.currentBannerToEditId + "/", formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          // eslint-disable-next-line
          M.toast({ html: "Edited Action" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.listLoaderState = -1;
          handleNetworkError(error, vm);
        });
    },
    editBannerActionSubmit: function () {
      const action = document.getElementById("bannerAction").value;
      if (action == "") {
        // eslint-disable-next-line
        M.toast({ html: "Fill all fields" });
        return;
      }
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(
        document.getElementById("editBannerActionModal")
      );
      instance.close();
      const vm = this;
      const formData = {
        action: action || "",
      };
      axios
        .patch(URL.BANNER_DELETE + this.currentBannerToEditId + "/", formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          vm.$emit("institute-data-update");
          // eslint-disable-next-line
          M.toast({ html: "Edited Action" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.listLoaderState = -1;
          handleNetworkError(error, vm);
        });
    },
    setWAAction() {
      var mobile = document.getElementById("waNumber").value;
      var message = document.getElementById("waMessage").value;
      if (mobile == "" || mobile.length != 10) {
        // eslint-disable-next-line
        M.toast({ html: "Enter 10 digit number" });
        return;
      } else if (message == "") {
        // eslint-disable-next-line
        M.toast({ html: "Enter message" });
        return;
      }
      document.getElementById("bannerAction").value =
        "https://wa.me/91" + mobile + "/?text=" + encodeURIComponent(message);
    },
  },
};
</script>

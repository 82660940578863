<template>
  <div class="controls">


    <div
      ref="background-dropdown"
      class="dropdown-trigger btn blue"
      data-target="background-dropdown"
    >
      Background
    </div>
    <ul id="background-dropdown" class="dropdown-content">
      <chrome-picker @input="updateBackgroundColor" v-model="selectedBlock.rectangle.background" />
    </ul>
    
  </div>
</template>

<script>
import {Chrome} from "vue-color";
export default {
  components: {
    'chrome-picker' : Chrome,
  },
  props: ["selectedBlock"],
  watch: {
    selectedBlock(newValue) {
      this.$emit("update-selection", newValue);
    },
  },
   mounted() {
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["background-dropdown"], {
      constrainWidth: false,
      coverTrigger: false,
      closeOnClick: false,
    });
   },
   methods:{
     updateBackgroundColor(color) {
      this.selectedBlock.rectangle.background = color.hex;
    },
   }
};
</script>
<style scoped>
.controls {
  margin: 20px;
  text-align: center;
}
</style>
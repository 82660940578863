import { render, staticRenderFns } from "./EditDetails.vue?vue&type=template&id=e6dd73d6&scoped=true&"
import script from "./EditDetails.vue?vue&type=script&lang=js&"
export * from "./EditDetails.vue?vue&type=script&lang=js&"
import style0 from "./EditDetails.vue?vue&type=style&index=0&id=e6dd73d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6dd73d6",
  null
  
)

export default component.exports
export default {
    "banner_combo_1": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/1.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.6031177662037037,
                    "y": -0.06106053511751968,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.024946263227513227,
                    "y": 0.07284658286118981,
                    "width": 0.13362888558201058,
                    "height": 0.28618537535410765,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.17785988774523678,
                    "y": 0.08426977729574829,
                    "width": 0.43270502645502645,
                    "height": 0.3409945782453413,
                    "text": {
                        "color": "#E67819",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.0037977430555555555,
                    "y": 0.4745374468838527,
                    "width": 0.6442005621693122,
                    "height": 0.18020759560906516,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.0019221230158730158,
                    "y": 0.6458812854107648,
                    "width": 0.6390335648148148,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.003162202380952381,
                    "y": 0.8486411118980169,
                    "width": 0.6412863756613757,
                    "height": 0.141643059490085,
                    "text": {
                        "color": "black",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.0708215297450425,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_2": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/2.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.015754174933862435,
                    "y": -0.0621892532478313,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.4739686673280423,
                    "y": 0.04839102337110482,
                    "width": 0.13362888558201058,
                    "height": 0.28618537535410765,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.6271354747161363,
                    "y": 0.02843142743739135,
                    "width": 0.3668103091931217,
                    "height": 0.3409613806532733,
                    "text": {
                        "color": "#E67819",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.10198300283286119,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.4623377562830688,
                    "y": 0.43686924575070823,
                    "width": 0.5145037615740741,
                    "height": 0.1801965297450425,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "right",
                        "fontSize": 0.10764872521246459,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.4642650462962963,
                    "y": 0.5852292847025495,
                    "width": 0.5161313657407407,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "right",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.3927538029100529,
                    "y": 0.8634361720963173,
                    "width": 0.6097418568121693,
                    "height": 0.141643059490085,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.0708215297450425,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_3": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/3.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.6137373392834128,
                    "y": -0.04957118384766171,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.11240802744708994,
                    "y": 0.07656471317280453,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.25484814832724734,
                    "y": 0.05846418239489843,
                    "width": 0.3668103091931217,
                    "height": 0.3409613806532733,
                    "text": {
                        "color": "#E67819",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.10198300283286119,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.11025338955026455,
                    "y": 0.4367917847025496,
                    "width": 0.5145037615740741,
                    "height": 0.1801965297450425,
                    "text": {
                        "color": "#2A2A2A",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.2261284722222222,
                    "y": 0.6304333392351275,
                    "width": 0.43743799603174605,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.02101417824074074,
                    "y": 0.8535875531161473,
                    "width": 0.6097263558201058,
                    "height": 0.1385999468838527,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.0708215297450425,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_4": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/4.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": -0.039126508560512634,
                    "y": -0.042037075051649046,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.32866236772486773,
                    "y": 0.07110924220963173,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.49294227597378676,
                    "y": 0.053266381051509444,
                    "width": 0.48825533427889384,
                    "height": 0.34091716042321396,
                    "text": {
                        "color": "#E67819",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.12181303116147309,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.4103060929232804,
                    "y": 0.48996326133144474,
                    "width": 0.5726583167989417,
                    "height": 0.1801965297450425,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "right",
                        "fontSize": 0.10764872521246459,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.46080832506613756,
                    "y": 0.6366966182719547,
                    "width": 0.5246569113756614,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#0093DD",
                        "background": "none",
                        "alignment": "right",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.526418857473545,
                    "y": 0.8672981586402266,
                    "width": 0.4646112351190476,
                    "height": 0.141643059490085,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.059490084985835696,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_5": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/5.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.6088666296670006,
                    "y": 0.006796582880362284,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.04963934358465608,
                    "y": 0.05356984773371105,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.18760373430712007,
                    "y": 0.04386039663224599,
                    "width": 0.43989748677248675,
                    "height": 0.39873629994162757,
                    "text": {
                        "color": "#222222",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11048158640226628,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.05308573082010582,
                    "y": 0.46810817988668557,
                    "width": 0.5567956349206349,
                    "height": 0.1801965297450425,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.04562975363756614,
                    "y": 0.6044285587818697,
                    "width": 0.5647941468253969,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#F5C500",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.024388227513227514,
                    "y": 0.8128762393767706,
                    "width": 0.6105530753968254,
                    "height": 0.1610525849858357,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.059490084985835696,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_6": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.00548735119047619,
                    "y": -0.005223087818696884,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/6.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.3174738138601223,
                    "y": -0.012657206071479077,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.1330140128968254,
                    "y": 0.06936083569405099,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.021479602362675633,
                    "y": 0.36430568700051796,
                    "width": 0.3462766617063492,
                    "height": 0.39872521246458925,
                    "text": {
                        "color": "#015198",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.09348441926345609,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.6869161292989417,
                    "y": 0.3137283109065156,
                    "width": 0.30900710978835977,
                    "height": 0.4340474504249292,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11331444759206799,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.0033172123015873015,
                    "y": 0.8219502478753541,
                    "width": 0.354409515542328,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#131313",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.003957919973544974,
                    "y": 0.6558073654390935,
                    "width": 0.3664021164021164,
                    "height": 0.1871458923512748,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.05099150141643059,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_7": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.004159432870370371,
                    "y": -0.016598796033994334,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/7.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": -0.0450478875287666,
                    "y": -0.04027760267204565,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.4134837962962963,
                    "y": 0.07523680949008499,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.5557212938309296,
                    "y": 0.07631657581071624,
                    "width": 0.4367558716466187,
                    "height": 0.27309445821529743,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.09348441926345609,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.4052062665343915,
                    "y": 0.40307409702549574,
                    "width": 0.5869502314814815,
                    "height": 0.19571087110481586,
                    "text": {
                        "color": "#E25510",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.42288773148148145,
                    "y": 0.5514120042492918,
                    "width": 0.5630942046957672,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#131313",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.3330381302960643,
                    "y": 0.8889837748627549,
                    "width": 0.6673073743386243,
                    "height": 0.11363535764872522,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.05099150141643059,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_8": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.004159432870370371,
                    "y": -0.016598796033994334,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/8.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.7159526498389582,
                    "y": -0.030185534683377094,
                    "width": 0.41829943783068785,
                    "height": 2.666617812029701,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.16428984788359788,
                    "y": 0.034879603399433426,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.07360427167484494,
                    "y": 0.341753456122331,
                    "width": 0.3076223544973545,
                    "height": 0.3216846671388102,
                    "text": {
                        "color": "#714584",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.09348441926345609,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.4459067046957672,
                    "y": 0.38679621104815864,
                    "width": 0.417043857473545,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.4386264054232804,
                    "y": 0.5057985127478754,
                    "width": 0.5630942046957672,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#131313",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.015712154766963787,
                    "y": 0.871322655882585,
                    "width": 0.673404471584098,
                    "height": 0.141643059490085,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.05099150141643059,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_9": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.004159432870370371,
                    "y": -0.016598796033994334,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/9.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.7322958624712333,
                    "y": 0.08974629959424331,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.12598689649470898,
                    "y": 0.12000929532577903,
                    "width": 0.12609540343915343,
                    "height": 0.27005134560906513,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.016049088143098914,
                    "y": 0.42849876419598537,
                    "width": 0.3454447751322751,
                    "height": 0.4929953080736544,
                    "text": {
                        "color": "#085359",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11614730878186968,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.39331700562169314,
                    "y": 0.1780718838526912,
                    "width": 0.417043857473545,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#085359",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.3847860315098846,
                    "y": 0.3147663612911007,
                    "width": 0.39451566827360285,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#131313",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.3942102119759585,
                    "y": 0.5017338633896672,
                    "width": 0.3603257275132275,
                    "height": 0.3120131019830028,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.05099150141643059,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_10": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.008541046626984126,
                    "y": -0.0009405984419263456,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/10.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.30251018952149794,
                    "y": 0.08122558429679287,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.09681919642857142,
                    "y": 0.1276004780453258,
                    "width": 0.13893022486772486,
                    "height": 0.29753895184135976,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": -0.0056212987616629925,
                    "y": 0.4795677266605746,
                    "width": 0.3454447751322751,
                    "height": 0.4929953080736544,
                    "text": {
                        "color": "#912B36",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11614730878186968,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.6065434854497355,
                    "y": 0.14429886685552407,
                    "width": 0.417043857473545,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.6010197037982444,
                    "y": 0.3113691410361432,
                    "width": 0.39451566827360285,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#EFC300",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.6091779699124664,
                    "y": 0.5559123336446247,
                    "width": 0.32017299107142855,
                    "height": 0.41709454674220964,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.06515580736543909,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_11": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.008541046626984126,
                    "y": -0.0009405984419263456,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/11.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.6974651333045667,
                    "y": 0.14358172806449826,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.09681919642857142,
                    "y": 0.1276004780453258,
                    "width": 0.13893022486772486,
                    "height": 0.29753895184135976,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": -0.0056212987616629925,
                    "y": 0.4795677266605746,
                    "width": 0.3454447751322751,
                    "height": 0.4929953080736544,
                    "text": {
                        "color": "#E70F7C",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11614730878186968,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.3294425843253968,
                    "y": 0.07787048512747875,
                    "width": 0.46005394345238093,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#E70F7C",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.3406960430839587,
                    "y": 0.231805578713197,
                    "width": 0.4457722820302166,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.43081838823257224,
                    "y": 0.5453444335029816,
                    "width": 0.29202835648148145,
                    "height": 0.41709454674220964,
                    "text": {
                        "color": "#000000",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.05099150141643059,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_12": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.008541046626984126,
                    "y": -0.0009405984419263456,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/12.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.731117787074408,
                    "y": 0.1409701841551498,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.16595878802910052,
                    "y": 0.16095299220963172,
                    "width": 0.13893022486772486,
                    "height": 0.29753895184135976,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.08057455110606188,
                    "y": 0.5127542528645406,
                    "width": 0.3103401951058201,
                    "height": 0.4155674575070821,
                    "text": {
                        "color": "#F6C403",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11614730878186968,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.3893229166666667,
                    "y": 0.16806834277620397,
                    "width": 0.3987888558201058,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.11331444759206799,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.38573159202575763,
                    "y": 0.310030171489401,
                    "width": 0.4034752416863013,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.10764872521246459,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.45320698776960927,
                    "y": 0.5373659455426416,
                    "width": 0.30590174437830686,
                    "height": 0.41709454674220964,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.056657223796033995,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_13": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.0016637731481481482,
                    "y": -0.006285410764872521,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/13.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.1537523356855191,
                    "y": 0.2452216891126569,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.1490058697089947,
                    "y": 0.02174442280453258,
                    "width": 0.13100405092592593,
                    "height": 0.2805639164305949,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.44780338708489786,
                    "y": 0.04282020541411568,
                    "width": 0.5486316983661954,
                    "height": 0.34575294300111764,
                    "text": {
                        "color": "#291576",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.1359773371104816,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.4446459573412698,
                    "y": 0.35108666784702547,
                    "width": 0.5631820436507936,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#90283F",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.14447592067988668,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.4380371062453344,
                    "y": 0.52004920477552,
                    "width": 0.4034752416863013,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#291576",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.13314447592067988,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.4533464966981807,
                    "y": 0.7950567210383923,
                    "width": 0.5360036375661376,
                    "height": 0.18444582152974504,
                    "text": {
                        "color": "#291576",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.056657223796033995,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    },
    "banner_combo_14": {
        name: "Blank Banner",
        thumbnail: "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/thumbnails/banner_blank.jpeg",
        category: "Basics",
        categoryId: "1",
        template: {
            "width": 1280,
            "height": 600,
            "zoom": 1,
            "blocks": [
                {
                    "id": "image1607639184488",
                    "type": "image",
                    "index": 0,
                    "x": -0.0016637731481481482,
                    "y": -0.006285410764872521,
                    "width": 1.0129588293650793,
                    "height": 1.0169044905276203,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/banner_combo/14.png"
                    }
                },
                {
                    "id": "image1607639437836",
                    "type": "image",
                    "index": 1,
                    "x": 0.6921017900505985,
                    "y": 0.15097372523163707,
                    "width": 0.3064443598348628,
                    "height": 1.953544858592334,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/templates/avartar.png"
                    }
                },
                {
                    "id": "image1607639462611",
                    "type": "image",
                    "ref": "logo",
                    "index": 2,
                    "x": 0.14445374503968253,
                    "y": 0.16914173158640228,
                    "width": 0.13100405092592593,
                    "height": 0.2805639164305949,
                    "image": {
                        "value": "https://mobishaala-public-static-asset.s3.ap-south-1.amazonaws.com/template-generator/assets/mobishaala_logo.png"
                    }
                },
                {
                    "id": "text1607639471357",
                    "type": "text",
                    "ref": "name",
                    "index": 3,
                    "x": 0.29059749257431583,
                    "y": 0.1721690899750222,
                    "width": 0.4804635830026455,
                    "height": 0.3457418555240793,
                    "text": {
                        "color": "#92283C",
                        "background": "none",
                        "alignment": "left",
                        "fontSize": 0.11614730878186968,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639694564",
                    "type": "text",
                    "ref": "admin_name",
                    "index": 4,
                    "x": 0.14049582506613756,
                    "y": 0.6074495396600567,
                    "width": 0.5631820436507936,
                    "height": 0.19568873937677053,
                    "text": {
                        "color": "#90283F",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.1274787535410765,
                        "font": "'Secular One', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639719471",
                    "type": "text",
                    "ref": "admin_number",
                    "index": 5,
                    "x": 0.5211689266818423,
                    "y": 0.011982189902998725,
                    "width": 0.4034752416863013,
                    "height": 0.19573300283286119,
                    "text": {
                        "color": "#4C483F",
                        "background": "none",
                        "alignment": "right",
                        "fontSize": 0.10198300283286119,
                        "font": "'Sunflower', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                },
                {
                    "id": "text1607639749252",
                    "type": "text",
                    "ref": "address",
                    "index": 6,
                    "x": 0.005223150137334157,
                    "y": 0.8397185482338597,
                    "width": 0.7158564814814815,
                    "height": 0.09099459985835694,
                    "text": {
                        "color": "#FFFFFF",
                        "background": "none",
                        "alignment": "center",
                        "fontSize": 0.056657223796033995,
                        "font": "'Roboto', sans-serif",
                        "isEdit": false,
                        "isBold": false,
                        "isItalics": false,
                        "value": "Some text"
                    }
                }
            ]
        }
    }
}
<template>
  <div class="controls">
    <div
      ref="new-dropdown"
      class="dropdown-trigger btn blue"
      data-target="new-dropdown"
    >
      New
    </div>
    <div
      :disabled="!selectedBlock || !selectedBlock.id"
      class="btn white black-text"
      @click="$emit('clear-selection')"
    >
      Clear Selection
    </div>
    <div
      :disabled="!selectedBlock || !selectedBlock.id"
      class="btn red"
      @click="$emit('delete-selection')"
    >
      Delete
    </div>
    <div
      :disabled="!selectedBlock || !selectedBlock.id"
      class="btn green"
      @click="$emit('move-up-selection')"
    >
      Move Up
    </div>
    <div
      :disabled="!selectedBlock || !selectedBlock.id"
      class="btn green"
      @click="$emit('move-down-selection')"
    >
      Move Down
    </div>
    <div @click="$emit('print-state')" class="btn blue" v-if="isDebug">
      Print state
    </div>
    <ul id="new-dropdown" class="dropdown-content">
      <li>
        <a class="black-text" @click="$emit('add-block', 'text')">Text</a>
      </li>
      <li>
        <a class="black-text" @click="$emit('add-block', 'image')">My Image</a>
      </li>
      <li>
        <a class="black-text" @click="$emit('add-block', 'wimage')"
          >Web Image</a
        >
      </li>
      <li>
        <a class="black-text" @click="$emit('add-block', 'stock')"
          >Stock Image</a
        >
      </li>
      <li>
        <a class="black-text" @click="$emit('add-block', 'rectangle')"
          >Rectangle</a
        >
      </li>
    </ul>

    <div
      v-if="isDebug"
      ref="download-dropdown"
      class="dropdown-trigger btn blue"
      data-target="download-dropdown"
    >
      Download
    </div>
    <ul id="download-dropdown" class="dropdown-content">
      <li><a @click="$emit('download-block', 'jpg')">Jpeg</a></li>
      <li><a @click="$emit('download-block', 'png')">Png</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["selectedBlock", "isDebug"],
  mounted() {
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["new-dropdown"], {
      constrainWidth: false,
    });
    // eslint-disable-next-line
    M.Dropdown.init(this.$refs["download-dropdown"], {
      constrainWidth: false,
    });
  },
};
</script>
<style scoped>
.controls {
  margin: 20px;
  text-align: center;
}
</style>

<template>
  <div>
    <div class="my-card">
      <div class="row center">
        <h4>
          Add your logo
          <div style="color: rgba(0, 0, 0, 0.4); font-size: 1rem">
            1024x1024
          </div>
        </h4>

        <div class="col s12">
          <img height="200" width="200" class="bordered" id="selectedImage" />
        </div>

        <div class="col s12">
          <a
            @click="$refs.input_image.click()"
            class="waves-effect waves-light blue btn"
            >Upload logo</a
          >
        </div>

        <div
          class="col s12"
          style="display: flex; justify-content: center; margin-top: 24px"
          v-if="imageFile != null"
        >
          <NextButton @on-click="submit($event)" :isBusy="isBusy"
            >Next</NextButton
          >
        </div>
      </div>
    </div>

    <div class="my-card">
      <h4>Choose Banner</h4>
      <template-picker-generated
        :width="170"
        categoryTypeId="2"
        :prefillObject="prefillObject"
        @template-selected="imageSelected"
        v-if="prefillObject != null"
      />
    </div>

    <input
      class="hidden"
      type="file"
      ref="input_image"
      @change="onLogoFileSelected"
      accept="image/x-png, image/gif, image/jpeg"
    />
  </div>
</template>
<style scoped>
.hidden {
  display: none;
}
.bordered {
  border: solid 1px rgba(0, 0, 0, 0.12)
}
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 20px;
  line-height: 40px;
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}
</style>
<script>
import axios from "axios";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import NextButton from "@/components/Common/NextButton.vue";
import TemplatePickerGenerated from "@/components/TemplateGenerator/TemplatePickerGenerated.vue";

export default {
  props: ["institutionDetails"],
  mounted() {
    // eslint-disable-next-line
    console.log("mounted");
    // eslint-disable-next-line
    M.updateTextFields();

    this.prefillObject = {
      name: this.institutionDetails.name,
      logo: this.institutionDetails.logo,
      admin_name: this.institutionDetails.contact_person_name,
      admin_number: this.institutionDetails.contact_person_mobile,
      admin_email: this.institutionDetails.contact_person_email,
      address: this.institutionDetails.address,
    };
  },
  updated() {
    // eslint-disable-next-line
    console.log("updated");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  components: {
    NextButton,
    TemplatePickerGenerated
  },
  data() {
    return {
      defaultPictures: [],
      isBusy: false,
      imageFile: null,
      prefillObject: null,
    };
  },
  methods: {
    imageSelected: function (blob) {
      const vm = this;
      vm.imageFile = blob;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(blob);

      window.scrollTo(0, 0);
    },
    onLogoFileSelected: function (e) {
      if (e.target.value == "") {
        // eslint-disable-next-line
        console.log("Nothing selected");
        return;
      }
      var file = e.target.files[0];
      this.imageFile = file;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    selectImage(url) {
      let vm = this;
      fetch(url)
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          vm.imageSelected(blob);
        });
      window.scrollTo(0, 0);
    },
    submit: function () {
      const instituteName = this.institutionDetails.name;
      const instituteAddress = this.institutionDetails.address;
      // const contactName = document.getElementById("contactName").value;
      // const contactNumber = document.getElementById("contactNumber").value;
      // const contactEmail = document.getElementById("contactEmail").value;
      // const code = document.getElementById("code").value;
      if (
        instituteName == "" ||
        instituteAddress == "" ||
        // contactName == "" ||
        // contactNumber == "" ||
        // contactEmail == "" ||
        // code == "" ||
        this.imageFile == null
      ) {
        // eslint-disable-next-line
        M.toast({ html: "Select logo" });
        return;
      }

      if (instituteName.replace(/ /g, "").length < 4) {
        // eslint-disable-next-line
        M.toast({ html: "Institute name must be atleast 4 characters" });
        return;
      }

      const code = this.generateInstituteCode(instituteName);

      const formData = new FormData();
      formData.append("name", instituteName);
      formData.append("address", instituteAddress);
      formData.append("slider_enabled", true);
      // formData.append("contact_person_name", contactName);
      // formData.append("contact_person_mobile", contactNumber);
      // formData.append("contact_person_email", contactEmail);
      formData.append("code", code);
      // formData.append("logo", logo.files[0]);
      formData.append(
        "logo",
        this.imageFile,
        "image" + new Date().getTime() + ".jpg"
      );

      const vm = this;
      vm.isBusy = true;
      axios
        .patch(URL.ORG_APP, formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
            "content-type": "multipart/form-data",
          },
        })
        .then(function (response) {
          vm.isBusy = false;
          vm.$emit("institute-data-update");
          localStorage.setItem(STORAGE.SHOW_CONGRATS_MESSAGE, true);
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
    generateInstituteCode: function (instituteName) {
      return (
        instituteName.replace(/ /g, "").substring(0, 4) +
        (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000)
      );
    },
  },
};
</script>


<template>
  <div>
    <video ref="videoPlayer" class="playerr video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import "videojs-youtube";
import "videojs-markers";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-offset";

export default {
  name: "VideoPlayer",
  props: [
    "src",
    "isLive",
    "type",
    "isCleanMode",
    "srcCaptions",
    "markers",
    "offset",
  ],
  data() {
    return {
      player: null,
      captions: [],
      options: {
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        liveui: this.isLive,
        autoplay: true,
        fluid: true,
        controls: true,
        sources: [],
        controlBar: {
          pictureInPictureToggle: false,
        },
        plugins: {
          httpSourceSelector: {
            default: "auto",
          },
        },
      },
    };
  },
  mounted() {
    if (!this.srcCaptions || this.srcCaptions.length == 0) this.captions = [];
    else
      this.captions = this.srcCaptions.map((item) => {
        return {
          kind: "subtitle",
          srclang: item.language_code,
          label: item.language_name,
          src:
            "https://" +
            item.bucket_name +
            ".s3.ap-south-1.amazonaws.com/" +
            item.caption_file_key,
        };
      });

    this.options.sources.push({
      src: this.src,
      type: this.type,
    });

    let vm = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        // eslint-disable-next-line
        console.log("onPlayerReady", this);
        vm.loadSubtitles();
      }
    );

    this.player.httpSourceSelector();

    if (this.offset) {
      this.player.offset(this.offset);
    } 
    
    else if (this.markers && this.markers.length > 0)
      this.player.markers({
        markerTip: {
          display: true,
          text: function (marker) {
            return marker.text;
          },
          time: function (marker) {
            return marker.time;
          },
        },
        breakOverlay: {
          display: true,
          displayTime: 10,
          style: {
            width: "auto",
            height: "auto",
            "background-color": "rgba(0,0,0,0.2)",
            color: "white",
            "font-size": "1.2rem",
          },
          text: function (marker) {
            return marker.text;
          },
        },
        markers: this.markers,
      });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    getCurrentTime() {
      return this.player.currentTime();
    },
    seekTo(time) {
      if (this.player) {
        this.player.currentTime(time);
      }
    },
    async loadSubtitles() {
      console.log(this.captions);
      let requests = this.captions
        .map((data) => this.convertToVtt(data.src))
        .map((p) => p.catch((e) => e));

      let upload_res = await Promise.all(requests);
      upload_res.forEach((value, i) => {
        this.player.addRemoteTextTrack(
          { ...this.captions[i], src: value },
          false
        );
      });
    },
    async convertToVtt(url) {
      let response = await fetch(url);
      if (!response.ok) {
        return;
      }

      let temp = await response.text();

      var srt = temp.replace(/\r+/g, "");
      // trim white space start and end
      srt = srt.replace(/^\s+|\s+$/g, "");
      // get cues
      var cuelist = srt.split("\n\n");

      var result = "";
      if (cuelist.length > 0) {
        result += "WEBVTT\n\n";
        for (var i = 0; i < cuelist.length; i = i + 1) {
          result += this.convertSrtCue(cuelist[i]);
        }
      }

      var vttBlob = new Blob([result], { type: "text/vtt" });
      var blobURL = URL.createObjectURL(vttBlob);
      return blobURL;
    },
    convertSrtCue(caption) {
      var cue = "";
      var s = caption.split(/\n/);
      while (s.length > 3) {
        for (var i = 3; i < s.length; i++) {
          s[2] += "\n" + s[i];
        }
        s.splice(3, s.length - 3);
      }

      var line = 0;
      if (
        s &&
        s.length > 0 &&
        !s[0].match(/\d+:\d+:\d+/) &&
        s[1].match(/\d+:\d+:\d+/)
      ) {
        cue += s[0].match(/\w+/) + "\n";
        line += 1;
      }
      // get time strings

      if (s && s[line].match(/\d+:\d+:\d+/)) {
        // convert time string
        var m = s[1].match(
          /(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/
        );
        if (m) {
          cue +=
            m[1] +
            ":" +
            m[2] +
            ":" +
            m[3] +
            "." +
            m[4] +
            " --> " +
            m[5] +
            ":" +
            m[6] +
            ":" +
            m[7] +
            "." +
            m[8] +
            "\n";
          line += 1;
        } else {
          // Unrecognized timestring
          return "";
        }
      } else {
        // file format error or comment lines
        return "";
      }
      // get cue text
      if (s[line]) {
        cue += s[line] + "\n\n";
      }
      return cue;
    },
  },
};
</script>
<style scoped>
.playerr {
  width: 100%;
  height: 100%;
}
</style>
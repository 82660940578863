var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"videoPicker",staticClass:"modal"},[_c('div',{staticClass:"modal-content"},[_c('h5',{staticStyle:{"margin":"0"}},[_vm._v(" Video Gallery "),(_vm.isBusy)?_c('div',{staticClass:"preloader-wrapper small active",staticStyle:{"width":"20px","height":"20px"}},[_vm._m(0)]):_vm._e()]),(_vm.selectedTopic == null)?_c('div',{staticClass:"row",staticStyle:{"height":"100%"},attrs:{"id":"picker_page1"}},[_c('div',{staticClass:"col s12 m4 picker-window"},[_c('div',{staticClass:"picker-list"},_vm._l((_vm.categorySubjectList),function(categorySubject){return _c('div',{key:categorySubject.id,staticClass:"row",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(categorySubject.category)+" ")]),_c('ul',{staticClass:"collection"},_vm._l((categorySubject.subjects),function(subject){return _c('li',{key:subject.id,staticClass:"collection-item clickable image-collection-item",class:{
                  'blue white-text':
                    _vm.selectedSubject && _vm.selectedSubject.id == subject.id,
                  'white black-text': !(
                    _vm.selectedSubject && _vm.selectedSubject.id == subject.id
                  ),
                },staticStyle:{"height":"60px"},on:{"click":function($event){_vm.selectedSubject = subject}}},[_c('img',{staticClass:"square-image",attrs:{"src":subject.image}}),_c('span',{staticStyle:{"margin-left":"40px","display":"block"}},[_vm._v(_vm._s(subject.order)+". "+_vm._s(subject.name))]),(!subject.is_visible)?_c('i',{staticClass:"secondary-content material-icons red-text"},[_vm._v("visibility_off")]):_vm._e()])}),0)])}),0)]),_c('div',{staticClass:"col s12 m4 picker-window"},[_c('ul',{staticClass:"collection picker-list"},_vm._l((_vm.chapterList),function(chapter){return _c('li',{key:chapter.id,staticClass:"collection-item clickable",class:_vm.selectedChapter && _vm.selectedChapter.id == chapter.id
                ? 'blue white-text'
                : 'white black-text',on:{"click":function($event){_vm.selectedChapter = chapter;
              _vm.topicList = chapter.topics;}}},[_vm._v(" "+_vm._s(chapter.index)+". "+_vm._s(chapter.name)+" "),(!chapter.is_visible)?_c('i',{staticClass:"secondary-content material-icons red-text"},[_vm._v("visibility_off")]):_vm._e()])}),0)]),_c('div',{staticClass:"col s12 m4 picker-window"},[_c('ul',{staticClass:"collection picker-list"},_vm._l((_vm.topicList),function(topic){return _c('li',{key:topic.id,staticClass:"collection-item clickable",class:_vm.selectedTopic && _vm.selectedTopic.id == topic.id
                ? 'blue white-text'
                : 'white black-text',on:{"click":function($event){_vm.selectedTopic = topic}}},[_vm._v(" "+_vm._s(topic.index)+". "+_vm._s(topic.name)+" "),(!topic.is_visible)?_c('i',{staticClass:"secondary-content material-icons red-text"},[_vm._v("visibility_off")]):_vm._e(),(topic.video.youtube && topic.video.youtube != '')?_c('i',{staticClass:"secondary-content material-icons red-text"},[_vm._v("subscriptions")]):_vm._e(),(!_vm.isTopicValidForEditor(topic))?_c('i',{staticClass:"secondary-content material-icons red-text"},[_vm._v("videocam_off")]):_vm._e()])}),0)])]):_vm._e(),(_vm.selectedTopic != null)?_c('div',{staticClass:"row",staticStyle:{"height":"100%"},attrs:{"id":"picker_page2"}},[_c('div',{staticClass:"col s12"},[_c('a',{staticClass:"waves-effect waves-light btn blue white-text",on:{"click":function($event){_vm.selectedTopic = null}}},[_vm._v(_vm._s(_vm.selectedSubject.name))]),_vm._v(" > "),_c('a',{staticClass:"waves-effect waves-light btn blue white-text",on:{"click":function($event){_vm.selectedTopic = null}}},[_vm._v(_vm._s(_vm.selectedChapter.name))]),_vm._v(" > "),_c('a',{staticClass:"waves-effect waves-light btn blue white-text",on:{"click":function($event){_vm.selectedTopic = null}}},[_vm._v(_vm._s(_vm.selectedTopic.name))])]),_c('div',{staticClass:"col s12",staticStyle:{"margin-top":"32px"}},[_c('MultiSourceVideo',{attrs:{"videoData":_vm.selectedTopic.video,"currentSelectedVideoId":_vm.currentSelectedVideoId},on:{"set-current-selected-video-id":_vm.setCurrentSelectedVideoId}})],1)]):_vm._e()]),_c('div',{staticClass:"modal-footer"},[_c('a',{staticClass:"modal-close waves-effect waves-green btn-flat"},[_vm._v("Cancel")]),_c('a',{staticClass:"waves-effect waves-green btn-flat blue-text",on:{"click":function($event){return _vm.emitSelected()}}},[_vm._v("Select")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-layer spinner-green-only"},[_c('div',{staticClass:"circle-clipper left"},[_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"gap-patch"},[_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"circle-clipper right"},[_c('div',{staticClass:"circle"})])])}]

export { render, staticRenderFns }
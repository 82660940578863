<template>
  <div class="t-holder">
    <h5 v-if="historyTemplates.length > 0">History</h5>
    <vue-masonry-wall
      :items="historyTemplates"
      v-if="historyTemplates.length > 0"
      :options="{ width: 300, padding: 12 }"
    >
      <template v-slot:default="{ item }">
        <div class="card">
          <div class="card-image" @click="$emit('template-selected', item._id)">
            <img :src="getURLFromBlob(item.rendered)" v-if="item.rendered" />
            <div
              v-else
              :class="{
                'banner-placeholder': categoryTypeId == '1',
                'image-placeholder': categoryTypeId == '2',
              }"
            ></div>
          </div>
        </div>
      </template>
    </vue-masonry-wall>

    <h5>Templates</h5>
    <vue-masonry-wall
      :items="templates"
      v-if="templates.length > 0"
      :options="{ width: 300, padding: 12 }"
    >
      <template v-slot:default="{ item }">
        <div class="card">
          <div class="card-image" @click="$emit('template-selected', item.id)">
            <img
              :src="item.thumbnail"
              v-if="item.template.blocks.length == 0"
            />
            <img :src="item.rendered" v-else-if="item.rendered" />
            <div
              v-else
              :class="{
                'banner-placeholder': categoryTypeId == '1',
                'image-placeholder': categoryTypeId == '2',
              }"
            ></div>
          </div>
          <div
            class="loader-container"
            v-if="!item.rendered && item.template.blocks.length != 0"
          >
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-blue-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vue-masonry-wall>

    <Editor
      v-if="templateId != ''"
      ref="editor"
      style="transform: translateX(-999%); position: fixed"
      :prefillObject="prefillObject"
      :templateId="templateId"
      :preRender="true"
      @image-generated="imageGenerated"
    />
  </div>
</template>
<script>
import VueMasonryWall from "vue-masonry-wall";
import templates from "./templates";
import Editor from "./Editor";
import PouchDB from "pouchdb-browser";
var db = new PouchDB("template-picker-db");
var dbHistory = new PouchDB("template-history-db");

export default {
  props: {
    categoryTypeId: {
      type: String,
      default: "2",
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
  },
  components: { VueMasonryWall, Editor },
  data() {
    return {
      templates: [],
      historyTemplates: [],
      templateId: "",
    };
  },
  mounted() {
    this.getHistoryTemplates();

    for (var key in templates) {
      let item = templates[key];
      item.id = key;
      if (item.categoryId == this.categoryTypeId) this.templates.push(item);
    }

    this.$nextTick(() => {
      this.renderNext();
    });
  },
  methods: {
    getURLFromBlob(blob) {
      return URL.createObjectURL(blob);
    },
    getHistoryTemplates() {
      const vm = this;
      dbHistory.allDocs(
        { include_docs: true, descending: true },
        function (err, doc) {
          vm.historyTemplates = doc.rows
            .filter((x) => x.doc.categoryId == vm.categoryTypeId)
            .slice(0, 8)
            .map((a) => a.doc).sort((a)=>a.created_at);
        }
      );
    },
    imageGenerated(image) {
      let itemIndex = this.templates
        .map((item) => item.id)
        .indexOf(this.templateId);

      if (itemIndex == -1) return;
      this.templateId = "";
      this.templates[itemIndex].rendered = URL.createObjectURL(image);

      var imageJson = {
        _id: this.templates[itemIndex].id,
        image: image,
      };

      db.put(imageJson, function callback(err) {
        if (!err) {
          // eslint-disable-next-line
          console.log("Successfully posted a image!");
        } else {
          console.log(err);
        }
      });

      this.$nextTick(() => {
        this.renderNext();
      });
    },
    async renderNext() {
      for (let item in this.templates) {
        if (this.templates[item].rendered) continue;
        if (this.templates[item].template.blocks.length == 0) continue;

        try {
          let doc = await db.get(this.templates[item].id);
          if (doc && doc.image) {
            this.templates[item].rendered = URL.createObjectURL(doc.image);
            this.$forceUpdate();
          } else {
            this.templateId = this.templates[item].id;
            break;
          }
        } catch (e) {
          this.templateId = this.templates[item].id;
          break;
        }
      }
    },
  },
};
</script>
<style scoped>
.t-holder {
  padding: 10px;
}
.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-placeholder {
  width: 100%;
  padding-top: 100%;
}
.banner-placeholder {
  width: 100%;
  padding-top: 46.875%;
}
</style>
<template>
  <div class="row">
    <div class="col s12">
      <div class="card">
        <div class="card-content">
          <h4>Upload Banner</h4>
          <p>
            Recommended banner size is
            <b>1280px</b> x
            <b>600px</b>. Any other size will be streched/cropped.
          </p>
          <div class="row">
            <div class="col s12">
              <img
                height="300"
                width="640"
                class="bordered"
                id="selectedImage"
                v-if="imageFile != null"
              />
            </div>

            <div class="file-field col s12 input-field">
              <div class="btn blue">
                <span>
                  Pick File
                  <span class="white-text">&nbsp;*</span>
                </span>
                <input type="file" id="banner" accept="image/x-png, image/gif, image/jpeg" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text" />
              </div>
            </div>

            <br />
            <NextButton @on-click="submit($event)" :isBusy="isBusy">Upload</NextButton>
            <router-link
              to="/app"
              class="right waves-effect waves-light red btn-large"
              style="margin-right:16px"
            >Cancel</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bordered {
  border: 1px solid black;
}
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 30px;
  line-height: 64px;
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}
</style>
<script>
import axios from "axios";
import URLS from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import NextButton from "@/components/Common/NextButton.vue";

export default {
  props: ["institutionDetails", "bannerList"],
  components: {
    NextButton,
  },
  data() {
    return {
      isBusy: false,
      imageFile: null,
    };
  },
  mounted() {
    document
      .querySelector("#banner")
      .addEventListener("change", this.onLogoFileSelected);
    // eslint-disable-next-line
    console.log("mounted");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  updated() {
    // eslint-disable-next-line
    console.log("updated");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  methods: {
    onLogoFileSelected: function (e) {
      if (e.target.value == "") {
        // eslint-disable-next-line
        console.log("Nothing selected");
        return;
      }
      var file = e.target.files[0];
      this.imageFile = file;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    submit: function () {
      const banner = document.getElementById("banner");
      if (banner.value == "") {
        // eslint-disable-next-line
        M.toast({ html: "Select Banner" });
        return;
      }
      this.isBusy = true;
      const vm = this;
      this.isGoodImage(banner.files[0]).then(
        function () {
          let biggestOrder = 0;
          if (vm.bannerList && vm.bannerList.data) {
            for (let i = 0; i < vm.bannerList.data.length; i++) {
              // eslint-disable-next-line
              console.log("got order ", vm.bannerList.data[i].order);
              if (vm.bannerList.data[i].order > biggestOrder)
                biggestOrder = vm.bannerList.data[i].order;
            }
          }
          biggestOrder = biggestOrder + 1;

          const formData = new FormData();
          formData.append("order", biggestOrder);
          formData.append(
            "image",
            banner.files[0],
            "image" + new Date().getTime() + "." + banner.value.split(".").pop()
          );
          axios
            .post(URLS.BANNER_ADD, formData, {
              headers: {
                Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
                "content-type": "multipart/form-data",
              },
            })
            .then(function (response) {
              vm.isBusy = false;
              vm.$emit("institute-data-update");
              vm.$router.push("/app");
              // eslint-disable-next-line
              M.toast({ html: "Success" });
              // eslint-disable-next-line
              console.log(response);
            })
            .catch((error) => {
              handleNetworkError(error, vm);
              vm.isBusy = false;
            });
        },
        function (err) {
          vm.isBusy = false;
          // eslint-disable-next-line
          M.toast({ html: err });
        }
      );
    },
    isGoodImage: function (file) {
      const promise = new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = function () {
          // Check if image is bad/invalid
          if (this.width + this.height === 0) {
            this.onerror();
            return;
          }

          resolve("Stuff worked!");
          // // Check the image resolution
          // if (this.width == 1280 && this.height == 600) {
          //   resolve("Stuff worked!");
          // } else {
          //   reject(Error("The image resolution is not 1280 x 600."));
          // }
        };
        image.onerror = function () {
          reject(Error("Invalid image. Please select an image file."));
        };
        image.src = URL.createObjectURL(file);
      });
      return promise;
    },
  },
};
</script>


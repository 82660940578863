<template>
    <div class="my-card">
        <h4>Edit Details</h4>
        
      <div class="row">
        <div class="input-field col s12">
          <input id="instituteName" type="text" class="validate" v-bind:value="institutionDetails.name">
          <label for="instituteName">Institute Name<span class="red-text">&nbsp;*</span></label>
        </div>
        <div class="input-field col s12">
          <textarea id="instituteAddress" class="materialize-textarea" v-bind:value="institutionDetails.address"></textarea>
          <label for="instituteAddress">Institute Address<span class="red-text">&nbsp;*</span></label>
        </div>
        <div class="input-field col s12 m6">
          <input id="contactName" type="text" class="validate" v-bind:value="institutionDetails.contact_person_name">
          <label for="contactName">Contact Name<span class="red-text">&nbsp;*</span></label>
        </div>
        <div class="input-field col s12 m6">
          <input id="contactNumber" type="number" class="validate" v-bind:value="institutionDetails.contact_person_mobile">
          <label for="contactNumber">Contact Number<span class="red-text">&nbsp;*</span></label>
        </div>
        <div class="input-field col s12">
          <input id="contactEmail" type="email" class="validate" v-bind:value="institutionDetails.contact_person_email">
          <label for="contactEmail">Contact Email<span class="red-text">&nbsp;*</span></label>
        </div>
        
        <p>Institute code must be</p>
        <ol>
          <li>8 characters long</li>
          <li>First 4 character must be A-Z</li>
          <li>Last 4 character must be a number</li>
        </ol>
        <p>eg. ABCD1234</p>
        <div class="input-field col s12">
          <input id="code" type="text" class="validate" v-bind:value="institutionDetails.code">
          <label for="code">Institute Code<span class="red-text">&nbsp;*</span></label>
        </div>
        <!-- <div class="col s4">
          <a @click="generateInstituteCode()" class="waves-effect waves-light blue btn">Generate</a>
        </div> -->
        <br>
         <NextButton @on-click="submit($event)" :isBusy="isBusy">Save</NextButton>
         <!-- <a v-on:click="submit()" class=" right waves-effect waves-light blue btn-large">Next</a> -->
         <router-link to="/app" class=" right waves-effect waves-light red btn-large" style="margin-right:16px">Cancel</router-link>

      </div>

      </div>
</template>
<style scoped>
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 30px;
  line-height: 64px;
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}
</style>
<script>
import axios from "axios";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import NextButton from "@/components/Common/NextButton.vue";

export default {
  props: ["institutionDetails"],
  mounted() {
    this.disableInputScroll();
    // eslint-disable-next-line
    console.log("mounted");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  updated() {
    // eslint-disable-next-line
    console.log("updated");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  components: {
    NextButton
  },
  data() {
    return {
      isBusy: false
    };
  },
  methods: {
    submit: function() {
      const instituteName = document.getElementById("instituteName").value;
      const instituteAddress = document.getElementById("instituteAddress")
        .value;
      const contactName = document.getElementById("contactName").value;
      const contactNumber = document.getElementById("contactNumber").value;
      const contactEmail = document.getElementById("contactEmail").value;
      const code = document.getElementById("code").value;
      if (
        instituteName == "" ||
        instituteAddress == "" ||
        contactName == "" ||
        contactNumber == "" ||
        contactEmail == "" ||
        code == ""
      ) {
        // eslint-disable-next-line
        M.toast({ html: "Fill all fields" });
        return;
      }

      if (instituteName.length < 4) {
        // eslint-disable-next-line
        M.toast({ html: "Institute name must be atleast 4 characters" });
        return;
      }

      if (code.length != 8) {
        // eslint-disable-next-line
        M.toast({ html: "Enter 8 digit institute code" });
        return;
      }
      if (!this.isAlphaNumeric(code)) {
        // eslint-disable-next-line
        M.toast({ html: "Enter alphanumeric institute code" });
        return;
      }
      if (
        !this.isAlphabets(code.substring(0, 4)) ||
        !this.isNumeric(code.substring(4, 8))
      ) {
        // eslint-disable-next-line
        M.toast({ html: "Enter code in format AAAA1111" });
        return;
      }

      const formData = new FormData();
      formData.append("name", instituteName);
      formData.append("address", instituteAddress);
      formData.append("contact_person_name", contactName);
      formData.append("contact_person_mobile", contactNumber);
      formData.append("contact_person_email", contactEmail);
      formData.append("code", code);
      const vm = this;
      this.isBusy = true;
      axios
        .patch(URL.ORG_APP, formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
            "content-type": "multipart/form-data"
          }
        })
        .then(function(response) {
          vm.isBusy = false;
          vm.$emit("institute-data-update");
          vm.$router.push("/app");
          // eslint-disable-next-line
          M.toast({ html: "Success" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch(error => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
    isAlphaNumeric: function(str) {
      var code, i, len;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (
          !(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123)
        ) {
          // lower alpha (a-z)
          return false;
        }
      }
      return true;
    },
    isAlphabets: function(str) {
      var code, i, len;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123)
        ) {
          // lower alpha (a-z)
          return false;
        }
      }
      return true;
    },
    isNumeric: function(str) {
      var code, i, len;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58))
          //is 0-9
          return false;
      }
      return true;
    },
    generateInstituteCode: function(instituteName) {
      return (
        instituteName.substring(0, 4) +
        (Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000)
      );
    }
  }
};
</script>


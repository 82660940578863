<template>
  <div>
    <div v-if="currentSelectedVideoId == videoData.id">
      <!-- <MobishaalaVideoPlayer :videoData="videoData" v-if="videoData.dash && videoData.dash !=''"/> -->
      <VideoJSPlayer
        ref="player"
        :isCleanMode="isCleanMode"
        :src-captions="videoData.captions"
        :src="getSource(videoData)"
        :type="getSourceType(videoData)"
        :isLive="false"
        :markers="getTimeStamps(videoData)"
        :offset="offset"
      />
      <!-- <VimeoVideoPlayer
        :videoData="videoData"
        v-if="videoData.vimeo && videoData.vimeo != ''"
      /> -->
    </div>
    <div
      v-if="currentSelectedVideoId != videoData.id"
      style="position: relative"
      v-lazy-container="{
        selector: 'img',
        error: '@/assets/img/mobishaala_player_poster_loading.jpg',
        loading: '@/assets/img/mobishaala_player_poster_loading.jpg',
      }"
    >
      <img
        class="responsive-img"
        :data-src="
          videoData.thumbnail && videoData.thumbnail != ''
            ? videoData.thumbnail
            : '@/assets/img/mobishaala_player_poster_loading.jpg'
        "
        width="100%"
        height="150px"
      />
      <a
        class="play-buttons btn-floating btn-large waves-effect waves-light red"
        @click="setCurrentSelectedVideoId(videoData.id)"
      >
        <i class="material-icons">play_arrow</i>
      </a>
    </div>
  </div>
</template>
<style scoped>
.play-buttons {
  position: absolute;
  left: calc(50% - 28px);
  top: calc(50% - 28px);
}
</style>

<script>
// import MobishaalaVideoPlayer from "@/components/VideoPlayer/Players/MobishaalaVideoPlayer.vue";
// import YoutubeVideoPlayer from "@/components/VideoPlayer/Players/YoutubeVideoPlayer.vue";
// import VimeoVideoPlayer from "@/components/VideoPlayer/Players/VimeoVideoPlayer.vue";
import VideoJSPlayer from "@/components/VideoPlayer/Players/VideoJSPlayer.vue";

import URL from "@/constants/url.js";

export default {
  props: ["videoData", "currentSelectedVideoId", "isCleanMode", "offset"],
  data() {
    return {
      S3_VIDEO_ENDPOINT: URL.S3_VIDEO_ENDPOINT,
    };
  },
  components: {
    // FlowPlayer,
    VideoJSPlayer,
    // MobishaalaVideoPlayer,
    // YoutubeVideoPlayer,
    // VimeoVideoPlayer,
  },
  methods: {
    setCurrentSelectedVideoId: function (id) {
      this.$emit("set-current-selected-video-id", id);
    },
    seekTo(time) {
      this.$refs.player.seekTo(time)
    },
    seekToTimestamp(timestamp){
      this.$refs.player.seekTo(timestamp.start_time)
    },
    getCurrentTime() {
      return this.$refs.player.getCurrentTime()
    },
    getSource(video) {
      if (video.hls && video.hls != "") {
        return video.hls;
      } else if (video.youtube && video.youtube != "") {
        return video.youtube;
      }
    },
    getSourceType(video) {
      if (video.hls && video.hls != "") {
        return "application/x-mpegURL";
      } else if (video.youtube && video.youtube != "") {
        return "video/youtube";
      }
    },
    getTimeStamps(video) {
      if (video.timestamps) {
        return video.timestamps.filter(it=>it.type=="player").map((it) => {
          return { time: it.start_time, text: it.caption };
        });
      } else {
        return null;
      }
    },
    getOffset(video){
       if (video.timestamps) {
        return video.timestamps.filter(it=>it.type=="standalone").map((it) => {
          return { start: it.start_time, end: it.start_time+it.duration, restart_beginning: false };
        })[0];
      } else {
        return null;
      }
    }
  },
};
</script>


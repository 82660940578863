<template>
  <BaseModal ref="modal" class="baseModal" @onClose="onModalClose">
    <nav>
      <div class="nav-wrapper blue">
        <a
          @click="templateId = ''"
          style="
            position: absolute;
            color: #fff;
            display: inline-block;
            font-size: 2.1rem;
            padding: 0;
            margin-left: 10px;
          "
          ><i class="material-icons" v-if="templateId != ''">arrow_back</i
          >Editor</a
        >
        <ul class="right">
          <li v-if="templateId != ''">
            <a @click="$refs.editor.generateImage('png')">Save</a>
          </li>
          <li>
            <a @click="close()"><i class="material-icons">close</i></a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="modal-content" style="padding: 0" v-if="isOpen">
      <TemplatePicker
        :prefillObject="prefillObject"
        :categoryTypeId="categoryTypeId"
        @template-selected="templateId = $event"
        v-if="templateId == ''"
      />
      <Editor
        v-else
        ref="editor"
        :prefillObject="prefillObject"
        :templateId="templateId"
        :preRender="preRender"
        @image-generated="
          $emit('image-generated', $event);
          close();
        "
      />
    </div>
  </BaseModal>
</template>
<script>
import BaseModal from "@/components/Common/BaseModal.vue";
import Editor from "./Editor";
import TemplatePicker from "./TemplatePicker";

export default {
  props: {
    categoryTypeId: {
      type: String,
      default: "2",
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
    preRender: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseModal,
    Editor,
    TemplatePicker,
  },
  data() {
    return {
      templateId: "",
      isOpen: false,
    };
  },
  mounted() {
    this.$refs.modal.isFullscreen(true);
  },
  methods: {
    onModalClose() {
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.isOpen = false;
    },
  },
};
</script>



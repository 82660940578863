<template>
  <div>
    <m-internal-canvas
      ref="canvas"
      :zoom="zoom"
      :width="width"
      :height="height"
      :prefillObject="prefillObject"
      :initBlocks="initBlocks"
      :preRender="preRender"
      @block-selected="selectedBlock = $event"
      @show-loader="showLoader"
      @image-generated="$emit('image-generated', $event)"
    />

    <input
      class="hidden"
      type="file"
      ref="input_image"
      @change="onImageSelected"
      accept="image/x-png, image/gif, image/jpeg"
    />

    <block-control
      :selectedBlock="selectedBlock"
      @clear-selection="$refs.canvas.clearSelection()"
      @delete-selection="$refs.canvas.deleteBlock(selectedBlock)"
      @move-up-selection="$refs.canvas.orderBlock(selectedBlock, true)"
      @move-down-selection="$refs.canvas.orderBlock(selectedBlock, false)"
      @download-block="$refs.canvas.download($event)"
      @add-block="onAddItem"
      @print-state="$refs.canvas.printState()"
      :isDebug="isDebug"
    />

    <text-control
      v-if="selectedBlock.type == 'text'"
      :selectedBlock="selectedBlock"
      @update-selection="updateSelectedObject"
    />

    <rectangle-control
      v-if="selectedBlock.type == 'rectangle'"
      :selectedBlock="selectedBlock"
      @update-selection="updateSelectedObject"
    />
    <image-control
      v-if="selectedBlock.type == 'image'"
      :selectedBlock="selectedBlock"
      @remove-background="$refs.canvas.initRemoveBG(selectedBlock.image.value)"
      @update-selection="updateSelectedObject"
    />

    <div ref="webImageDialog" class="modal">
      <div class="modal-content">
        <h4>Enter URL</h4>
        <p>Enter a fully qualified image url.</p>
        <div class="row">
          <div class="input-field col s12">
            <input placeholder="URL" v-model="webImageURL" type="text" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a
          class="waves-effect waves-green btn-flat"
          @click="closeModal('webImageDialog')"
          >Cancel</a
        >
        <a
          class="waves-effect waves-green btn-flat"
          @click="closeModal('webImageDialog');$refs.canvas.addBlock('image', webImageURL)"
          >Add</a
        >
      </div>
    </div>

    <div ref="loadingModel" class="modal">
      <div class="modal-content">
        <h5>{{ loaderText }}</h5>
      </div>
    </div>

    <stock-image-modal
      ref="stockImageModal"
      @image-selected="$refs.canvas.addBlock('image', $event)"
    />
  </div>
</template>

<script>
import MInternalCanvas from "./MInternalCanvas";
import TextControl from "./navigational/TextControl";
import BlockControl from "./navigational/BlockControl";
import RectangleControl from "./navigational/RectangleControl";
import ImageControl from "./navigational/ImageControl";
import StockImageModal from "./StockImageModal";

export default {
  components: {
    StockImageModal,
    MInternalCanvas,
    TextControl,
    BlockControl,
    RectangleControl,
    ImageControl,
  },
  props: {
    width: {
      type: Number,
      isRequired: true,
    },
    height: {
      type: Number,
      isRequired: true,
    },
    zoom: {
      type: Number,
      isRequired: true,
    },
    initBlocks: {
      type: Array,
      default: () => [],
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
    isDebug: {
      type: Boolean,
      default: false,
    },
    preRender: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    selectedBlock: {},
    showWebImageDialog: false,
    webImageURL: "",
    loaderText: "",
  }),
  mounted() {
    // eslint-disable-next-line
    M.Modal.init(this.$refs.webImageDialog, {});
    // eslint-disable-next-line
    M.Modal.init(this.$refs.loadingModel, {
      dismissible: false,
    });
  },
  methods: {
    generateImage(type) {
      this.$refs.canvas.renderEmit(type);
    },
    closeModal(ref) {
      // eslint-disable-next-line
      M.Modal.getInstance(this.$refs[ref]).close();
    },
    showLoader(data) {
      this.loaderText = data;
      if (data != "") {
        // eslint-disable-next-line
        M.Modal.getInstance(this.$refs.loadingModel).open();
      } else {
        console.log("dismiss");
        // eslint-disable-next-line
        M.Modal.getInstance(this.$refs.loadingModel).close();
      }
    },
    onAddItem(key) {
      if (key == "image") this.$refs.input_image.click();
      else if (key == "wimage") {
        // eslint-disable-next-line
        M.Modal.getInstance(this.$refs.webImageDialog).open();
      } else if (key == "stock") {
        this.$refs.stockImageModal.open();
      } else this.$refs.canvas.addBlock(key);
    },
    onImageSelected: function (e) {
      if (e.target.value == "") {
        // eslint-disable-next-line
        console.log("Nothing selected");
        return;
      }
      var file = e.target.files[0];
      this.imageFile = file;
      var fileReader = new FileReader();
      const vm = this;
      fileReader.onload = function (evt) {
        vm.$refs.canvas.addBlock("image", evt.target.result);
      };
      fileReader.readAsDataURL(file);
    },
    updateSelectedObject(block) {
      this.$refs.canvas.updateBlock(block);
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
<template>
  <div>
    <div ref="canvasHolder">
      <vue-aspect-ratio
        class="canvas-holder"
        :ar="width + ':' + height"
        :width="calculatedCanvasWidth + 'px'"
      >
        <div class="_canvas-back">
          <div ref="_canvas" class="_canvas">
            <div
              :ref="block.id"
              :id="block.id"
              class="item"
              :class="{ active: selectedBlock.id == block.id }"
              v-for="(block, index) in blocks"
              :key="block.id"
              @click="selectBlock(block)"
              @contextmenu="showContextMenuInit($event, block)"
              :data-id="block.id"
              :style="'z-index:' + index"
            >
              <div
                :style="
                  'color:' +
                  block.text.color +
                  ';' +
                  'background:' +
                  block.text.background +
                  ';' +
                  'text-align:' +
                  block.text.alignment +
                  ';' +
                  'font-size:' +
                  block.text.fontSize +
                  'px;' +
                  'font-family:' +
                  block.text.font +
                  ';'
                "
                v-if="block.type == 'text'"
              >
                <span
                  v-if="!block.text.isEdit"
                  contentEditable="true"
                  @input="handleInput($event, block)"
                  :class="{
                    'block-text-bold': block.text.isBold,
                    'block-text-italics': block.text.isItalics,
                  }"
                  v-html="block.text.value"
                >
                </span>
                <!-- <div v-if="block.text.isEdit">
                  <input
                    type="text"
                    style="border: 1px black solid"
                    :style="
                      'color:' +
                      block.text.color +
                      ';' +
                      'background:' +
                      block.text.background +
                      ';' +
                      'text-align:' +
                      block.text.alignment +
                      ';' +
                      'font-size:' +
                      block.text.fontSize +
                      'px;' +
                      'font-family:' +
                      block.text.font +
                      ';'
                    "
                    v-model="block.text.value"
                  />
                  <div
                    class="btn green"
                    @click="
                      block.text.isEdit = false;
                      updateBlock(block);
                    "
                    color="green"
                  >
                    Done
                  </div>
                </div> -->
              </div>

              <img
                draggable="false"
                crossOrigin="anonymous"
                class="image-block"
                v-if="block.type == 'image'"
                :src="block.image.value"
              />

              <div
                class="rectangle-block"
                v-if="block.type == 'rectangle'"
                :style="'background:' + block.rectangle.background + ';'"
              />

              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle vright vbottom js-resize-right js-resize-bottom"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle vright vtop js-resize-right js-resize-top"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle vleft vbottom js-resize-left js-resize-bottom"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle vleft vtop js-resize-left js-resize-bottom"
              ></div>

              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle eright js-resize-right"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle etop js-resize-top"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle ebottom js-resize-bottom"
              ></div>
              <div
                v-if="selectedBlock.id == block.id"
                class="resize-handle eleft js-resize-left"
              ></div>
            </div>
          </div>
        </div>
      </vue-aspect-ratio>
    </div>
  </div>
</template>
<script>
import interact from "interactjs";
import VueAspectRatio from "vue-aspect-ratio";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
//eslint-disable-next-line
import * as tfjs from "@tensorflow/tfjs";
import * as bodyPix from "@tensorflow-models/body-pix";
import Jimp from "jimp/es";
import { debounce } from "lodash";

export default {
  components: {
    VueAspectRatio,
  },
  props: {
    width: {
      type: Number,
      isRequired: true,
    },
    height: {
      type: Number,
      isRequired: true,
    },
    zoom: {
      type: Number,
      isRequired: true,
    },
    initBlocks: {
      type: Array,
      default: () => [],
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
    preRender: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    blocks: [],
    selectedBlock: {},
    doubleClickDelay: 700,
    lastClickTime: -1,
    showContextMenu: false,
    contextMenuX: 0,
    contextMenuY: 0,
    calculatedCanvasWidth: 500,
    tf_bodymodel: null,
  }),
  mounted() {
    if ((this.height * this.$refs.canvasHolder.offsetWidth) / this.width > 500)
      this.calculatedCanvasWidth = (this.width * 500) / this.height;
    else this.calculatedCanvasWidth = this.$refs.canvasHolder.offsetWidth - 20;
    if (this.initBlocks.length != 0) this.loadInitBlocks();
  },
  watch: {
    selectedBlock(newValue) {
      this.$emit("block-selected", newValue);
    },
  },
  methods: {
    handleInput: debounce(function (event, block) {
      block.text.value = event.target.innerHTML;
    }, 1500),
    hanleInput(event, block) {
      block.text.value = event.target.innerHTML;
    },
    async loadTFModel() {
      if (!this.bodymodel) {
        const resNet = {
          architecture: "ResNet50",
          outputStride: 16,
          quantBytes: 4,
        };
        this.bodymodel = await bodyPix.load(resNet);
      }
    },
    async Prediction(image) {
      await this.loadTFModel();
      return this.bodymodel.segmentPersonParts(image, {
        internalResolution: "medium",
      });
    },
    async removeBG(img) {
      //tf.browser.fromPixels
      // const tfimg = tfjs.browser.decodeImage(img);
      const bodySeg = await this.Prediction(img);
      const jimp = await Jimp.read({
        url: img.src,
      });
      let count = 0;
      for (let i = 0; i < bodySeg.height; i++) {
        for (let j = 0; j < bodySeg.width; j++) {
          if (bodySeg.data[count] === -1) {
            jimp.setPixelColor(0x00000000, j, i);
          }
          count++;
        }
      }
      return jimp.autocrop().getBase64Async("image/png");
    },
    initRemoveBG(url) {
      const img = new Image();
      let vm = this;
      vm.$emit("show-loader", "Converting");
      img.onload = function () {
        vm.removeBG(this)
          .then((res) => {
            vm.addBlock("image", res);
            vm.$emit("show-loader", "");
          })
          .catch(() => {
            vm.$emit("show-loader", "");
          });
      };
      img.onerror = function () {
        vm.$emit("show-loader", "");
      };
      img.crossOrigin = "anonymous";
      img.src = url;
    },
    download(type) {
      this.clearSelection();
      let canvas = this.$refs._canvas;
      if (type == "png")
        domtoimage
          .toBlob(canvas, {
            // height: canvas.offsetHeight,
            // width: canvas.offsetWidth,
            height: this.height,
            width: this.width,
            style: {
              height: this.height,
              width: this.width,
              zoom: this.width / canvas.offsetWidth,
            },
          })
          .then(function (blob) {
            saveAs(blob, "image.png");
          });
      else if (type == "jpg")
        domtoimage
          .toJpeg(canvas, {
            quality: 0.95,
            bgcolor: "white",
            // height: canvas.offsetHeight,
            // width: canvas.offsetWidth,
            height: this.height,
            width: this.width,
            style: {
              height: this.height,
              width: this.width,
              zoom: this.width / canvas.offsetWidth,
            },
          })
          .then(function (dataUrl) {
            saveAs(dataUrl, "image.jpeg");
          });
    },
    renderEmit(type) {
      let vm = this;
      this.clearSelection();
      let canvas = this.$refs._canvas;
      if (type == "png")
        domtoimage
          .toBlob(canvas, {
            // height: canvas.offsetHeight,
            // width: canvas.offsetWidth,
            height: this.height,
            width: this.width,
            style: {
              height: this.height,
              width: this.width,
              zoom: this.width / canvas.offsetWidth,
            },
          })
          .then(function (blob) {
            let b = JSON.parse(JSON.stringify(vm.blocks));
            let state = {
              width: vm.width,
              height: vm.height,
              zoom: vm.zoom,
              blocks: b.map((item) => {
                if (item.text && item.text.fontSize) {
                  item.text.fontSize = item.text.fontSize / canvas.offsetHeight;
                }
                return item;
              }),
            };
            vm.$emit("image-generated", { url: blob, template: state });
            // vm.$emit("image-generated", blob);
          });
      else if (type == "jpg")
        domtoimage
          .toJpeg(canvas, {
            quality: 0.95,
            bgcolor: "white",
            // height: canvas.offsetHeight,
            // width: canvas.offsetWidth,
            height: this.height,
            width: this.width,
            style: {
              height: this.height,
              width: this.width,
              zoom: this.width / canvas.offsetWidth,
            },
          })
          .then(function (dataUrl) {
            let b = JSON.parse(JSON.stringify(vm.blocks));
            let state = {
              width: vm.width,
              height: vm.height,
              zoom: vm.zoom,
              blocks: b.map((item) => {
                if (item.text && item.text.fontSize) {
                  item.text.fontSize = item.text.fontSize / canvas.offsetHeight;
                }
                return item;
              }),
            };
            vm.$emit("image-generated", { url: dataUrl, template: state });
          });
    },
    loadInitBlocks() {
      let initBlocksCopy = JSON.parse(JSON.stringify(this.initBlocks));
      console.log("prefil details", this.prefillObject);
      for (let block in initBlocksCopy) {
        let blk = initBlocksCopy[block];
        if (blk.ref && blk.ref != "") {
          if (
            this.prefillObject &&
            this.prefillObject[blk.ref] &&
            this.prefillObject[blk.ref] != ""
          ) {
            if (blk.type == "text")
              blk.text.value = this.prefillObject[blk.ref];
            else if (blk.type == "image")
              blk.image.value = this.prefillObject[blk.ref];
          }
        }
        this.blocks.push(blk);
      }
      this.$nextTick(() => {
        let canvas = this.$refs._canvas;
        let initB = initBlocksCopy.map((item) => {
          if (item.text && item.text.fontSize) {
            item.text.fontSize = item.text.fontSize * canvas.offsetHeight;
          }
          return item;
        });
        for (let block in initB) {
          this.$refs[initB[block].id][0].style.transform =
            "translate(" +
            initB[block].x * canvas.offsetWidth +
            "px, " +
            initB[block].y * canvas.offsetHeight +
            "px)";

          this.$refs[initB[block].id][0].style.width =
            initB[block].width * canvas.offsetWidth + "px";
          this.$refs[initB[block].id][0].style.height =
            initB[block].height * canvas.offsetHeight + "px";

          this.$refs[initB[block].id][0].setAttribute(
            "data-x",
            initB[block].x * canvas.offsetWidth
          );
          this.$refs[initB[block].id][0].setAttribute(
            "data-y",
            initB[block].y * canvas.offsetHeight
          );

          this.makeDraggable(this.$refs[initB[block].id][0]);
          this.makeResizable(
            this.$refs[initB[block].id][0],
            initB[block].type == "image"
          );
        }

        if (this.preRender) {
          this.$nextTick(() => {
            this.renderEmit("png");
          });
        }
      });
    },
    printState() {
      let canvas = this.$refs._canvas;
      let b = JSON.parse(JSON.stringify(this.blocks));
      let state = {
        width: this.width,
        height: this.height,
        zoom: this.zoom,
        blocks: b.map((item) => {
          if (item.text && item.text.fontSize) {
            item.text.fontSize = item.text.fontSize / canvas.offsetHeight;
          }
          return item;
        }),
      };
      // eslint-disable-next-line
      console.log(JSON.stringify(state));
    },
    orderBlock(item, isUp) {
      let index = this.blocks.map((item) => item.id).indexOf(item.id);
      if (index != -1) {
        if (isUp && index != this.blocks.length - 1) {
          let tempIndex = this.blocks[index].index;
          this.blocks[index].index = this.blocks[index + 1].index;
          this.blocks[index + 1].index = tempIndex;

          [this.blocks[index], this.blocks[index + 1]] = [
            this.blocks[index + 1],
            this.blocks[index],
          ];
        }
        if (!isUp && index != 0) {
          let tempIndex = this.blocks[index].index;
          this.blocks[index].index = this.blocks[index - 1].index;
          this.blocks[index - 1].index = tempIndex;
          [this.blocks[index], this.blocks[index - 1]] = [
            this.blocks[index - 1],
            this.blocks[index],
          ];
        }

        this.$forceUpdate();
      }
    },
    showContextMenuInit(e, block) {
      this.selectedBlock = block;
      e.preventDefault();
      this.showContextMenu = false;
      this.contextMenuX = e.clientX;
      this.contextMenuY = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },
    clearSelection() {
      this.selectedBlock = {};
    },
    selectBlock(block, isEdit = false) {
      if (block.text && block.text.isEdit) return;
      if (block.type == "text") block.text.isEdit = isEdit;
      this.selectedBlock = block;
    },
    updateBlock(item) {
      let index = this.blocks.map((item) => item.id).indexOf(item.id);
      if (index != -1) this.blocks[index] = item;
      this.clearSelection();
    },
    updateBlockPosition(id, x, y, w, h) {
      let index = this.blocks.map((item) => item.id).indexOf(id);
      let canvas = this.$refs._canvas;
      if (index != -1) {
        this.blocks[index].x = x / canvas.offsetWidth;
        this.blocks[index].y = y / canvas.offsetHeight;
        if (w != -1) this.blocks[index].width = w / canvas.offsetWidth;
        if (h != -1) this.blocks[index].height = h / canvas.offsetHeight;
      }
    },
    deleteBlock(item) {
      let index = this.blocks.map((item) => item.id).indexOf(item.id);
      if (index != -1) this.blocks.splice(index, 1);
      this.showContextMenu = false;
      this.selectedBlock = {};
    },
    addBlock(key, url = "") {
      let index = Math.max(...this.blocks.map((item) => item.index), -1);
      if (key == "text") {
        let item = {
          id: "text" + new Date().getTime(),
          type: key,
          index: index + 1,
          x: 0,
          y: 0,
          width: 250,
          height: 200,
          text: {
            color: "black",
            background: "none",
            alignment: "center",
            fontSize: 25,
            font: "'Roboto', sans-serif",
            isEdit: false,
            isBold: false,
            isItalics: false,
            value: "Some text",
          },
        };
        this.blocks.push(item);
        this.$nextTick(() => {
          this.makeDraggable(this.$refs[item.id][0]);
          this.makeResizable(this.$refs[item.id][0]);
        });
      } else if (key == "image") {
        const img = new Image();
        let vm = this;
        img.onload = function () {
          let item = {
            id: "image" + new Date().getTime(),
            type: key,
            index: index + 1,
            x: 0,
            y: 0,
            width: 250,
            height: 200,
            image: {
              value: url,
            },
          };
          vm.blocks.push(item);
          vm.$nextTick(() => {
            let sw = 200;
            let sh = (200 * this.height) / this.width;
            vm.$refs[item.id][0].style.width = sw + "px";
            vm.$refs[item.id][0].style.height = sh + "px";
            vm.makeDraggable(vm.$refs[item.id][0]);
            vm.makeResizable(vm.$refs[item.id][0], true);
          });
        };
        img.crossOrigin = "anonymous";
        img.src = url;
      } else if (key == "rectangle") {
        let item = {
          id: "rectangle" + new Date().getTime(),
          type: key,
          index: index + 1,
          x: 0,
          y: 0,
          width: 250,
          height: 200,
          rectangle: {
            background: "red",
          },
        };
        this.blocks.push(item);
        this.$nextTick(() => {
          this.makeDraggable(this.$refs[item.id][0]);
          this.makeResizable(this.$refs[item.id][0]);
        });
      }
    },
    makeDraggable(item) {
      interact(item).draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        // modifiers: [
        //   interact.modifiers.restrictRect({
        //     restriction: "parent",
        //   }),
        // ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
          // call this function on every dragmove event
          move: this.dragMoveListener,
        },
      });
    },
    makeResizable(item, preserveAspectRatio = false) {
      let modifier = [];
      if (preserveAspectRatio) {
        modifier = [
          interact.modifiers.aspectRatio({
            // ratio may be the string 'preserve' to maintain the starting aspect ratio,
            // or any number to force a width/height ratio
            ratio: "preserve",
            // To add other modifiers that respect the aspect ratio,
            // put them in the aspectRatio.modifiers array
            // modifiers: [interact.modifiers.restrictSize({ max: "parent" })],
          }),
        ];
      }

      interact(item).resizable({
        // resize from all edges and corners
        edges: {
          left: ".js-resize-left",
          right: ".js-resize-right",
          bottom: ".js-resize-bottom",
          top: ".js-resize-top",
        },
        modifiers: modifier,
        listeners: {
          move: this.resizeMoveListener,
        },
      });
    },
    resizeMoveListener(event) {
      var target = event.target;
      var x = parseFloat(target.getAttribute("data-x")) || 0;
      var y = parseFloat(target.getAttribute("data-y")) || 0;

      // update the element's style
      target.style.width = event.rect.width + "px";
      target.style.height = event.rect.height + "px";

      // translate when resizing from top or left edges
      x += event.deltaRect.left;
      y += event.deltaRect.top;

      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px," + y + "px)";

      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);

      this.updateBlockPosition(
        target.getAttribute("data-id"),
        x,
        y,
        event.rect.width,
        event.rect.height
      );
    },
    dragMoveListener(event) {
      var target = event.target;
      if(target.className != "item active") return;
      // keep the dragged position in the data-x/data-y attributes
      var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px, " + y + "px)";

      // update the posiion attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);

      this.updateBlockPosition(target.getAttribute("data-id"), x, y, -1, -1);
    },
  },
};
</script>

<style scoped>
.canvas-holder {
  max-height: 500px;
  width: 90%;
  padding: 16px;
  margin: 0 auto;
}
._canvas-back {
  background: linear-gradient(90deg, #f7f7f7 21px, transparent 1%) center,
    linear-gradient(#f7f7f7 21px, transparent 1%) center, #000000;
  background-size: 22px 22px;
  border: 1px rgba(255, 0, 0, 0.4) solid;
  height: 100%;
  width: 100%;
  /* font-size: 100px; */
}
._canvas {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
.item {
  position: absolute;
  width: 250px;
  height: 200px;
  min-width: 2px;
  min-height: 2px;
  user-select: none;
  color: black;
  font-size: 1rem;
  touch-action: none;
  /* This makes things *much* easier */
  box-sizing: border-box;
  border: 2px solid transparent;
}

.item .image-block {
  width: 100%;
  height: 100%;
}
.item .rectangle-block {
  width: 100%;
  height: 100%;
}
.item.active {
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  cursor: move;
}

.vtop {
  top: -5px;
}
.vbottom {
  bottom: -5px;
}
.vleft {
  left: -5px;
}
.vright {
  right: -5px;
}
.etop {
  top: -5px;
  left: calc(50% - 5px);
}
.ebottom {
  bottom: -5px;
  left: calc(50% - 5px);
}
.eleft {
  left: -5px;
  top: calc(50% - 5px);
}
.eright {
  right: -5px;
  top: calc(50% - 5px);
}

.block-text-bold {
  font-weight: bold;
}
.block-text-italics {
  font-style: italic;
}
</style>
import STORAGE from "@/constants/storage.js";

export function handleNetworkError(error, vm) {
    // eslint-disable-next-line
    console.log(error);
    // eslint-disable-next-line
    console.log(JSON.parse(JSON.stringify(error)));


    if (error.response.data.code == 2305 || error.response.data.code == 2306 || error.response.data.code == 9000) {
        // eslint-disable-next-line
        // M.toast({ html: "Session Expired. Please sign in again." });
        localStorage.clear();
        vm.$router.push("/login");
        localStorage.setItem(STORAGE.HAS_LOGGED_IN_BEFORE, true)
    } else {

        if (error.response.data.user_msg) {
            // eslint-disable-next-line
            M.toast({ html: error.response.data.user_msg });
        } else {
            // eslint-disable-next-line
            M.toast({ html: error.response.data.msg });
        }


        for (var property in error.response.data.data) {
            // eslint-disable-next-line
            if (error.response.data.data.hasOwnProperty(property)) {
                for (var item in error.response.data.data[property]) {
                    var printItem = error.response.data.data[property][item]
                    var printProperty = property

                    if (printItem.indexOf("name must make a unique set") !== -1) {
                        printItem = "Name already exists. Please use another name."
                        printProperty = "Name"
                    }

                    else if (printItem.indexOf("content must make a unique set") !== -1) {
                        if (
                            vm.$route.name == "New Question" ||
                            vm.$route.name == "Edit Question"
                        ) {
                            printItem = "Question already exists. Question must be unique."
                            printProperty = "Question"
                        }
                    }
                    else if (printItem.indexOf("subject must make a unique set") !== -1) {
                        if (
                            vm.$route.name == "View Plan"
                        ) {
                            printItem = "Subject already exists. You cannot add the same subject again."
                            printProperty = "Subject"
                        }
                    }
                    else if (printItem.indexOf("test must make a unique set") !== -1) {
                        if (
                            vm.$route.name == "View Plan"
                        ) {
                            printItem = "Test already exists. You cannot add the same test again."
                            printProperty = "Test"
                        }
                    }
                    else if (printItem.indexOf("ebook must make a unique set") !== -1) {
                        if (
                            vm.$route.name == "View Plan"
                        ) {
                            printItem = "eBook/Note already exists. You cannot add the same eBook/Note again."
                            printProperty = "eBook/Note"
                        }
                    }


                    // eslint-disable-next-line
                    M.toast({ html: printProperty + " : " + printItem + "" });
                }
            }
        }
    }
}

export default {
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    USERNAME: "username",
    EMAIL: "email",
    TOKEN: "token",
    USER_ID: "user_id",
    ORG_APP: "org_app",
    ORG_ID: "org_id",
    ORG_NAME: "org_name",
    ROUTE_LIST: "routeList",
    INSTITUTE_LIST: "instituteList",
    HAS_LOGGED_IN_BEFORE: "hasLoggedInBefore",
    SHOW_CONGRATS_MESSAGE: "showCongratsMessage",
    CHAPTER_LIST_REVERSED: "chapterListReversed",
    QUESTION_LIST_REVERSED: "questionListReversed",
    ADD_QUESTION_IDEAL_TIME: "addQuestionIdealTime",
    ADD_QUESTION_POSITIVE_MARKS: "addQuestionPositiveMarks",
    ADD_QUESTION_NEGATIVE_MARKS: "addQuestionNegativeMarks",
    ADD_QUESTION_TAGS: "addQuestionTags",
    ADD_QUESTION_DIFFICULTY: "addQuestionDifficulty",
    ADD_QUESTION_EXAM: "addQuestionExam",
    ADD_QUESTION_SUBJECT: "addQuestionSUBJECT",
    ADD_QUESTION_CHAPTER: "addQuestionCHAPTER",
    ADD_QUESTION_TOPIC: "addQuestionTOPIC",
    ADD_QUESTION_SUBTOPIC: "addQuestionSUBTOPIC",
    BANK_DIALOG_LAST_DATE: "bankDialogLastDate",
    SCROLL_STATE_QUESTION_LIST: "scrollStateQuestionList",
    MATH_EDITOR_FONT_SIZE: "MATH_EDITOR_FONT_SIZE",

    PREVIOUS_SUBJECT_SELECTED_ID: "PREVIOUS_SUBJECT_SELECTED_ID",
    PREVIOUS_CHAPTER_SELECTED_ID: "PREVIOUS_CHAPTER_SELECTED_ID",
    PREVIOUS_TOPIC_NAME: "PREVIOUS_TOPIC_NAME",


    SETTINGS_DOUBT_ENABLED: "SETTINGS_DOUBT_ENABLED",
    SETTINGS_MAX_VIDEO_FILE_SIZE: "SETTINGS_MAX_VIDEO_FILE_SIZE",
    SETTINGS_PLAN_ENROLMENT_REQUEST_ENABLED: "SETTINGS_PLAN_ENROLMENT_REQUEST_ENABLED",
    SETTINGS_PLAN_ENROLMENT_ENABLED: "SETTINGS_PLAN_ENROLMENT_ENABLED",
    SETTINGS_PLAN_DEACTIVATION_ENABLED: "SETTINGS_PLAN_DEACTIVATION_ENABLED"
}
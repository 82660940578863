<template>
  <div ref="videoPicker" class="modal">
    <div class="modal-content">
      <h5 style="margin:0">
        Video Gallery
        <div
          class="preloader-wrapper small active"
          style="width: 20px;height: 20px;"
          v-if="isBusy"
        >
          <div class="spinner-layer spinner-green-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </h5>
      <div
        class="row"
        style="height:100%;"
        id="picker_page1"
        v-if="selectedTopic == null"
      >
        <div class="col s12 m4 picker-window">
          <div class="picker-list">
            <div
              class="row"
              style="margin-bottom:0"
              v-for="categorySubject in categorySubjectList"
              :key="categorySubject.id"
            >
              <div style="margin-left: 10px; */">
                {{ categorySubject.category }}
              </div>
              <ul class="collection">
                <li
                  class="collection-item clickable image-collection-item"
                  style="height: 60px;"
                  @click="selectedSubject = subject"
                  :class="{
                    'blue white-text':
                      selectedSubject && selectedSubject.id == subject.id,
                    'white black-text': !(
                      selectedSubject && selectedSubject.id == subject.id
                    ),
                  }"
                  v-for="subject in categorySubject.subjects"
                  :key="subject.id"
                >
                  <img :src="subject.image" class="square-image" />
                  <span style="margin-left: 40px;display: block;"
                    >{{ subject.order }}. {{ subject.name }}</span
                  >
                  <i
                    v-if="!subject.is_visible"
                    class="secondary-content material-icons red-text"
                    >visibility_off</i
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col s12 m4 picker-window">
          <ul class="collection picker-list">
            <li
              class="collection-item clickable"
              @click="
                selectedChapter = chapter;
                topicList = chapter.topics;
              "
              :class="
                selectedChapter && selectedChapter.id == chapter.id
                  ? 'blue white-text'
                  : 'white black-text'
              "
              v-for="chapter in chapterList"
              :key="chapter.id"
            >
              {{ chapter.index }}. {{ chapter.name }}
              <i
                v-if="!chapter.is_visible"
                class="secondary-content material-icons red-text"
                >visibility_off</i
              >
            </li>
          </ul>
        </div>
        <div class="col s12 m4 picker-window">
          <ul class="collection picker-list">
            <li
              class="collection-item clickable"
              @click="selectedTopic = topic"
              :class="
                selectedTopic && selectedTopic.id == topic.id
                  ? 'blue white-text'
                  : 'white black-text'
              "
              v-for="topic in topicList"
              :key="topic.id"
            >
              {{ topic.index }}. {{ topic.name }}
              <i
                v-if="!topic.is_visible"
                class="secondary-content material-icons red-text"
                >visibility_off</i
              >
              <i
                v-if="topic.video.youtube && topic.video.youtube != ''"
                class="secondary-content material-icons red-text"
                >subscriptions</i
              >
              <i
                v-if="!isTopicValidForEditor(topic)"
                class="secondary-content material-icons red-text"
                >videocam_off</i
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        class="row"
        style="height:100%;"
        id="picker_page2"
        v-if="selectedTopic != null"
      >
        <div class="col s12">
          <a
            @click="selectedTopic = null"
            class="waves-effect waves-light btn blue white-text"
            >{{ selectedSubject.name }}</a
          >
          &nbsp;>&nbsp;
          <a
            @click="selectedTopic = null"
            class="waves-effect waves-light btn blue white-text"
            >{{ selectedChapter.name }}</a
          >
          &nbsp;>&nbsp;
          <a
            @click="selectedTopic = null"
            class="waves-effect waves-light btn blue white-text"
            >{{ selectedTopic.name }}</a
          >
        </div>
        <div class="col s12" style="margin-top:32px;">
          <MultiSourceVideo
            @set-current-selected-video-id="setCurrentSelectedVideoId"
            :videoData="selectedTopic.video"
            :currentSelectedVideoId="currentSelectedVideoId"
          />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <a class="modal-close waves-effect waves-green btn-flat">Cancel</a>
      <a
        @click="emitSelected()"
        class="waves-effect waves-green btn-flat blue-text"
        >Select</a
      >
    </div>
  </div>
</template>
<style scoped>
.square-image {
  position: absolute;
  width: 42px;
  height: 42px;
  overflow: hidden;
  left: 15px;
  display: inline-block;
  vertical-align: middle;
}
.picker-window-header {
  font-size: 18px;
  margin-top: 16px;
}
.modal {
  width: 90% !important;
  height: 90% !important;
}
.modal-content {
  height: calc(100% - 56px);
}
.picker-window {
  border-radius: 10px;
  padding: 16px;
}
.picker-window:nth-child(odd) {
  background-color: #eee;
}
@media only screen and (min-width: 601px) {
  .picker-window {
    height: calc(100% - 56px);
  }
}
.picker-list {
  overflow: scroll;
  height: 100%;
}
</style>

<script>
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import axios from "axios";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import MultiSourceVideo from "@/components/VideoPlayer/MultiSourceVideo.vue";

export default {
  components: { MultiSourceVideo },
  data() {
    return {
      selectedSubject: null,
      selectedChapter: null,
      selectedTopic: null,
      categorySubjectList: [],
      chapterList: [],
      topicList: [],
      isBusy: false,
      currentSelectedVideoId: "",
    };
  },
  watch: {
    selectedSubject: function(newValue) {
      this.chapterList = [];
      this.selectedChapter = null;
      if (newValue != null) this.getAllChapters();
    },
    selectedChapter: function(newValue) {
      this.selectedTopic = null;
      if (newValue == null) {
        this.topicList = [];
      }
    },
    // ,
    // selectedTopic: function(newValue) {
    //   if (newValue == null) {
    //   } else {
    //   }
    // }
  },
  methods: {
    isTopicValidForEditor(topic) {
      if (!topic || !topic.video || !topic.video.raw_video) return false;

      var duration = topic.video.duration || 0;
      var url = topic.video.raw_video.backup_key || "";
      var bucket = topic.video.raw_video.backup_bucket || "";
      var dash = topic.video.dash || "";
      var hls = topic.video.hls || "";
      var thumbnail = topic.video.thumbnail || "";

      let currentTime = new Date().getTime();
      let expiryTime =
        new Date(topic.video.raw_video.created_at).getTime() +
        30 * 24 * 60 * 60 * 1000;

      if (
        duration == 0 ||
        url == "" ||
        bucket == "" ||
        dash == "" ||
        hls == "" ||
        thumbnail == "" ||
        currentTime - expiryTime > 0
      )
        return false;
      else return true;
    },
    emitSelected: function() {
      if (this.selectedTopic == null) {
        // eslint-disable-next-line
        M.toast({ html: "Select Video" });
        return;
      } else {
        this.$emit("selectedVideo", this.selectedTopic);
        // eslint-disable-next-line
        var instance = M.Modal.getInstance(this.$refs.videoPicker);
        instance.close();
      }
    },
    open: function() {
      const vm = this;
      // eslint-disable-next-line
      M.Modal.init(this.$refs.videoPicker, {
        onCloseEnd: function() {
          vm.currentSelectedVideoId = "";
          vm.selectedSubject = null;
          vm.selectedChapter = null;
          vm.selectedTopic = null;
          vm.subjectList = [];
          vm.chapterList = [];
          vm.topicList = [];
        },
      });
      // eslint-disable-next-line
      var instance = M.Modal.getInstance(this.$refs.videoPicker);
      instance.open();
      this.getAllSubjects();
    },
    getAllSubjects: function() {
      const vm = this;
      vm.isBusy = true;
      axios
        .get(URL.SUBJECT_LIST, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function(response) {
          vm.isBusy = false;
          var subjectList = response.data.data;
          var categorySubjectList = subjectList
            .map(function(x) {
              var xx = x;
              if (!xx.category || !xx.category.id) {
                xx.category = {
                  name: "No category",
                  id: "-1",
                  order: -1,
                };
              }
              return xx;
            })
            .sort(function(a, b) {
              if (a.category.id < b.category.id) return -1;
              if (a.category.id > b.category.id) return 1;
              return 0;
            });
          var previousTag = null,
            currentTag = null;
          var categorizedList = [];
          var categorizedSubList = [];
          for (var i = 0; i < categorySubjectList.length; i++) {
            if (
              categorySubjectList[i].category &&
              categorySubjectList[i].category.id
            ) {
              currentTag = categorySubjectList[i].category;
            } else {
              currentTag = {
                name: "No category",
                id: "-1",
                order: -1,
              };
            }

            if (previousTag == null) {
              //ignore case
            } else if (currentTag.id != previousTag.id) {
              categorizedList.push({
                id: previousTag.id,
                category: previousTag.name,
                categoryOrder: previousTag.order,
                subjects: categorizedSubList.sort(function(a, b) {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                }),
              });
              categorizedSubList = [];
            }
            previousTag = currentTag;
            categorizedSubList.push(categorySubjectList[i]);
          }
          if (categorizedSubList.length != 0) {
            categorizedList.push({
              id: previousTag.id,
              category: previousTag.name,
              categoryOrder: previousTag.order,
              subjects: categorizedSubList.sort(function(a, b) {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              }),
            });
            categorizedSubList = [];
          }
          vm.categorySubjectList = categorizedList.sort(function(a, b) {
            if (a.categoryOrder < b.categoryOrder) return -1;
            if (a.categoryOrder > b.categoryOrder) return 1;
            return 0;
          });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
    getAllChapters: function() {
      const vm = this;
      vm.isBusy = true;
      axios
        .get(URL.SUBJECT_DETAILS + this.selectedSubject.id, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function(response) {
          vm.isBusy = false;
          vm.chapterList = response.data.data.chapters;
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
    setCurrentSelectedVideoId: function(id) {
      this.currentSelectedVideoId = id;
    },
  },
};
</script>

<template>
    <div >
        <Home v-bind:institutionDetails="institutionDetails" v-bind:bannerList="bannerList" v-on:institute-data-update="getInstitutionDetails()" v-if="state==0 && !$route.params.subPage" />
        <Step1 v-bind:institutionDetails="institutionDetails" v-on:institute-data-update="getInstitutionDetails()" v-if="state==1" />
        <Step2 v-bind:institutionDetails="institutionDetails" v-bind:bannerList="bannerList" v-on:institute-data-update="getInstitutionDetails()" v-if="state==2"/>
        <EditDetails v-bind:institutionDetails="institutionDetails" v-on:institute-data-update="getInstitutionDetails()" v-if="$route.params.subPage == 'editDetails'"/>
        <EditLogo v-bind:institutionDetails="institutionDetails" v-on:institute-data-update="getInstitutionDetails()" v-if="$route.params.subPage == 'editLogo'"/>
        <AddBanner v-bind:institutionDetails="institutionDetails" v-bind:bannerList="bannerList" v-on:institute-data-update="getInstitutionDetails()" v-if="$route.params.subPage == 'addBanner'"/>
        <UploadBanner v-bind:institutionDetails="institutionDetails" v-bind:bannerList="bannerList" v-on:institute-data-update="getInstitutionDetails()" v-if="$route.params.subPage == 'uploadBanner'"/>
    </div>
</template>
<script>
import Step1 from "@/components/App/Step1.vue";
import Step2 from "@/components/App/Step2.vue";
import Home from "@/components/App/Home.vue";
import EditDetails from "@/components/App/EditDetails.vue";
import EditLogo from "@/components/App/EditLogo.vue";
import AddBanner from "@/components/App/AddBanner.vue";
import UploadBanner from "@/components/App/UploadBanner.vue";


import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import axios from "axios";
import {handleNetworkError} from "@/network/ErrorHandler.js"

export default {
  name: "app",
  data() {
    return {
      state: 0,
      institutionDetails: {},
      bannerList: {}
    };
  },
  components: {
    Home,
    Step1,
    Step2,
    EditDetails,
    EditLogo,
    AddBanner,
    UploadBanner
  },
  mounted() {

    if (
      !(
        localStorage.getItem(STORAGE.TOKEN) &&
        localStorage.getItem(STORAGE.TOKEN) != ""
      )
    ) {
      this.$router.push("login");
    }else{
      localStorage.setItem(STORAGE.HAS_LOGGED_IN_BEFORE,true)
    }
    this.getInstitutionDetails();
    // this.$on("institute-data-update", function() {
    //   // eslint-disable-next-line
    //   console.log("institute-data-update event invoked");
    //   this.getInstitutionDetails();
    //   this.$forceUpdate();
    // });
  },
  methods: {
    getInstitutionDetails: function() {
      const vm = this;
      axios
        .all([
          axios.get(URL.ORG_APP, {
            headers: {
              Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN)
            }
          }),
          axios.get(URL.BANNER_LIST, {
            headers: {
              Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN)
            }
          })
        ])
        .then(
          axios.spread(function(instituteDetails, bannerList) {
            vm.institutionDetails = instituteDetails.data;
            vm.bannerList = bannerList.data
            vm.bannerList.data = vm.bannerList.data.sort((a, b) => a.order - b.order || new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
            vm.decideState();
            // eslint-disable-next-line
            console.log(instituteDetails);
            // eslint-disable-next-line
            console.log(bannerList);
          })
        )
        .catch(error => handleNetworkError(error,vm));
    },
    decideState: function() {
      if (!this.institutionDetails.code) this.state = 1;
      else if (this.bannerList.data.length == 0) this.state = 2;
      else this.state = 0;
    }
  }
};
</script>
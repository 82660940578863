<template>
  <div ref="stockImageModal" class="modal bottom-sheet" style="max-height: 90%">
    <div class="modal-content">
      <h4>Stock Images</h4>
      <a href="https://www.pexels.com" class="pexels-logo">
        <img src="https://images.pexels.com/lib/api/pexels.png" />
      </a>
      <div class="row">
        <div class="input-field col s6">
          <input id="search" type="text" v-model="searchQuery" />
          <label for="search">Search</label>
        </div>
        <div class="col s2">
          <div class="btn-large white black-text" @click="search()">Search</div>
        </div>
      </div>

      <vue-masonry-wall
        :items="photos"
        v-if="photos.length > 0"
        :options="{ width: 300, padding: 12 }"
        @append="loadMore"
      >
        <template v-slot:default="{ item }">
          <div class="card">
            <div class="card-image" @click="selectImage(item.src.large)">
              <img :src="item.src.medium" />
            </div>
            <div class="card-content">
              <p>
                <a class="grey-text" :href="item.photographer_url"
                  >Photo by {{ item.photographer }}</a
                >
              </p>
            </div>
          </div>
        </template>
      </vue-masonry-wall>
    </div>
  </div>
</template>
<script>
import { createClient } from "pexels";
import VueMasonryWall from "vue-masonry-wall";
export default {
  components: { VueMasonryWall },
  data() {
    return {
      client: null,
      searchQuery: "",
      nextUrl: "",
      photos: [],
    };
  },
  mounted() {
    this.client = createClient(
      "563492ad6f9170000100000100d93459b0d940bebc927f95e2312454"
    );
    // eslint-disable-next-line
    M.Modal.init(this.$refs.stockImageModal, {});
  },
  methods: {
    selectImage(image) {
      this.$emit("image-selected", image);
      // eslint-disable-next-line
      M.Modal.getInstance(this.$refs.stockImageModal).close();
    },
    search() {
      if (this.searchQuery == "") return;
      let vm = this;
      this.client.photos
        .search({ query: vm.searchQuery, per_page: 80 })
        .then((photos) => {
          if (photos.next_page) vm.nextUrl = photos.page + 1;
          else vm.nextUrl = "";

          vm.photos = [];
          vm.photos = photos.photos;
        });
    },
    open() {
      // eslint-disable-next-line
      M.Modal.getInstance(this.$refs.stockImageModal).open();
    },
    loadMore() {
      let vm = this;
      this.client.photos
        .search({ page: vm.nextUrl, query: vm.searchQuery, per_page: 80 })
        .then((photos) => {
          if (photos.next_page) vm.nextUrl = photos.page + 1;
          else vm.nextUrl = "";
          vm.photos = vm.photos.concat(photos.photos);
        });
    },
  },
};
</script>
<style scoped>
.pexels-logo {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
}
.pexels-logo img {
  height: 30px;
}
.card-placeholder {
  border: 1px dashed red;
  border-radius: 5px;
  position: relative;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.card-placeholder:hover {
  background-color: rgba(255, 0, 0, 0.1);
}
.card-placeholder:active {
  background-color: rgba(255, 0, 0, 0.2);
}
.vue-grid-item.vue-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.vue-grid-item.vue-grid-placeholder {
  background: green !important;
}
</style>
const S3_UPLOAD_ENDPOINT =
  "https://mobishaala-client-video-uploads.s3.amazonaws.com";
const BASE = process.env.CONFIG_API;
// const BASE = "https://api.mobishaala.com/api";

// test
const GEOCODE_API =
  "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBWeqLP9wkPSYk_bcZ3ZoRw8C3V6SY29Js&address=";
const SHORTY_API = "https://mobishaa.la/api/shortner/addurl";

// prod not working for some reason
// const GEOCODE_API = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBjdEvgumppo3HUsFEEWkisST2S5vtrBWc&address="

const MICRO_SERVICE = "https://jg3s7iedx0.execute-api.ap-south-1.amazonaws.com";
const GENERATE_CERTIFICATE = MICRO_SERVICE + "/certificate";

const API = BASE + "/v1";
const ADMIN_BASE = API + "/institute/admin";

const PRELOGIN = API + "/pre-login/";

const MICRO_S3_VIDEO_UPLOAD_SIGNATURE =
  "https://micro-api.mobishaala.com/s3-header-handler/";
const S3_VIDEO_UPLOAD_SIGNATURE = ADMIN_BASE + "/s3-header-handler/";
const S3_VIDEO_UPLOAD_SUCCESS = ADMIN_BASE + "/s3-header-success/";

const DISCOVER_API = ADMIN_BASE + "/discover/";

const LOGIN = ADMIN_BASE + "/login/";
const LOGIN_OTP = ADMIN_BASE + "/login/otp/";
const LOGIN_OTP_VERIFY = ADMIN_BASE + "/login/otp/verify/";

const SIGNUP = ADMIN_BASE + "/signup/";
const ORG_APP = ADMIN_BASE + "/org-app/";

const BANNER_ADD = ADMIN_BASE + "/banner/add/";
const BANNER_LIST = ADMIN_BASE + "/banner/list/";
const BANNER_DELETE = ADMIN_BASE + "/banner/";

const STUDENT_LIST = ADMIN_BASE + "/student/";
const STUDENT_ADD = ADMIN_BASE + "/student/";
const STUDENT_DISABLE = ADMIN_BASE + "/student/";

const PLAN_ENROLLMENT_REQUEST = ADMIN_BASE + "/plans/plan-enrollment-request/";

const SUBJECT_LIST = ADMIN_BASE + "/subject/";
const SUBJECT_ADD = ADMIN_BASE + "/subject/";
const SUBJECT_DELETE = ADMIN_BASE + "/subject/";
const SUBJECT_DETAILS = ADMIN_BASE + "/subject/";
const SUBJECT_CATEGORY = ADMIN_BASE + "/subject/category/";

const CHAPTER_LIST = ADMIN_BASE + "/chapter/?subject=";
const CHAPTER_ADD = ADMIN_BASE + "/chapter/";
const CHAPTER_DETAILS = ADMIN_BASE + "/chapter/";

const TOPIC_ADD = ADMIN_BASE + "/topic/";

const VIDEO_ADD = ADMIN_BASE + "/video/";
const TIMESTAMPS = ADMIN_BASE + "/video/timestamps/";

const AUDIO = ADMIN_BASE + "/audio/";

const RAW_VIDEO = ADMIN_BASE + "/raw-videos/";
const RAW_VIDEO_UPDATE = ADMIN_BASE + "/raw-video/";
const INSTITUTE_RAW_VIDEO = ADMIN_BASE + "/institute-raw-videos/";

const LIVE_VIDEO = ADMIN_BASE + "/live-video/";

const REVIEW_VIDEOS = ADMIN_BASE + "/review-videos/?paginate=1";

const TRASH_VIDEOS = ADMIN_BASE + "/deleted-videos/";

const NOTIFICATION = ADMIN_BASE + "/notification/";
const NOTIFICATION_GROUP = ADMIN_BASE + "/notification-group/";
const NOTIFICATION_GROUP_STUDENT = ADMIN_BASE + "/notification-group/student/";

const ADD_TEST = ADMIN_BASE + "/tests/test/";
const PUBLICATION_ADD_TEST = ADMIN_BASE + "/tests/test/?is_publication=1";

const UPLOAD_TEST = ADMIN_BASE + "/tests/test-tsv-upload/";
const TEST_BULK_EDIT = ADMIN_BASE + "/tests/test/marks-update/";

const OBJECTIVE_ROOT = ADMIN_BASE + "/tests/";
const OBJECTIVE_TEST = ADMIN_BASE + `/tests/test/`;

const OBJECTIVE_TEST_PAGINATED = (id) =>
  ADMIN_BASE + `/tests/test/${id}/questions/`;
const OBJECTIVE_TEST_SECTION = ADMIN_BASE + "/tests/section/";
const OBJECTIVE_TEST_QUESTION = ADMIN_BASE + "/tests/question/";
const OBJECTIVE_TEST_QUESTION_SOLUTION =
  ADMIN_BASE + "/tests/question/solution/";

const SUBJECTIVE_TEST = ADMIN_BASE + "/tests/subjective-test/";
const PUBLICATION_SUBJECTIVE_TEST =
  ADMIN_BASE + "/tests/subjective-test/?s_publication=1";

const SUBJECTIVE_TEST_CORRECTION =
  ADMIN_BASE + "/tests/subjective-test/correction/";
const SUBJECTIVE_TEST_CORRECTION_IMAGE =
  ADMIN_BASE + "/tests/subjective-test/correction/image/";
const SUBJECTIVE_TEST_SECTION = ADMIN_BASE + "/tests/subjective-test/section/";
const SUBJECTIVE_TEST_BULK_EDIT =
  ADMIN_BASE + "/tests/subjective-test/marks-update/";

const SUBJECTIVE_TEST_PUBLICATION =
  ADMIN_BASE + "/tests/subjective-test/publication/";
const SUBJECTIVE_TEST_QUESTION =
  ADMIN_BASE + "/tests/subjective-test/question/";
const SUBJECTIVE_TEST_QUESTION_SOLUTION =
  ADMIN_BASE + "/tests/subjective-test/question/solution/";

const QUESTION = ADMIN_BASE + "/tests/question/";
const QUESTION_BULK = ADMIN_BASE + "/tests/question-bulk-create/";

const RANKLIST = ADMIN_BASE + "/tests/student-rank-list/";
const STUDENT_TEST_MARKS = ADMIN_BASE + "/tests/student-test-marks/";

const OPTION = ADMIN_BASE + "/tests/option/";

const NUMERICAL_ANSWER = ADMIN_BASE + "/tests/numeric-answer/";

const ANSWER = ADMIN_BASE + "/tests/answer/";

const TEST_QUESTION_RELATION = ADMIN_BASE + "/tests/test-question-relation/";

const TAG = ADMIN_BASE + "/tests/tag/";

const IMAGE = ADMIN_BASE + "/tests/question/image/";

const EBOOK = ADMIN_BASE + "/ebook/";
const NOTEBOOK = EBOOK + "notebook/";
const NOTEBOOK_PAGE = EBOOK + "notebook/page/";
const NOTEBOOK_QP = EBOOK + "notebook/page/quality-param/";
const EBOOK_TAG = ADMIN_BASE + "/ebook/tag/";

const PLANS = ADMIN_BASE + "/plans/";
const PUBLICCATION_PLANS = ADMIN_BASE + "/plans/?is_publication=1";
const PLANS_CATEGORY = ADMIN_BASE + "/plans/category/";
const PLANS_SUBJECT = ADMIN_BASE + "/plans/plan-subject/";
const PLANS_TEST = ADMIN_BASE + "/plans/plan-test/";
const PLANS_SUBJECTIVE_TEST = ADMIN_BASE + "/plans/plan-subjective-test/";
const PLANS_EBOOK = ADMIN_BASE + "/plans/plan-ebook/";
const PLANS_ENROLLMENT = ADMIN_BASE + "/plans/student-plan-enrollment/";
const PLANS_ENROLLMENT_DEACTIVATE =
  ADMIN_BASE + "/plans/student-plan-enrollment/deactivate/";

const PAYMENT_ORDERS = ADMIN_BASE + "/payments/orders/";
const PAYMENT_ORDER_SUMMARY = ADMIN_BASE + "/payments/orders/summary/";
const PAYMENT_BANK = ADMIN_BASE + "/payments/bank/";
const PAYMENT_BILLING_DATES = ADMIN_BASE + "/payments/billing-dates/";
const PAYMENT_MONTHLY_STATEMENT = ADMIN_BASE + "/payments/monthly-statement/";

const UPLOAD_QUOTA = ADMIN_BASE + "/render-video-quota/";

const SUB_ADMIN = ADMIN_BASE + "/sub-admin/";

const COUPON = ADMIN_BASE + "/discount-coupon/";
const COUPON_ADD_PLAN = ADMIN_BASE + "/discount-coupon/plan/add/";
const COUPON_ADD_STUDENT = ADMIN_BASE + "/discount-coupon/users/add/";

const RENDER_TASK = ADMIN_BASE + "/marketing-videos/render-task/";
const RENDER_TASK_VIDEO = ADMIN_BASE + "/marketing-videos/render-task/video/";
const RENDER_TASK_OBJECT = ADMIN_BASE + "/marketing-videos/render-task/object/";
const S3_SIGNED_URL = ADMIN_BASE + "/marketing-videos/s3-signed-url/";

const IMAGE_GALLERY = ADMIN_BASE + "/org/image/";

const MARKETING_SPOT = ADMIN_BASE + "/marketing-spot/";
const MARKETING_SPOT_ADD_STUDENT = ADMIN_BASE + "/marketing-spot/add-user/";
const MARKETING_SPOT_DELETE_STUDENT =
  ADMIN_BASE + "/marketing-spot/delete-user/";
const DIGITAL_DESIGN = ADMIN_BASE + "/marketing-spot/design/";
const DIGITAL_DESIGN_COMPONENT =
  ADMIN_BASE + "/marketing-spot/design/component/";

const MARKETING_CAMPAIGN = ADMIN_BASE + "/marketing-spot/campaign/";

const SURVEY = ADMIN_BASE + "/survey/";
const SURVEY_ITEM = ADMIN_BASE + "/survey/item/";
const SURVEY_USER = ADMIN_BASE + "/survey/user/";
const SURVEY_PLAN = ADMIN_BASE + "/survey/plan/";

const ORG_APP_TILE = ADMIN_BASE + "/website/org-app-tile/";
const ORG_APP_HTML_PAGE = ADMIN_BASE + "/website/html-page/";
const ORG_APP_DOMAIN_CONFIG = ADMIN_BASE + "/website/domain-config/";
const ORG_APP_DOMAIN_CHECK = ADMIN_BASE + "/website/domain-status-check/";
const ORG_APP_WEB_CONFIG = ADMIN_BASE + "/website/web-config/";
const ORG_APP_APP_DISTRIBUTION = ADMIN_BASE + "/website/app-distribution/";
const ORG_APP_APP_BANNERS = ADMIN_BASE + "/website/image-slider/";

const LIVE_STREAM = ADMIN_BASE + "/live-stream/event/";
const LIVE_MEET = ADMIN_BASE + "/live-stream/meet/";

const YT_AUTH = ADMIN_BASE + "/yt/";
const YT_AUTH_CREDENTIAL = ADMIN_BASE + "/yt/credential/";
const YT_AUTH_URL = ADMIN_BASE + "/yt/url/";

const LOGS_VIDEO = ADMIN_BASE + "/video/logs/";
const TRANSCRIPTION_TASK = ADMIN_BASE + "/transcription-task/";
const TRANSLATION_TASK = ADMIN_BASE + "/translation-task/";
const OCR = "https://micro-api.mobishaala.com/google-vision/ocr/";
const GENERATE_KEYWORDS = ADMIN_BASE + "/marketing-videos/generate-keywords/";

const EXAM_TAGS = ADMIN_BASE + "/exam-tag/";
const SUBJECT_TAGS = ADMIN_BASE + "/subject-tag/";
const CHAPTER_TAGS = ADMIN_BASE + "/chapter-tag/";
const TOPIC_TAGS = ADMIN_BASE + "/topic-tag/";
const SUBTOPIC_TAGS = ADMIN_BASE + "/sub-topic-tag/";

const SRS_EXAM_CATEGORY = ADMIN_BASE + "/exam-category/";
const SRS_TAG_TYPE = ADMIN_BASE + "/tag-type/";
const SRS_TAG_NAME = ADMIN_BASE + "/tag-name/";
const SRS_TAG_NAME_REL = ADMIN_BASE + "/tag-name-rel/";

const URL_SHORTNER = ADMIN_BASE + "/url-shortner/";

const DOUBT = ADMIN_BASE + "/doubt/";

const QUALITY_PARAMS = ADMIN_BASE + "/ebook/sri/quality-parameter/";

const QRCODE_GENERATOR = (id) =>
  ADMIN_BASE + `/plans/${id}/generate-access-codes/`;

const TTPVideo = (id) =>
  ADMIN_BASE + `/ebook/notebook/page/video-solution/${id}/`;
const TTP_QUIZ = (id) => ADMIN_BASE + `/ebook/notebook/page/${id}/questions/`;
const ADD_TTP_QUESTION = () => ADMIN_BASE + `/ebook/notebook/page/question/`;
const DELETE_TTP_QUESTION = (id) =>
  ADMIN_BASE + `/ebook/notebook/page/question/${id}/`;
const ADDTTP_VIDEO = () => ADMIN_BASE + `/ebook/notebook/page/video-solution/`;
export default {
  DISCOVER_API:DISCOVER_API,
  S3_UPLOAD_ENDPOINT: S3_UPLOAD_ENDPOINT,
  S3_VIDEO_UPLOAD_SIGNATURE: S3_VIDEO_UPLOAD_SIGNATURE,
  S3_VIDEO_UPLOAD_SUCCESS: S3_VIDEO_UPLOAD_SUCCESS,
  MICRO_S3_VIDEO_UPLOAD_SIGNATURE: MICRO_S3_VIDEO_UPLOAD_SIGNATURE,
  QRCODE_GENERATOR: QRCODE_GENERATOR,
  SHORTY_API: SHORTY_API,
  GEOCODE_API: GEOCODE_API,
  TTPVideo: TTPVideo,
  ADDTTP_VIDEO: ADDTTP_VIDEO,
  ADD_TTP_QUESTION: ADD_TTP_QUESTION,
  DELETE_TTP_QUESTION: DELETE_TTP_QUESTION,
  LOGIN: LOGIN,
  PRELOGIN: PRELOGIN,
  SIGNUP: SIGNUP,
  LOGIN_OTP: LOGIN_OTP,
  LOGIN_OTP_VERIFY: LOGIN_OTP_VERIFY,

  ORG_APP: ORG_APP,

  BANNER_ADD: BANNER_ADD,
  BANNER_LIST: BANNER_LIST,
  BANNER_DELETE: BANNER_DELETE,

  STUDENT_LIST: STUDENT_LIST,
  STUDENT_ADD: STUDENT_ADD,
  STUDENT_DISABLE: STUDENT_DISABLE,

  PLAN_ENROLLMENT_REQUEST: PLAN_ENROLLMENT_REQUEST,

  SUBJECT_LIST: SUBJECT_LIST,
  SUBJECT_DETAILS: SUBJECT_DETAILS,
  SUBJECT_ADD: SUBJECT_ADD,
  SUBJECT_DELETE: SUBJECT_DELETE,
  SUBJECT_CATEGORY: SUBJECT_CATEGORY,

  CHAPTER_LIST: CHAPTER_LIST,
  CHAPTER_ADD: CHAPTER_ADD,
  CHAPTER_DETAILS: CHAPTER_DETAILS,

  TOPIC_ADD: TOPIC_ADD,

  REVIEW_VIDEOS: REVIEW_VIDEOS,

  VIDEO_ADD: VIDEO_ADD,
  TIMESTAMPS: TIMESTAMPS,
  LIVE_VIDEO: LIVE_VIDEO,

  AUDIO: AUDIO,

  RAW_VIDEO: RAW_VIDEO,
  RAW_VIDEO_UPDATE: RAW_VIDEO_UPDATE,
  INSTITUTE_RAW_VIDEO: INSTITUTE_RAW_VIDEO,

  NOTIFICATION: NOTIFICATION,
  NOTIFICATION_GROUP: NOTIFICATION_GROUP,
  NOTIFICATION_GROUP_STUDENT: NOTIFICATION_GROUP_STUDENT,

  ADD_TEST: ADD_TEST,
  PUBLICATION_ADD_TEST: PUBLICATION_ADD_TEST,
  TEST_BULK_EDIT: TEST_BULK_EDIT,
  UPLOAD_TEST: UPLOAD_TEST,
  RANKLIST: RANKLIST,
  STUDENT_TEST_MARKS: STUDENT_TEST_MARKS,

  OBJECTIVE_ROOT: OBJECTIVE_ROOT,
  OBJECTIVE_TEST: OBJECTIVE_TEST,
  OBJECTIVE_TEST_PAGINATED: OBJECTIVE_TEST_PAGINATED,
  OBJECTIVE_TEST_SECTION: OBJECTIVE_TEST_SECTION,
  OBJECTIVE_TEST_QUESTION: OBJECTIVE_TEST_QUESTION,
  OBJECTIVE_TEST_QUESTION_SOLUTION: OBJECTIVE_TEST_QUESTION_SOLUTION,
  TTP_QUIZ: TTP_QUIZ,

  SUBJECTIVE_TEST: SUBJECTIVE_TEST,
  PUBLICATION_SUBJECTIVE_TEST: PUBLICATION_SUBJECTIVE_TEST,
  SUBJECTIVE_TEST_CORRECTION: SUBJECTIVE_TEST_CORRECTION,
  SUBJECTIVE_TEST_CORRECTION_IMAGE: SUBJECTIVE_TEST_CORRECTION_IMAGE,
  SUBJECTIVE_TEST_SECTION: SUBJECTIVE_TEST_SECTION,

  SUBJECTIVE_TEST_PUBLICATION: SUBJECTIVE_TEST_PUBLICATION,

  SUBJECTIVE_TEST_QUESTION: SUBJECTIVE_TEST_QUESTION,
  SUBJECTIVE_TEST_QUESTION_SOLUTION: SUBJECTIVE_TEST_QUESTION_SOLUTION,
  SUBJECTIVE_TEST_BULK_EDIT: SUBJECTIVE_TEST_BULK_EDIT,

  QUESTION: QUESTION,
  QUESTION_BULK: QUESTION_BULK,

  OPTION: OPTION,
  NUMERICAL_ANSWER: NUMERICAL_ANSWER,

  ANSWER: ANSWER,

  TEST_QUESTION_RELATION: TEST_QUESTION_RELATION,

  TAG: TAG,

  IMAGE: IMAGE,

  TRASH_VIDEOS: TRASH_VIDEOS,

  EBOOK: EBOOK,
  NOTEBOOK: NOTEBOOK,
  NOTEBOOK_PAGE: NOTEBOOK_PAGE,
  NOTEBOOK_QP: NOTEBOOK_QP,
  EBOOK_TAG: EBOOK_TAG,

  PLANS: PLANS,
  PUBLICCATION_PLANS: PUBLICCATION_PLANS,
  PLANS_CATEGORY: PLANS_CATEGORY,
  PLANS_SUBJECT: PLANS_SUBJECT,
  PLANS_TEST: PLANS_TEST,
  PLANS_SUBJECTIVE_TEST: PLANS_SUBJECTIVE_TEST,
  PLANS_EBOOK: PLANS_EBOOK,
  PLANS_ENROLLMENT: PLANS_ENROLLMENT,
  PLANS_ENROLLMENT_DEACTIVATE: PLANS_ENROLLMENT_DEACTIVATE,

  PAYMENT_ORDERS: PAYMENT_ORDERS,
  PAYMENT_ORDER_SUMMARY: PAYMENT_ORDER_SUMMARY,
  PAYMENT_BANK: PAYMENT_BANK,
  PAYMENT_BILLING_DATES: PAYMENT_BILLING_DATES,
  PAYMENT_MONTHLY_STATEMENT: PAYMENT_MONTHLY_STATEMENT,

  UPLOAD_QUOTA,

  SUB_ADMIN: SUB_ADMIN,

  COUPON: COUPON,
  COUPON_ADD_PLAN: COUPON_ADD_PLAN,
  COUPON_ADD_STUDENT: COUPON_ADD_STUDENT,

  RENDER_TASK: RENDER_TASK,
  RENDER_TASK_VIDEO: RENDER_TASK_VIDEO,
  RENDER_TASK_OBJECT: RENDER_TASK_OBJECT,

  IMAGE_GALLERY: IMAGE_GALLERY,

  MARKETING_SPOT: MARKETING_SPOT,
  MARKETING_SPOT_ADD_STUDENT: MARKETING_SPOT_ADD_STUDENT,
  MARKETING_SPOT_DELETE_STUDENT: MARKETING_SPOT_DELETE_STUDENT,
  DIGITAL_DESIGN: DIGITAL_DESIGN,
  DIGITAL_DESIGN_COMPONENT: DIGITAL_DESIGN_COMPONENT,

  MARKETING_CAMPAIGN: MARKETING_CAMPAIGN,

  SURVEY: SURVEY,
  SURVEY_ITEM: SURVEY_ITEM,
  SURVEY_USER: SURVEY_USER,
  SURVEY_PLAN: SURVEY_PLAN,

  ORG_APP_TILE: ORG_APP_TILE,
  ORG_APP_HTML_PAGE: ORG_APP_HTML_PAGE,
  ORG_APP_DOMAIN_CONFIG: ORG_APP_DOMAIN_CONFIG,
  ORG_APP_DOMAIN_CHECK: ORG_APP_DOMAIN_CHECK,
  ORG_APP_WEB_CONFIG: ORG_APP_WEB_CONFIG,
  ORG_APP_APP_DISTRIBUTION: ORG_APP_APP_DISTRIBUTION,
  ORG_APP_APP_BANNERS: ORG_APP_APP_BANNERS,

  LOGS_VIDEO: LOGS_VIDEO,
  LIVE_STREAM: LIVE_STREAM,
  LIVE_MEET: LIVE_MEET,

  YT_AUTH: YT_AUTH,
  YT_AUTH_CREDENTIAL: YT_AUTH_CREDENTIAL,
  YT_AUTH_URL: YT_AUTH_URL,

  TRANSCRIPTION_TASK: TRANSCRIPTION_TASK,
  TRANSLATION_TASK: TRANSLATION_TASK,
  OCR: OCR,
  GENERATE_KEYWORDS: GENERATE_KEYWORDS,

  EXAM_TAGS: EXAM_TAGS,
  SUBJECT_TAGS: SUBJECT_TAGS,
  CHAPTER_TAGS: CHAPTER_TAGS,
  TOPIC_TAGS: TOPIC_TAGS,
  SUBTOPIC_TAGS: SUBTOPIC_TAGS,

  SRS_EXAM_CATEGORY: SRS_EXAM_CATEGORY,
  SRS_TAG_TYPE: SRS_TAG_TYPE,
  SRS_TAG_NAME: SRS_TAG_NAME,
  SRS_TAG_NAME_REL: SRS_TAG_NAME_REL,

  URL_SHORTNER: URL_SHORTNER,

  DOUBT: DOUBT,

  GENERATE_CERTIFICATE: GENERATE_CERTIFICATE,

  QUALITY_PARAMS: QUALITY_PARAMS,

  S3_SIGNED_URL: S3_SIGNED_URL,
};

<template>
  <v-app id="inspire">
    <div id="app">
      <div
        class="navbar-fixed"
        v-if="
          $route.path != '/login' &&
            $route.path != '/signup' &&
            $route.path != '/loginbda' &&
            $route.path.split('/')[1] != 'printer'
        "
      >
        <nav>
          <div class="nav-wrapper blue">
            <a
              class="brand-logo thin truncate"
              :class="hideNav ? 'zeroPadding' : ''"
              style="margin-left: 10px"
            >
              <i
                class="material-icons navigation-back-button"
                v-if="this.$route.path.split('/').length > 2"
                @click="goBackNavigation()"
                >keyboard_arrow_left</i
              >
              {{ this.$route.name }}
            </a>
            <a data-target="sidenav-mobile" class="sidenav-trigger">
              <i class="clickable material-icons">menu</i>
            </a>
          </div>
        </nav>
      </div>

      <ul
        class="sidenav sidenav-fixed z-depth-1"
        :class="baseUrl.substring(8, 12) == 'test' ? 'test-tag' : 'beta-tag'"
        id="sidenav-mobile"
        v-if="
          !hideNav &&
            $route.path != '/login' &&
            $route.path != '/signup' &&
            $route.path != '/loginbda' &&
            $route.path.split('/')[1] != 'printer'
        "
        tabindex="0"
        @keydown.shift.p="activateSuperMode(!isSuperMode)"
      >
        <li>
          <div class="user-view">
            <a
              @click="hideNav = !hideNav"
              class="btn-floating waves-effect waves-light white"
              style="position: absolute;right: 4px;
              top: 4px;
              width: 30px;
              height: 30px;
            "
            >
              <i class="material-icons blue-text" style="line-height: 30px">
                arrow_back
              </i>
            </a>
            <div class="background headerDrawer">
              <!-- <img class="circle" src="@/assets/img/404.svg"> -->
            </div>
            <!-- <a><img class="circle" src="localstorage.getItem()"></a> -->
            <a>
              <span class="black-text name">{{ instituteName }}</span>
            </a>
            <a>
              <span class="black-text name">{{ headerName }}</span>
            </a>
            <a>
              <span class="black-text email">{{ headerEmail }}</span>
            </a>
          </div>
        </li>

        <li
          :class="$route.path.substring(1) == 'app' ? 'active' : ''"
          v-if="accessRoutes.includes('app')"
        >
          <router-link to="/app" class="sidenav-close">
            <i class="material-icons sidenav-icon">phone_android</i>
            App
          </router-link>
        </li>
        <li
          class="master-option"
          :class="$route.path.substring(1) == 'institute-app' ? 'active' : ''"
          v-if="accessRoutes.includes('institute-app') && isSuperMode"
        >
          <router-link to="/institute-app" class="sidenav-close">
            <i class="material-icons sidenav-icon">phone_android</i>Institute
            App
          </router-link>
        </li>
        <li v-if="accessRoutes.includes('app')">
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('contents') ||
              accessRoutes.includes('addVideo') ||
              accessRoutes.includes('review') ||
              accessRoutes.includes('trash') ||
              accessRoutes.includes('live')
          "
        >
          <a class="subheader blue-text">Video</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'contents' ? 'active' : ''"
          v-if="accessRoutes.includes('contents')"
        >
          <router-link to="/contents" class="sidenav-close">
            <i class="material-icons sidenav-icon">ondemand_video</i>Video
            Content
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'addVideo' ? 'active' : ''"
          v-if="accessRoutes.includes('addVideo')"
        >
          <router-link to="/addVideo" class="sidenav-close">
            <i class="material-icons sidenav-icon">video_library</i>Add Video
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'review' ? 'active' : ''"
          v-if="accessRoutes.includes('review')"
        >
          <router-link to="/review" class="sidenav-close">
            <i class="material-icons sidenav-icon">rate_review</i>Review
          </router-link>
        </li>
        
        <li
          v-if="
            accessRoutes.includes('contents') ||
              accessRoutes.includes('addVideo') ||
              accessRoutes.includes('review')
          "
        >
          <div class="divider"></div>
        </li>
        
        <li
          v-if="
            (accessRoutes.includes('contents') ||
              accessRoutes.includes('addVideo') ||
              accessRoutes.includes('review') ||
              accessRoutes.includes('trash') ||
              accessRoutes.includes('live')) && isSuperMode
          "
        >
          <a class="subheader blue-text">Discover</a>
        </li>

        <li
        class="master-option"
          :class="$route.path.substring(1) == 'discover' ? 'active' : ''"
          v-if="accessRoutes.includes('addVideo') && isSuperMode"
        >
          <router-link to="/discover" class="sidenav-close">
            <i class="material-icons sidenav-icon">ondemand_video</i>Add Content
          </router-link>
        </li>

        <li
          v-if="
            (accessRoutes.includes('contents') ||
              accessRoutes.includes('addVideo') ||
              accessRoutes.includes('review')) && isSuperMode
          "
        >
          <div class="divider"></div>
        </li>

        <li
          v-if="accessRoutes.includes('live') || accessRoutes.includes('meet')"
        >
          <a class="subheader blue-text">Live</a>
        </li>

        <li
          :class="$route.path.substring(1) == 'live' ? 'active' : ''"
          v-if="accessRoutes.includes('live')"
        >
          <router-link to="/live" class="sidenav-close">
            <i class="material-icons sidenav-icon">record_voice_over</i>Live
            Broadcast
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'meet' ? 'active' : ''"
          v-if="accessRoutes.includes('meet')"
        >
          <router-link to="/meet" class="sidenav-close">
            <i class="material-icons sidenav-icon">supervisor_account</i>Live
            Class
          </router-link>
        </li>
        <li
          v-if="accessRoutes.includes('live') || accessRoutes.includes('meet')"
        >
          <div class="divider"></div>
        </li>

        <li v-if="accessRoutes.includes('audio')">
          <a class="subheader blue-text">Audio</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'audio' ? 'active' : ''"
          v-if="accessRoutes.includes('audio')"
        >
          <router-link to="/audio" class="sidenav-close">
            <i class="material-icons sidenav-icon">audiotrack</i>Audio Library
          </router-link>
        </li>
        <li v-if="accessRoutes.includes('audio')">
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('tests') ||
              accessRoutes.includes('subjectivetests')
          "
        >
          <a class="subheader blue-text">Tests</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'tests' ? 'active' : ''"
          v-if="accessRoutes.includes('tests')"
        >
          <router-link to="/tests" class="sidenav-close">
            <i class="material-icons sidenav-icon">hourglass_empty</i>Objective
            Tests
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'subjectivetests' ? 'active' : ''"
          v-if="accessRoutes.includes('subjectivetests')"
        >
          <router-link to="/subjectivetests" class="sidenav-close">
            <i class="material-icons sidenav-icon">assignment</i>Subjective
            Tests
          </router-link>
        </li>

        <li
          v-if="
            (accessRoutes.includes('publications') ||
              accessRoutes.includes('publications-objective')) &&
              isSuperMode
          "
        >
          <a class="subheader blue-text">Publications</a>
        </li>

        <li
          class="master-option"
          :class="$route.path.substring(1) == 'publications' ? 'active' : ''"
          v-if="accessRoutes.includes('publications') && isSuperMode"
        >
          <router-link to="/publications" class="sidenav-close">
            <i class="material-icons sidenav-icon">assignment</i>Subjective
            Publications
          </router-link>
        </li>
        <li
          class="master-option"
          :class="
            $route.path.substring(1) == 'publications-objective' ? 'active' : ''
          "
          v-if="accessRoutes.includes('publications-objective') && isSuperMode"
        >
          <router-link to="/publications-objective" class="sidenav-close">
            <i class="material-icons sidenav-icon">assignment</i>Objective
            Publications
          </router-link>
        </li>
        <li
          class="master-option"
          :class="
            $route.path.substring(1) == 'publications-objective' ? 'active' : ''
          "
          v-if="accessRoutes.includes('publications-objective') && isSuperMode"
        >
          <router-link to="/ttp" class="sidenav-close">
            <i class="material-icons sidenav-icon">assignment</i>TTP
          </router-link>
        </li>
        <li
          class="master-option"
          :class="$route.path.substring(1) == 'question-bank' ? 'active' : ''"
          v-if="isSuperMode"
        >
          <router-link to="/question-bank" class="sidenav-close">
            <i class="material-icons sidenav-icon">quiz</i>Question Bank
          </router-link>
        </li>
        <li
          v-if="
            accessRoutes.includes('tests') ||
              accessRoutes.includes('publications') ||
              accessRoutes.includes('subjectivetests')
          "
        >
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('ebooks') || accessRoutes.includes('notes')
          "
        >
          <a class="subheader blue-text">Reads</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'ebooks' ? 'active' : ''"
          v-if="accessRoutes.includes('ebooks')"
        >
          <router-link to="/ebooks" class="sidenav-close">
            <i class="material-icons sidenav-icon">insert_drive_file</i>eBooks
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'notes' ? 'active' : ''"
          v-if="accessRoutes.includes('notes')"
        >
          <router-link to="/notes" class="sidenav-close">
            <i class="material-icons sidenav-icon">subject</i>Notes
          </router-link>
        </li>
        <li
          v-if="
            accessRoutes.includes('ebooks') || accessRoutes.includes('notes')
          "
        >
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('plans') ||
              accessRoutes.includes('orders') ||
              accessRoutes.includes('payments')
          "
        >
          <a class="subheader blue-text">Payments</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'plans' ? 'active' : ''"
          v-if="accessRoutes.includes('plans')"
        >
          <router-link to="/plans" class="sidenav-close">
            <i class="material-icons sidenav-icon">local_atm</i>Plans
          </router-link>
        </li>
        <li
          class="master-option"
          v-if="accessRoutes.includes('plans') && isSuperMode"
        >
          <router-link to="/publicationplans" class="sidenav-close">
            <i class="material-icons sidenav-icon">local_atm</i>
            Publication Plans
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'orders' ? 'active' : ''"
          v-if="accessRoutes.includes('orders')"
        >
          <router-link to="/orders" class="sidenav-close">
            <i class="material-icons sidenav-icon">confirmation_number</i>Orders
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'payments' ? 'active' : ''"
          v-if="accessRoutes.includes('payments')"
        >
          <router-link to="/payments" class="sidenav-close">
            <i class="material-icons sidenav-icon">attach_money</i>Payment
            history
          </router-link>
        </li>
        <li
          v-if="
            accessRoutes.includes('plans') ||
              accessRoutes.includes('orders') ||
              accessRoutes.includes('payments')
          "
        >
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('students') ||
              accessRoutes.includes('notifications')
          "
        >
          <a class="subheader blue-text">Students and Notification</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'students' ? 'active' : ''"
          v-if="accessRoutes.includes('students')"
        >
          <router-link to="/students" class="sidenav-close">
            <i class="material-icons sidenav-icon">account_circle</i>Students
            <!-- <span class="new badge green">4</span> -->
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'notifications' ? 'active' : ''"
          v-if="accessRoutes.includes('notifications')"
        >
          <router-link to="/notifications" class="sidenav-close">
            <i class="material-icons sidenav-icon">notifications_none</i
            >Notifications
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'doubts' ? 'active' : ''"
          v-if="accessRoutes.includes('doubts') && settings.getDoubtEnabled()"
        >
          <router-link to="/doubts" class="sidenav-close">
            <i class="material-icons sidenav-icon">live_help</i>Doubts
          </router-link>
        </li>
        <li
          v-if="
            accessRoutes.includes('students') ||
              accessRoutes.includes('doubts') ||
              accessRoutes.includes('notifications')
          "
        >
          <div class="divider"></div>
        </li>

        <li
          v-if="
            accessRoutes.includes('print') || accessRoutes.includes('share')
          "
        >
          <a class="subheader blue-text">Marketing</a>
        </li>
        <li
          :class="$route.path.substring(1) == 'campaigns' ? 'active' : ''"
          v-if="accessRoutes.includes('campaigns')"
        >
          <router-link to="/campaigns" class="sidenav-close">
            <i class="material-icons sidenav-icon">art_track</i>Campaigns
          </router-link>
        </li>
        <li
          class="master-option"
          :class="$route.path.substring(1) == 'coupon' ? 'active' : ''"
          v-if="accessRoutes.includes('coupon') && isSuperMode"
        >
          <router-link to="/coupon" class="sidenav-close">
            <i class="material-icons sidenav-icon">card_giftcard</i>Coupons
          </router-link>
        </li>
        <li
          class="master-option"
          :class="$route.path.substring(1) == 'editor' ? 'active' : ''"
          v-if="accessRoutes.includes('editor') && isSuperMode"
        >
          <router-link to="/editor" class="sidenav-close">
            <i class="material-icons sidenav-icon">subscriptions</i>Youtube
            editor
          </router-link>
        </li>
        <li
          class="master-option"
          :class="$route.path.substring(1) == 'fb' ? 'active' : ''"
          v-if="accessRoutes.includes('fb') && isSuperMode"
        >
          <router-link to="/fb" class="sidenav-close">
            <i class="material-icons sidenav-icon">facebook</i>Facebook
            marketing
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'survey' ? 'active' : ''"
          v-if="accessRoutes.includes('survey')"
        >
          <router-link to="/survey" class="sidenav-close">
            <i class="material-icons sidenav-icon">playlist_add_check</i>Survey
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'inapp-marketing' ? 'active' : ''"
          v-if="accessRoutes.includes('inapp-marketing')"
        >
          <router-link to="/inapp-marketing" class="sidenav-close">
            <i class="material-icons sidenav-icon">devices</i>InApp Marketing
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'digital-design' ? 'active' : ''"
          v-if="accessRoutes.includes('digital-design')"
        >
          <router-link to="/digital-design" class="sidenav-close">
            <i class="material-icons sidenav-icon">color_lens</i>Digital Design
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'print' ? 'active' : ''"
          v-if="accessRoutes.includes('print')"
        >
          <router-link to="/print" class="sidenav-close">
            <i class="material-icons sidenav-icon">print</i>Design & Print
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'share' ? 'active' : ''"
          v-if="accessRoutes.includes('share')"
        >
          <router-link to="/share" class="sidenav-close">
            <i class="material-icons sidenav-icon">share</i>Share
          </router-link>
        </li>
        <li
          v-if="
            accessRoutes.includes('print') || accessRoutes.includes('share')
          "
        >
          <div class="divider"></div>
        </li>

        <li>
          <a class="subheader blue-text">Other</a>
        </li>
        <!-- <li><router-link to="/about"><i class="material-icons sidenav-icon">show_chart</i>Analytics</router-link></li> -->
        <!-- <li><router-link to="/about"><i class="material-icons sidenav-icon">attach_money</i>Billing<span class="new badge red" data-badge-caption="Payment pending"></span></router-link></li> -->
        <!-- <li><router-link to="/about"><i class="material-icons sidenav-icon">help_outline</i>Help<span class="new badge red" data-badge-caption="new message">3</span></router-link></li> -->
        <!-- <li :class="$route.path.substring(1) == 'help'?'active':''"><router-link to="/help" class="sidenav-close"><i class="material-icons sidenav-icon">help_outline</i>Help</router-link></li> -->
        <li :class="$route.path.substring(1) == 'help' ? 'active' : ''">
          <router-link to="/help" class="sidenav-close">
            <i class="material-icons sidenav-icon">help</i>Help
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'admins' ? 'active' : ''"
          v-if="accessRoutes.includes('admins')"
        >
          <router-link to="/admins" class="sidenav-close">
            <i class="material-icons sidenav-icon">supervisor_account</i>Admins
          </router-link>
        </li>

        <li
          :class="$route.path.substring(1) == 'tag-manager' ? 'active' : ''"
          v-if="accessRoutes.includes('tag-manager')"
        >
          <router-link to="/tag-manager" class="sidenav-close">
            <i class="material-icons sidenav-icon">sell</i>Tag Manager
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'tools' ? 'active' : ''"
          v-if="accessRoutes.includes('tools')"
        >
          <router-link to="/tools" class="sidenav-close">
            <i class="material-icons sidenav-icon">business_center</i>Tools
          </router-link>
        </li>
        <li
          :class="$route.path.substring(1) == 'settings' ? 'active' : ''"
          v-if="accessRoutes.includes('settings')"
        >
          <router-link to="/settings" class="sidenav-close">
            <i class="material-icons sidenav-icon">settings</i>Settings
          </router-link>
        </li>
        <li>
          <a class="clickable sidenav-close" v-on:click="logout()">
            <i class="material-icons sidenav-icon">power_settings_new</i>Logout
          </a>
        </li>
        <li class="center-align grey-text">{{ APP_VERSION }}</li>
      </ul>

      <router-view v-if="$route.path.split('/')[1] == 'printer'" />
      <main
        v-else
        v-bind:class="{
          zeroPadding:
            hideNav ||
            $route.path == '/login' ||
            $route.path == '/signup' ||
            $route.path == '/loginbda' ||
            $route.path == '/printer',
        }"
        style="min-height: calc(100vh - 130px)"
      >
        <keep-alive>
          <router-view v-if="shouldKeepAlive(this.$route.path)" />
        </keep-alive>

        <router-view v-if="!shouldKeepAlive(this.$route.path)" />
      </main>

      <footer
        v-if="
          $route.path != '/login' &&
            $route.path != '/signup' &&
            $route.path != '/loginbda' &&
            $route.path.split('/')[1] != 'printer'
        "
        :class="hideNav ? 'zeroPadding' : ''"
        class="page-footer"
        style="padding-top: 0; background-color: #22000000"
      >
        <div class="footer-copyright">
          <div class="container grey-text">
            © 2020 Mobishaala Edutech Pvt Ltd
            <a
              class="grey-text right"
              target="_blank"
              href="https://mobishaala.com/files/toe.pdf"
              >Terms and Conditions</a
            >
            <a
              class="grey-text right"
              target="_blank"
              href="https://mobishaala.com/files/pp.pdf"
              style="margin-right: 8px"
              >Privacy Policy</a
            >
          </div>
        </div>
      </footer>

      <a
        @click="hideNav = !hideNav"
        v-if="hideNav"
        class="btn-floating waves-effect waves-light white print-hide-1"
        style="position: fixed; left: -15px; top: 40px; z-index: 998"
      >
        <i class="material-icons blue-text">arrow_forward</i>
      </a>
    </div>
  </v-app>
</template>

<style>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.headerDrawer {
  background: url("assets/img/what-the-hex.png");
}
header,
main,
footer,
.brand-logo {
  padding-left: 300px !important;
}
.zeroPadding {
  padding: 0 !important;
}
@media only screen and (max-width: 992px) {
  header,
  main,
  footer,
  .brand-logo {
    padding-left: 0 !important;
  }
}
html {
  background: #dfe6e9;
}
@media print {
  .print-hide-1 {
    display: none;
    visibility: hidden;
  }
  .sidenav,
  .navbar-fixed {
    visibility: hidden;
  }
  header,
  main,
  footer,
  .brand-logo {
    padding-left: 0 !important;
  }
  main {
    margin: 1cm;
    margin-top: calc(1cm - 56px);
  }
  a[href]:after {
    content: none !important;
  }
  @page {
    margin: 0;
  }
}
:focus {
  outline: none;
}

.clickable {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.navigation-back-button {
  cursor: pointer;
  width: 44px !important;
  margin-top: 10px !important;
  margin-left: 16px !important;
  height: 44px !important;
  text-align: center;
  border-radius: 50%;
  background: #00000022;
  font-size: 30px !important;
  line-height: 44px !important;
}
@media only screen and (max-width: 600px) {
  .navigation-back-button {
    margin-top: 5px !important;
  }
}
.master-option {
  background: rgba(0, 255, 255, 0.212);
}
.beta-tag::after {
  content: "beta";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #ee8e4a;
  top: 7px;
  left: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.test-tag::after {
  content: "test";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #e74c3c;
  top: 7px;
  left: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* editor/ */
.editor p.is-empty:first-child::before {
  content: "Type here";
  color: #aaa;
  height: 0;
  pointer-events: none;
  font-style: italic;
}

.sidenav-icon {
  float: right !important;
  margin: 0 !important;
}
.v-application a {
  color: white !important;
}

.v-application .sidenav-close {
  color: black !important;
}
.v-application .blue-text {
  color: #2196f3 !important;
}
.v-application .green-text {
  color: #008080 !important;
}
.v-application .red-text {
  color: red !important;
}
.v-application .gray1-text {
  color: rgba(0, 0, 0, 0.507) !important;
}

.v-application .black-text {
  color: black !important;
}
.v-application .primary-text {
  color: #e74c3c !important;
}
.v-application .white-text {
  color: #fff !important;
}
.v-application .black-text {
  color: #000;
}
</style>

<script>
import STORAGE from "@/constants/storage.js";
import URL from "@/constants/url.js";
import axios from "axios";

import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
// document.addEventListener("DOMContentLoaded", function() {
import settings from "@/managers/settings";
// });

export default {
  data() {
    return {
      hideNav: false,
      APP_VERSION: "",
      instituteName: localStorage.getItem(STORAGE.ORG_NAME),
      headerName:
        localStorage.getItem(STORAGE.FIRST_NAME) +
        " " +
        localStorage.getItem(STORAGE.LAST_NAME) +
        " (" +
        localStorage.getItem(STORAGE.USERNAME) +
        ")",
      headerEmail: localStorage.getItem(STORAGE.EMAIL),
      baseUrl: URL.LOGIN,
      accessRoutes: [],
      isSuperMode: false,
      settings: settings,
    };
  },
  created() {
    this.hideNav = localStorage.getItem("hideNavPreference") == "true" || false;
  },
  methods: {
    shouldKeepAlive(route) {
      if (route == "/addVideo/uploader") return true;
      if (route == "/index/quality-params/select") return true;

      return false;
    },
    activateSuperMode(value) {
      // eslint-disable-next-line
      console.log("super ", value);
      this.isSuperMode = value;
    },
    logout: function() {
      localStorage.clear();
      this.$router.push("/login");
      localStorage.setItem(STORAGE.HAS_LOGGED_IN_BEFORE, true);
    },
    goBackNavigation: function() {
      this.$router.go(-1);
    },
    initFirebase() {
      var firebaseConfig = {
        apiKey: "AIzaSyD2PBgDNTD9-QOOsr7slBqvNsiLwTDio78",
        authDomain: "mobishaala-84ff5.firebaseapp.com",
        databaseURL: "https://mobishaala-84ff5.firebaseio.com",
        projectId: "mobishaala-84ff5",
        storageBucket: "mobishaala-84ff5.appspot.com",
        messagingSenderId: "488057792591",
        appId: "1:488057792591:web:4a693c8094cf34a3e1623a",
        measurementId: "G-BK7QJ00EV5",
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
    },
  },
  mounted() {
    this.initFirebase();

    axios.defaults.params = {};
    axios.defaults.params["org_id"] =
      localStorage.getItem(STORAGE.ORG_ID) || "";

    // eslint-disable-next-line
    this.APP_VERSION = process.env.VERSION;
    // var elems1 = document.querySelectorAll('.collapsible');
    // var instances = M.Collapsible.init(elems1, {});
    // eslint-disable-next-line
    // console.log(this.$route.path);
    var elems = document.querySelectorAll(".sidenav");
    // eslint-disable-next-line
    var instances = M.Sidenav.init(elems, {});
    // // eslint-disable-next-line
    // var elems2 = document.querySelectorAll('.dropdown-trigger');
    // // eslint-disable-next-line
    // M.Dropdown.init(elems2, {});

    // Freshchat code belowwww. also change link in index.html
    // window.fcWidget.init({
    //   token: "e899d4e1-7043-486d-8b3b-007e29dc3a2c",
    //   host: "https://wchat.freshchat.com",
    //   siteId: "MobishaalaAdminDashboard",
    //   externalId: this.headerEmail
    // });
    // window.fcWidget.user.setFirstName(localStorage.getItem(STORAGE.FIRST_NAME));
    // window.fcWidget.user.setEmail(this.headerEmail);
    // window.fcWidget.user.setPhone(localStorage.getItem(STORAGE.USERNAME));

    this.accessRoutes = (localStorage.getItem(STORAGE.ROUTE_LIST) || "").split(
      ","
    );
  },
  updated() {
    (this.headerName =
      localStorage.getItem(STORAGE.FIRST_NAME) +
      " " +
      localStorage.getItem(STORAGE.LAST_NAME) +
      " (" +
      localStorage.getItem(STORAGE.USERNAME) +
      ")"),
      (this.headerEmail = localStorage.getItem(STORAGE.EMAIL));
    this.instituteName = localStorage.getItem(STORAGE.ORG_NAME);

    var elems = document.querySelectorAll(".sidenav");
    // eslint-disable-next-line
    var instances = M.Sidenav.init(elems, {});

    var tempRoutes = (localStorage.getItem(STORAGE.ROUTE_LIST) || "").split(
      ","
    );
    if (JSON.stringify(this.accessRoutes) != JSON.stringify(tempRoutes)) {
      this.accessRoutes = (
        localStorage.getItem(STORAGE.ROUTE_LIST) || ""
      ).split(",");
    }
  },
  watch: {
    hideNav(newVal) {
      localStorage.setItem("hideNavPreference", newVal);
    },
  },
};
</script>

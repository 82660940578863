<template>
    <a v-on:click="onClickEvent($event)" class="right waves-effect waves-light blue btn-large" :class="isBusy||isDisabled?'disabled':''">
        <div class="valign-wrapper" v-if="isBusy">
            <div class="preloader-wrapper active my-preloader">
                <div class="spinner-layer spinner-blue-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div><div class="gap-patch">
                    <div class="circle"></div>
                </div><div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
                </div>
            </div>Loading {{countDown}}
        </div>
        <slot v-else></slot>
        </a>    
</template>
<style scoped>
.my-preloader{
    height: 25px;
    width: 25px;
    margin-right: 16px;
}
</style>

<script>
export default {
    props:["isBusy","isDisabled", "countDown"],
    methods:{
        onClickEvent:function(event){
            this.$emit("on-click",event)
        }
    }
}
</script>


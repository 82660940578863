import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
// import PATHS from "@/constants/paths.js"
import STORAGE from "@/constants/storage.js";

import "./assets/css/nprogress.css";

// const AddTestBulk = ()  // import ( /* webpackChunkName: "AddTestBulk" */ '@/components/Tests/AddTestBulk.vue')
// const AttemptQuestionList = () => //     import ( /* webpackChunkName: "AttemptQuestionList" */ '@/components/SubjectiveTest/AttemptQuestionList.vue')

import App from "./views/App.vue";

const routePaths = [
  {
    path: "/",
    redirect: "/app",
  },
  {
    path: "/app",
    name: "App",
    component: App,
  },
  {
    path: "/printer",
    name: "Printer",
    component: () =>
      import(/* webpackChunkName: "Printer" */ "./views/Printer.vue"),
    children: [
      {
        path: "spt/:testId",
        name: "SPT",
        component: () =>
          import(
            /* webpackChunkName: "Printer_SPT1" */ "./components/Printer/SPT.vue"
          ),
      },
      {
        path: "spt2/:testId",
        name: "SPT2",
        component: () =>
          import(
            /* webpackChunkName: "Printer_SPT2" */ "./components/Printer/SPTObjective.vue"
          ),
      },
      {
        path: "generateQrCode/:planId",
        name: "Publication Plan",
        component: () =>
          import(
            /* webpackChunkName: "Printer_SPT2" */ "./components/Printer/GenerateQrCode.vue"
          ),
      },
      {
        path: "spt3/:testId",
        name: "SPT3",
        component: () =>
          import(
            /* webpackChunkName: "Printer_SPT3" */ "./components/Printer/SPTObjectivePublication.vue"
          ),
      },
      {
        path: "worksheet/:testId",
        name: "Worksheet",
        component: () =>
          import(
            /* webpackChunkName: "Printer_WorkSheet4" */ "./components/Printer/Worksheet.vue"
          ),
      },
      {
        path: "worksheet2/:testId",
        name: "Worksheet2",
        component: () =>
          import(
            /* webpackChunkName: "Printer_WorkSheet5" */ "./components/Printer/WorksheetObjective.vue"
          ),
      },
      {
        path: "ttp",
        name: "TTP",
        component: () =>
          import(
            /* webpackChunkName: "Printer_TTP" */ "./components/Printer/TTP.vue"
          ),
      },
    ],
  },
  {
    path: "/template-editor",
    name: "Template Editor",
    component: () =>
      import(
        /* webpackChunkName: "TemplateEditor" */ "./components/TemplateGenerator/Editor.vue"
      ),
  },
  {
    path: "/app/:subPage",
    name: "App ",
    component: App,
  },

  {
    path: "/institute-app",
    name: "Institute App",
    component: () =>
      import(/* webpackChunkName: "InstituteApp" */ "./views/InstituteApp.vue"),
  },
  {
    path: "/institute-app/new-distribution",
    name: "New Distribution",
    component: () =>
      import(
        /* webpackChunkName: "NewDistribution" */ "@/components/InstituteApp/NewDistribution.vue"
      ),
  },
  {
    path: "/institute-app/uploadBanner",
    name: "Upload App Bannner",
    component: () =>
      import(
        /* webpackChunkName: "UploadInstituteBanner" */ "@/components/InstituteApp/UploadBanner.vue"
      ),
  },
  {
    path: "/institute-app/setup-demo",
    name: "Institute App Demo Setup",
    component: () =>
      import(
        /* webpackChunkName: "InstituteAppSetup" */ "@/components/InstituteApp/SetFreeDomain.vue"
      ),
  },
  {
    path: "/institute-app/config",
    name: "Institute App Config",
    component: () =>
      import(
        /* webpackChunkName: "InstituteAppConfig" */ "@/components/InstituteApp/WebsiteConfig.vue"
      ),
  },
  {
    path: "/institute-app/banners",
    name: "Institute App Banners",
    component: () =>
      import(
        /* webpackChunkName: "InsituteAppBanner" */ "@/components/InstituteApp/Banners.vue"
      ),
  },
  {
    path: "/institute-app/banners/new",
    name: "New Institute App Banner",
    component: () =>
      import(
        /* webpackChunkName: "NewInstituteAppBanner" */ "@/components/InstituteApp/UploadBanner.vue"
      ),
  },
  {
    path: "/institute-app/tiles/new",
    name: "New Tile",
    component: () =>
      import(
        /* webpackChunkName: "NewTile" */ "@/components/InstituteApp/NewTile.vue"
      ),
  },
  {
    path: "/institute-app/tiles",
    name: "Page Contents",
    component: () =>
      import(
        /* webpackChunkName: "InstituteAppContents" */ "@/components/InstituteApp/InstituteAppContents.vue"
      ),
  },
  {
    path: "/institute-app/tiles/:tileId",
    name: "View Tile",
    component: () =>
      import(
        /* webpackChunkName: "ViewTile" */ "@/components/InstituteApp/ViewTile.vue"
      ),
  },
  {
    path: "/institute-app/tiles/:tileId/new",
    name: "New Tile Content",
    component: () =>
      import(
        /* webpackChunkName: "NewTileContent" */ "@/components/InstituteApp/NewTileContent.vue"
      ),
  },
  {
    path: "/institute-app/tiles/:tileId/edit",
    name: "Edit Tile",
    component: () =>
      import(
        /* webpackChunkName: "EditTile" */ "@/components/InstituteApp/EditTile.vue"
      ),
  },
  {
    path: "/institute-app/tiles/:tileId/:contentId",
    name: "View Tile Content",
    component: () =>
      import(
        /* webpackChunkName: "ViewTileContent" */ "@/components/InstituteApp/ViewTileContent.vue"
      ),
  },

  {
    path: "/addVideo/uploader",
    name: "Upload Private Videos",
    component: () =>
      import(
        /* webpackChunkName: "Uploader" */ "@/components/AddVideo/Uploader.vue"
      ),
    keepAlive: true,
  },
  {
    path: "/addVideo/history",
    name: "Upload History",
    component: () =>
      import(
        /* webpackChunkName: "UploadHistory" */ "@/components/AddVideo/UploadHistory.vue"
      ),
  },
  {
    path: "/addVideo/edit/:videoId",
    name: "Edit Video",
    component: () =>
      import(
        /* webpackChunkName: "RawVideoEditor" */ "@/components/AddVideo/RawVideoEditor.vue"
      ),
  },
  {
    path: "/review",
    name: "Review",
    component: () =>
      import(/* webpackChunkName: "review" */ "./views/Review.vue"),
  },
  {
    path: "/review/trash",
    name: "Trash Videos",
    component: () =>
      import(/* webpackChunkName: "TrashVideos" */ "./views/Trash.vue"),
  },
  {
    path: "/audio",
    name: "Audio Library",
    component: () =>
      import(/* webpackChunkName: "AudioLibrary" */ "./views/AudioLibrary.vue"),
  },
  {
    path: "/audio/new",
    name: "New Audio",
    component: () =>
      import(
        /* webpackChunkName: "AddAudio" */ "@/components/Audio/AddAudio.vue"
      ),
  },
  {
    path: "/meet",
    name: "Live Class",
    component: () =>
      import(/* webpackChunkName: "LiveMeet" */ "./views/LiveMeet.vue"),
  },
  {
    path: "/question-bank",
    name: "Question Bank",
    component: () =>
      import(/* webpackChunkName: "AddSubject" */ "./views/TestGenerator.vue"),
  },
  {
    path: "/meet/new",
    name: "New Live Class",
    component: () =>
      import(
        /* webpackChunkName: "NewLiveMeet" */ "@/components/LiveMeet/AddLiveMeet.vue"
      ),
  },
  {
    path: "/live",
    name: "Live Broadcast",
    component: () =>
      import(/* webpackChunkName: "LiveVideo" */ "./views/LiveVideo.vue"),
  },
  {
    path: "/live/new",
    name: "Add Live Video",
    component: () =>
      import(
        /* webpackChunkName: "AddLiveVideo" */ "@/components/LiveVideo/AddLiveVideo.vue"
      ),
  },
  {
    path: "/live/new-stream",
    name: "Add Live Broadcast",
    component: () =>
      import(
        /* webpackChunkName: "AddLiveStream" */ "@/components/LiveVideo/CreateLiveStream.vue"
      ),
  },
  {
    path: "/live/:liveId/edit",
    name: "Edit Live Video",
    component: () =>
      import(
        /* webpackChunkName: "EditLiveVideo" */ "@/components/LiveVideo/EditLiveVideo.vue"
      ),
  },
  {
    path: "/live/:liveId",
    name: "View Live Video",
    component: () =>
      import(
        /* webpackChunkName: "ViewLiveVideo" */ "@/components/LiveVideo/ViewLiveVideo.vue"
      ),
  },
  {
    path: "/live/stream/:eventId",
    name: "View Live Broadcast",
    component: () =>
      import(
        /* webpackChunkName: "ViewLiveStream" */ "@/components/LiveVideo/ViewLiveStream.vue"
      ),
  },
  {
    path: "/live/stream/:eventId/edit",
    name: "Edit Live Broadcast",
    component: () =>
      import(
        /* webpackChunkName: "EditLiveStream" */ "@/components/LiveVideo/EditLiveStream.vue"
      ),
  },
  {
    path: "/index/quality-params/select",
    name: "Quality Params Select Index",
    component: () =>
      import(
        /* webpackChunkName: "QualityParamsSelectIndex" */ "@/components/QualityParams/SelectIndex.vue"
      ),
  },
  {
    path: "/index/quality-params/:tagName",
    name: "Quality Params Notes",
    component: () =>
      import(
        /* webpackChunkName: "QualityParamsNotes" */ "@/components/QualityParams/ListNotes.vue"
      ),
  },
  {
    path: "/index/quality-params/:tagName/add",
    name: "Quality Params Add Note",
    component: () =>
      import(
        /* webpackChunkName: "QualityParamsNotes" */ "@/components/QualityParams/AddNote.vue"
      ),
  },
  {
    path: "/index/quality-params/:tagName/edit/:noteId",
    name: "Quality Params Edit Note",
    component: () =>
      import(
        /* webpackChunkName: "QualityParamsNotes" */ "@/components/QualityParams/EditNote.vue"
      ),
  },
  {
    path: "/index",
    name: "Index Content",
    component: () =>
      import(/* webpackChunkName: "IndexContent" */ "@/views/Index.vue"),
  },
  {
    path: "/contents",
    name: "Subjects",
    component: () =>
      import(
        /* webpackChunkName: "SubjectList" */ "@/components/Contents/SubjectList.vue"
      ),
  },
  {
    path: "/contents/trash",
    name: "Subjects Trash",
    component: () =>
      import(
        /* webpackChunkName: "SubjectTrash" */ "@/components/Contents/SubjectTrash.vue"
      ),
  },
  {
    path: "/contents/categories",
    name: "Video Categories",
    component: () =>
      import(
        /* webpackChunkName: "CategoryList" */ "@/components/Contents/CategoryList.vue"
      ),
  },
  {
    path: "/contents/categories/new",
    name: "New Video Category",
    component: () =>
      import(
        /* webpackChunkName: "AddCategory" */ "@/components/Contents/AddCategory.vue"
      ),
  },
  {
    path: "/contents/categories/:categoryId/edit",
    name: "Edit Video Category",
    component: () =>
      import(
        /* webpackChunkName: "EditCategory" */ "@/components/Contents/EditCategory.vue"
      ),
  },
  {
    path: "/contents/addSubject",
    name: "New Subject",
    component: () =>
      import(
        /* webpackChunkName: "AddSubject" */ "@/components/Contents/AddSubject.vue"
      ),
  },

  {
    path: "/contents/editSubject/:subjectId",
    name: "Edit Subject",
    component: () =>
      import(
        /* webpackChunkName: "EditSubject" */ "@/components/Contents/EditSubject.vue"
      ),
  },
  {
    path: "/contents/:subjectId/",
    name: "Chapters",
    component: () =>
      import(
        /* webpackChunkName: "ChapterList" */ "@/components/Contents/ChapterList.vue"
      ),
  },
  {
    path: "/contents/:subjectId/addChapter",
    name: "New Chapter",
    component: () =>
      import(
        /* webpackChunkName: "AddChapter" */ "@/components/Contents/AddChapter.vue"
      ),
  },
  {
    path: "/contents/:subjectId/editChapter/:chapterId",
    name: "Edit Chapter",
    component: () =>
      import(
        /* webpackChunkName: "EditChapter" */ "@/components/Contents/EditChapter.vue"
      ),
  },
  {
    path: "/contents/:subjectId/:chapterId/",
    name: "Topics",
    component: () =>
      import(
        /* webpackChunkName: "TopicList" */ "@/components/Contents/TopicList.vue"
      ),
  },
  {
    path: "/contents/:subjectId/:chapterId/:topicId/:videoId/timestamps",
    name: "Video Timestamps",
    component: () =>
      import(
        /* webpackChunkName: "VideoTimestamps" */ "@/components/Contents/Timestamps.vue"
      ),
  },
  {
    path: "/contents/:subjectId/:chapterId/:topicId/:videoId/video-select",
    name: "Video Select",
    component: () =>
      import(
        /* webpackChunkName: "VideoSelect" */ "@/components/Contents/VideoSelect.vue"
      ),
  },
  {
    path: "/addVideo",
    name: "Add Videos",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "./views/AddVideo.vue"),
  },
  {
    path: "/addVideoold",
    name: "Add Videos old",
    component: () =>
      import(/* webpackChunkName: "AddVideoOld" */ "./views/AddVideoOld.vue"),
  },
    {
    path: "/discover",
    name: "Discover",
    component: () =>
    import(/* webpackChunkName: "AddVideo" */ "./components/Discover/AddDiscoverVideo.vue"),
  },
    {
    path: "/tools/DMT",
    name: "Discover Management tool (DMT)",
    component: () =>
    import(/* webpackChunkName: "AddVideo" */ "./components/Discover/DiscoverAll/DiscoverAll.vue"),
  },
  {
    path: "/discover/addvideo",
    name: "Add Video to Discover",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "./components/Discover/SelectVideo/SelectVideo.vue"),
  },
  {
    path: "/discover/newk",
    name: "New Discover Video",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "./components/Discover/DiscoverNew/DiscoverNew.vue"),
  },
  {
    path: "/discover/:id/edit",
    name: "Edit Discover Video",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "./components/Discover/EditVideo/EditVideo.vue"),
  },
  {
    path: "/discover/trash",
    name: "Discover Trash",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "@/components/Discover/DiscoverTrash/DiscoverTrash.vue"),
  },
  {
    path: "/discover/analytics/:reelId",
    name: "Discover Analytics",
    component: () =>
      import(/* webpackChunkName: "AddVideo" */ "@/components/Discover/Analytics/UserHistoryAnalytics.vue"),
  },
  {
    path: "/doubts",
    name: "Doubts",
    component: () =>
      import(/* webpackChunkName: "Doubts" */ "./views/Doubts.vue"),
    children: [
      {
        path: "all",
        name: "All Doubts",
        component: () =>
          import(
            /* webpackChunkName: "Doubts" */ "@/components/Doubts/AllDoubts.vue"
          ),
      },
      {
        path: "popular",
        name: "Popular Doubts",
        component: () =>
          import(
            /* webpackChunkName: "Doubts" */ "@/components/Doubts/PopularDoubts.vue"
          ),
      },
      {
        path: "",
        redirect: "all",
      },
    ],
  },

  {
    path: "/doubts/:doubtId",
    name: "View Doubt",
    component: () =>
      import(
        /* webpackChunkName: "Doubts" */ "@/components/Doubts/ViewDoubt.vue"
      ),
  },
  {
    path: "/doubts/:doubtId/new-resolution",
    name: "New Doubt Resolution",
    component: () =>
      import(
        /* webpackChunkName: "Doubts" */ "@/components/Doubts/NewResolution.vue"
      ),
  },
  {
    path: "/students",
    name: "Students",
    component: () =>
      import(/* webpackChunkName: "Students" */ "./views/Students.vue"),
  },
  {
    path: "/students/enrolment",
    name: "Student Enrollments",
    component: () =>
      import(
        /* webpackChunkName: "Students_Enrolments" */ "@/components/Students/Enrolments.vue"
      ),
  },
  {
    path: "/students/:studentAppId/:studentId/enrollment-request",
    name: "Enrollment Request",
    component: () =>
      import(
        /* webpackChunkName: "Students_Enrolment_Request" */ "@/components/Students/EnrollmentRequest.vue"
      ),
  },
  {
    path: "/students/:studentAppId/:studentId/edit",
    name: "Edit Student",
    component: () =>
      import(
        /* webpackChunkName: "EditStudent" */ "@/components/Students/EditStudent.vue"
      ),
  },
  {
    path: "/students/:studentAppId/:studentId/",
    name: "Student Details",
    component: () =>
      import(
        /* webpackChunkName: "StudentDetails" */ "@/components/Students/StudentDetails.vue"
      ),
  },
  {
    path: "/students/:studentAppId/:studentId/addPlan",
    name: "Add Subscription",
    component: () =>
      import(
        /* webpackChunkName: "StudentAddPlan" */ "@/components/Students/StudentAddPlan.vue"
      ),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "Notifications" */ "./views/Notifications.vue"
      ),
  },
  {
    path: "/notifications/groups",
    name: "User Groups",
    component: () =>
      import(
        /* webpackChunkName: "NotificationUserGroups" */ "@/components/Notifications/NotificationUserGroups.vue"
      ),
  },
  {
    path: "/notifications/groups/:groupId",
    name: "View Notification Group",
    component: () =>
      import(
        /* webpackChunkName: "ViewNotificationUserGroup" */ "@/components/Notifications/ViewNotificationUserGroup.vue"
      ),
  },
  {
    path: "/notifications/groups/:groupId/add",
    name: "Add Student | Notification Group",
    component: () =>
      import(
        /* webpackChunkName: "NotificationGroupAddStudents" */ "@/components/Notifications/NotificationGroupAddStudents.vue"
      ),
  },
  {
    path: "/notifications/new",
    name: "New Notification",
    component: () =>
      import(
        /* webpackChunkName: "NewNotification" */ "@/components/Notifications/NewNotification.vue"
      ),
  },
  {
    path: "/notifications/newg",
    name: "New Group Notification",
    component: () =>
      import(
        /* webpackChunkName: "NewGroupNotification" */ "@/components/Notifications/NewGroupNotification.vue"
      ),
  },
  {
    path: "/notifications/newp",
    name: "New Plan Notification",
    component: () =>
      import(
        /* webpackChunkName: "NewPlanNotification" */ "@/components/Notifications/NewPlanNotification.vue"
      ),
  },
  {
    path: "/tests",
    name: "Tests",
    component: () =>
      import(/* webpackChunkName: "tests" */ "./views/Tests.vue"),
  },
  {
    path: "/tests/trash",
    name: "Tests Trash",
    component: () =>
      import(
        /* webpackChunkName: "TestsTrash" */ "@/components/Tests/TestsTrash.vue"
      ),
  },
  {
    path: "/tests/tags",
    name: "Tags",
    component: () =>
      import(
        /* webpackChunkName: "TagList" */ "@/components/Tests/TagList.vue"
      ),
  },
  {
    path: "/tests/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "CategoryList" */ "@/components/Tests/CategoryList.vue"
      ),
  },
  {
    path: "/tests/categories/new",
    name: "New Category",
    component: () =>
      import(
        /* webpackChunkName: "AddCategory" */ "@/components/Tests/AddCategory.vue"
      ),
  },
  {
    path: "/tests/categories/:categoryId/edit",
    name: "Edit Category",
    component: () =>
      import(
        /* webpackChunkName: "EditCategory" */ "@/components/Tests/EditCategory.vue"
      ),
  },
  {
    path: "/tests/tags/new",
    name: "Add Tag",
    component: () =>
      import(/* webpackChunkName: "AddTag" */ "@/components/Tests/AddTag.vue"),
  },
  {
    path: "/tests/new",
    name: "New Test",
    component: () =>
      import(
        /* webpackChunkName: "addtest" */ "@/components/Tests/AddTest.vue"
      ),
  },
  // {
  //     path: '/tests/new-bulk',
  //     name: 'New Bulk Test',
  //     component: AddTestBulk
  // },
  {
    path: "/tests/upload",
    name: "Upload Test",
    component: () =>
      import(
        /* webpackChunkName: "UploadTest" */ "@/components/Tests/UploadTest.vue"
      ),
  },
  {
    path: "/tests/:testId",
    name: "Questions",
    component: () =>
      import(
        /* webpackChunkName: "QuestionList" */ "@/components/Tests/QuestionList.vue"
      ),
  },
  {
    path: "/tests/:testId/edit",
    name: "Edit Test",
    component: () =>
      import(
        /* webpackChunkName: "EditTest" */ "@/components/Tests/EditTest.vue"
      ),
  },
  {
    path: "/tests/:testId/bulk-edit",
    name: "Bulk Edit Test",
    component: () =>
      import(
        /* webpackChunkName: "BulkUpdate" */ "@/components/Tests/BulkUpdate.vue"
      ),
  },
  {
    path: "/tests/:testId/results",
    name: "Test Results",
    component: () =>
      import(
        /* webpackChunkName: "TestResults" */ "@/components/Tests/TestResults.vue"
      ),
  },
  {
    path: "/tests/:testId/results/:studentId",
    name: "Student Test Results",
    component: () =>
      import(
        /* webpackChunkName: "StudentTestResults" */ "@/components/Tests/StudentTestResult.vue"
      ),
  },
  {
    path: "/tests/:testId/schedule",
    name: "Schedule Test",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleTest" */ "@/components/Tests/ScheduleTest.vue"
      ),
  },
  {
    path: "/tests/:testId/new",
    name: "New Question",
    component: () =>
      import(
        /* webpackChunkName: "AddQuestion" */ "@/components/Tests/AddQuestionNew.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId/edit",
    name: "Edit Question",
    component: () =>
      import(
        /* webpackChunkName: "EditQuestion" */ "@/components/Tests/EditQuestion.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId",
    name: "View Question",
    component: () =>
      import(
        /* webpackChunkName: "ViewQuestion" */ "@/components/Tests/ViewQuestion.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId/new",
    name: "Add Options",
    component: () =>
      import(
        /* webpackChunkName: "AddOption" */ "@/components/Tests/AddOption.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId/new-numerical",
    name: "Add Numerical Answer",
    component: () =>
      import(
        /* webpackChunkName: "AddNumericalAnswer" */ "@/components/Tests/AddNumericalAnswer.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId/:optionId/edit",
    name: "Edit Option",
    component: () =>
      import(
        /* webpackChunkName: "EditOption" */ "@/components/Tests/EditOption.vue"
      ),
  },
  {
    path: "/tests/:testId/:questionId/:answerId/edit-numerical",
    name: "Edit Numerical Answer",
    component: () =>
      import(
        /* webpackChunkName: "EditNumericalAnswer" */ "@/components/Tests/EditNumericalAnswer.vue"
      ),
  },
  {
    path: "/tag-manager",
    name: "Tag Manager",
    component: () =>
      import(/* webpackChunkName: "tag-manager" */ "./views/TagManager.vue"),
  },
  {
    path: "/ttp",
    name: "TTP Publications",
    component: () =>
      import(/* webpackChunkName: "Publications" */ "./views/TTP.vue"),
  },
  {
    path: "/ttp/new",
    name: "New TTP Publications",
    component: () =>
      import(/* webpackChunkName: "NewTTP" */ "@/components/TTP/AddTTP.vue"),
  },
  {
    path: "/ttp/:ttpId",
    name: "TTP Publication",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/Pages.vue"),
  },
  {
    path: "/ttp/:ttpId/edit",
    name: "Edit TTP",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/EditTTP.vue"),
  },
  {
    path: "/ttp/:ttpId/new",
    name: "New TTP Chapter",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/AddChapter.vue"),
  },
  {
    path: "/ttp/:ttpId/new-sri",
    name: "New TTP Chapter from SRI",
    component: () =>
      import(
        /* webpackChunkName: "TTP" */ "@/components/TTP/AddChapterFromSRI.vue"
      ),
  },
  {
    path: "/ttp/:ttpId/:chapterId/new",
    name: "New TTP Topic",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/AddTopic.vue"),
  },
  {
    path: "/ttp/:ttpId/:chapterId/edit",
    name: "TTP Edit Chapter",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/EditChapter.vue"),
  },
  {
    path: "/ttp/:ttpId/:chapterId/:topicId",
    name: "TTP Topic",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/Page.vue"),
  },
  {
    path: "/ttp/:ttpId/:chapterId/:topicId/edit",
    name: "TTP Edit Topic",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/EditTopic.vue"),
  },
  {
    path: "/ttp/:ttpId/:chapterId/:topicId/new",
    name: "TTP QP",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/AddQP.vue"),
  },
  {
    path: "/ttp/:ttpId/:chapterId/:topicId/:qpId/edit",
    name: "TTP Edit QP",
    component: () =>
      import(/* webpackChunkName: "TTP" */ "@/components/TTP/EditQP.vue"),
  },
  {
    path: "/publications-objective",
    name: "Objective Publications",
    component: () =>
      import(
        /* webpackChunkName: "Publications" */ "./views/PublicationsObjective.vue"
      ),
  },
  {
    path: "/publications-objective/new",
    name: "New Publications Objective",
    component: () =>
      import(
        /* webpackChunkName: "AddSubjectiveTestPublication" */ "@/components/PublicationsObjective/AddPublication.vue"
      ),
  },
  {
    path: "/publications-objective/section/:sectionId/edit",
    name: "Edit Objective Section",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/PublicationsObjective/EditSection.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/bulk-edit",
    name: "Bulk Edit Publication Objective Test",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/PublicationsObjective/BulkUpdate.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/edit",
    name: "Edit Publication Objective Test",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/PublicationsObjective/EditPublication.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/questions",
    name: "Publication Objective Questions",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/PublicationsObjective/Questions.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/questions/add",
    name: "Publications Questions Add Question",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestAddQuestion" */ "@/components/PublicationsObjective/AddQuestion.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/questions/:questionId/edit",
    name: "Publications Questions Edit Question",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestEditQuestion" */ "@/components/PublicationsObjective/EditQuestion.vue"
      ),
  },
  {
    path: "/publications-objective/:testId/questions/:questionId/option/add",
    name: "Publications Questions Add Option",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestEditQuestion" */ "@/components/PublicationsObjective/AddOption.vue"
      ),
  },
  {
    path:
      "/publications-objective/:testId/questions/:questionId/option/:optionId/edit",
    name: "Publications Questions Edit Option",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestEditQuestion" */ "@/components/PublicationsObjective/EditOption.vue"
      ),
  },
  {
    path: "/publications",
    name: "Subjective Publications",
    component: () =>
      import(/* webpackChunkName: "Publications" */ "./views/Publications.vue"),
  },
  {
    path: "/publications/new",
    name: "New Publications",
    component: () =>
      import(
        /* webpackChunkName: "AddSubjectiveTestPublication" */ "@/components/Publications/AddPublication.vue"
      ),
  },
  {
    path: "/publications/section/:sectionId/edit",
    name: "Edit Section",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/Publications/EditSection.vue"
      ),
  },
  {
    path: "/publications/:testId/questions",
    name: "Publication Questions",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/Publications/Questions.vue"
      ),
  },
  {
    path: "/publications/:testId/bulk-edit",
    name: "Bulk Edit Publication Questions",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestQuestions" */ "@/components/Publications/BulkUpdate.vue"
      ),
  },
  {
    path: "/publications/:testId/questions/add",
    name: "Publications Add Question",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestAddQuestion" */ "@/components/Publications/AddQuestion.vue"
      ),
  },
  {
    path: "/publications/:testId/questions/:questionId/edit",
    name: "Publications Edit Question",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestEditQuestion" */ "@/components/Publications/EditQuestion.vue"
      ),
  },
  {
    path: "/publications/:testId/edit",
    name: "Edit Publications",
    component: () =>
      import(
        /* webpackChunkName: "EditSubjectiveTest" */ "@/components/Publications/EditPublication.vue"
      ),
  },

  {
    path: "/subjectivetests",
    name: "Subjective Tests",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTest" */ "./views/SubjectiveTest.vue"
      ),
  },
  {
    path: "/subjectivetests/trash",
    name: "Subjective Test Trash",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestTrash" */ "@/components/SubjectiveTest/SubjectiveTestTrash.vue"
      ),
  },
  {
    path: "/subjectivetests/new",
    name: "New Subjective Tests",
    component: () =>
      import(
        /* webpackChunkName: "AddSubjectiveTest" */ "@/components/SubjectiveTest/AddSubjectiveTest.vue"
      ),
  },
  {
    path: "/subjectivetests/:testId/attempts",
    name: "Subjective Test Attempts",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestAttempts" */ "@/components/SubjectiveTest/Attempts.vue"
      ),
  },
  {
    path: "/subjectivetests/:testId/attempts/:attemptId",
    name: "Test Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "SubjectiveTestAttemptCorrect" */ "@/components/SubjectiveTest/CorrectAttempt.vue"
      ),
  },
  {
    path: "/subjectivetests/:testId/attempts/:attemptId/view",
    name: "View Corrected Solution",
    component: () =>
      import(
        /* webpackChunkName: "ViewCorrectedSolution" */ "@/components/SubjectiveTest/ViewCorrectedSolution.vue"
      ),
  },
  {
    path: "/subjectivetests/:testId/schedule",
    name: "Schedule Subjective Test",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleSubjectiveTest" */ "@/components/SubjectiveTest/ScheduleTest.vue"
      ),
  },
  {
    path: "/subjectivetests/:testId/edit",
    name: "Edit Subjective Test",
    component: () =>
      import(
        /* webpackChunkName: "EditSubjectiveTest" */ "@/components/SubjectiveTest/EditSubjectiveTest.vue"
      ),
  },

  {
    path: "/notes",
    name: "Notes",
    component: () =>
      import(/* webpackChunkName: "Notes" */ "./views/Notes.vue"),
  },
  {
    path: "/notes/trash",
    name: "Notes Trash",
    component: () =>
      import(
        /* webpackChunkName: "NotesTrash" */ "@/components/Notes/NotesTrash.vue"
      ),
  },
  {
    path: "/notes/new",
    name: "Add Note",
    component: () =>
      import(
        /* webpackChunkName: "AddNote" */ "@/components/Notes/AddNote.vue"
      ),
  },
  {
    path: "/notes/:noteId/edit",
    name: "Edit Note",
    component: () =>
      import(
        /* webpackChunkName: "EditNote" */ "@/components/Notes/EditNote.vue"
      ),
  },

  {
    path: "/ebooks",
    name: "Ebooks",
    component: () =>
      import(/* webpackChunkName: "Ebooks" */ "./views/Ebooks.vue"),
  },
  {
    path: "/ebooks/trash",
    name: "Ebooks Trash",
    component: () =>
      import(
        /* webpackChunkName: "EbooksTrash" */ "@/components/Ebooks/EbooksTrash.vue"
      ),
  },
  {
    path: "/ebooks/:ebookId/edit",
    name: "Edit Ebook",
    component: () =>
      import(
        /* webpackChunkName: "EditEbook" */ "@/components/Ebooks/EditEbook.vue"
      ),
  },
  {
    path: "/ebooks/new",
    name: "Add Ebook",
    component: () =>
      import(
        /* webpackChunkName: "AddEbook" */ "@/components/Ebooks/AddEbook.vue"
      ),
  },
  {
    path: "/ebooks/tags",
    name: "Ebook Tags",
    component: () =>
      import(
        /* webpackChunkName: "EbookTagList" */ "@/components/Ebooks/TagList.vue"
      ),
  },
  {
    path: "/ebooks/tags/new",
    name: "Add Ebook Tag",
    component: () =>
      import(
        /* webpackChunkName: "EbookAddTag" */ "@/components/Ebooks/AddTag.vue"
      ),
  },
  {
    path: "/ebooks/categories",
    name: "Ebook Categories",
    component: () =>
      import(
        /* webpackChunkName: "EbookCategoryList" */ "@/components/Ebooks/CategoryList.vue"
      ),
  },
  {
    path: "/ebooks/categories/:categoryId/edit",
    name: "Edit Ebook Category",
    component: () =>
      import(
        /* webpackChunkName: "EditEbookCategory" */ "@/components/Ebooks/EditCategory.vue"
      ),
  },
  {
    path: "/ebooks/categories/new",
    name: "Add Category",
    component: () =>
      import(
        /* webpackChunkName: "EbookAddCategory" */ "@/components/Ebooks/AddCategory.vue"
      ),
  },

  {
    path: "/plans",
    name: "Plans",
    component: () =>
      import(/* webpackChunkName: "Plans" */ "./views/Plans.vue"),
  },

  {
    path: "/publicationplans",
    name: "Publication Plans",
    component: () =>
      import(/* webpackChunkName: "Plans" */ "./views/PublicationPlans.vue"),
  },
  {
    path: "/plans/new",
    name: "Add Plan",
    component: () =>
      import(
        /* webpackChunkName: "AddPlan" */ "@/components/Plans/AddPlan.vue"
      ),
  },

  {
    path: "/plans/categories",
    name: "Plan Categories",
    component: () =>
      import(
        /* webpackChunkName: "PlanCategoryList" */ "@/components/Plans/CategoryList.vue"
      ),
  },
  {
    path: "/plans/categories/new",
    name: "New Plan Category",
    component: () =>
      import(
        /* webpackChunkName: "AddPlanCategory" */ "@/components/Plans/AddCategory.vue"
      ),
  },
  {
    path: "/plans/categories/:categoryId/edit",
    name: "Edit Plan Category",
    component: () =>
      import(
        /* webpackChunkName: "EditPlanCategory" */ "@/components/Plans/EditCategory.vue"
      ),
  },
  {
    path: "/plans/:planId/edit",
    name: "Edit Plan",
    component: () =>
      import(
        /* webpackChunkName: "EditPlan" */ "@/components/Plans/EditPlan.vue"
      ),
  },
  {
    path: "/plans/:planId",
    name: "View Plan",
    component: () =>
      import(
        /* webpackChunkName: "ViewPlan" */ "@/components/Plans/ViewPlan.vue"
      ),
  },
  {
    path: "/share",
    name: "Share",
    component: () =>
      import(/* webpackChunkName: "Share" */ "./views/Share.vue"),
  },

  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "Orders" */ "./views/Orders.vue"),
  },

  {
    path: "/payments",
    name: "Payment History",
    component: () =>
      import(
        /* webpackChunkName: "PaymentHistory" */ "./views/PaymentHistory.vue"
      ),
  },
  {
    path: "/payments/monthly/:fromDate/:toDate",
    name: "Monthly Statement",
    component: () =>
      import(
        /* webpackChunkName: "MonthlyStatement" */ "@/components/PaymentHistory/MonthlyStatement.vue"
      ),
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () =>
      import(/* webpackChunkName: "Campaigns" */ "./views/Campaigns.vue"),
  },
  {
    path: "/campaigns/new",
    name: "New Campaign",
    component: () =>
      import(
        /* webpackChunkName: "NewCampaign" */ "@/components/Campaigns/AddCampaign.vue"
      ),
  },
  {
    path: "/campaigns/:campaignId",
    name: "View Campaign",
    component: () =>
      import(
        /* webpackChunkName: "ViewCampaign" */ "@/components/Campaigns/ViewCampaign.vue"
      ),
  },
  {
    path: "/coupon",
    name: "Coupons",
    component: () =>
      import(/* webpackChunkName: "Coupons" */ "./views/Coupons.vue"),
  },
  {
    path: "/coupon/new",
    name: "New Coupon",
    component: () =>
      import(
        /* webpackChunkName: "AddCoupon" */ "@/components/Coupons/AddCoupon.vue"
      ),
  },
  {
    path: "/coupon/trash",
    name: "Trash Coupons",
    component: () =>
      import(
        /* webpackChunkName: "TrashCoupons" */ "@/components/Coupons/TrashCoupons.vue"
      ),
  },
  {
    path: "/coupon/:couponId/edit",
    name: "Edit Coupon",
    component: () =>
      import(
        /* webpackChunkName: "EditCoupon" */ "@/components/Coupons/EditCoupon.vue"
      ),
  },
  {
    path: "/coupon/:couponId/addPlan",
    name: "Add Plan to Coupon",
    component: () =>
      import(
        /* webpackChunkName: "AddPlanToCoupon" */ "@/components/Coupons/AddPlan.vue"
      ),
    props: true,
  },
  {
    path: "/coupon/:couponId/addStudent",
    name: "Add Students to Coupon",
    component: () =>
      import(
        /* webpackChunkName: "AddStudentsToCoupon" */ "@/components/Coupons/AddStudents.vue"
      ),
  },
  {
    path: "/coupon/:couponId",
    name: "View Coupon",
    component: () =>
      import(
        /* webpackChunkName: "ViewCoupon" */ "@/components/Coupons/ViewCoupon.vue"
      ),
  },
  {
    path: "/fb",
    name: "Facebook Marketing",
    component: () =>
      import(/* webpackChunkName: "Facebook" */ "./views/Facebook.vue"),
  },
  {
    path: "/editor",
    name: "Youtube Editor",
    component: () =>
      import(/* webpackChunkName: "editor" */ "./views/Editor.vue"),
  },
  {
    path: "/editor/auth/result",
    name: "Authentication Result",
    component: () =>
      import(
        /* webpackChunkName: "AuthenticationResult" */ "@/components/Editor/AuthenticationResult.vue"
      ),
  },
  {
    path: "/editor/:taskId",
    name: "Youtube Video",
    component: () =>
      import(
        /* webpackChunkName: "youtubeVideo" */ "@/components/Editor/ViewRenderTask.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/About.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "Settings" */ "./views/Settings.vue"),
  },
  {
    path: "/settings/logs-video",
    name: "Video Logs",
    component: () =>
      import(
        /* webpackChunkName: "VideoLogs" */ "@/components/Settings/VideoLogs.vue"
      ),
  },
  {
    path: "/tools",
    name: "Tools",
    component: () =>
      import(/* webpackChunkName: "Tools" */ "./views/Tools.vue"),
  },
  {
    path: "/tools/certificate",
    name: "Certificate",
    component: () =>
      import(
        /* webpackChunkName: "Tools_Keywords" */ "./views/Certificate.vue"
      ),
  },
  {
    path: "/tools/keywords",
    name: "Keywords",
    component: () =>
      import(
        /* webpackChunkName: "Tools_Keywords" */ "./views/KeywordGenerator.vue"
      ),
  },
  {
    path: "/tools/document-scan",
    name: "Document Scan",
    component: () =>
      import(/* webpackChunkName: "DocuScan" */ "./views/DocuScan.vue"),
  },
  {
    path: "/tools/workbook",
    name: "Wookbook Generator",
    component: () =>
      import(
        /* webpackChunkName: "Tools_Workbook" */ "./views/WorkbookGenerator.vue"
      ),
  },
  {
    path: "/tools/pdf-watermark",
    name: "PDF Watermark",
    component: () =>
      import(
        /* webpackChunkName: "Tools_PDF_Watermark" */ "./views/PDFWatermark.vue"
      ),
  },
  {
    path: "/tools/shorty",
    name: "URL Shortener",
    component: () =>
      import(
        /* webpackChunkName: "Tools_URLShortener" */ "./views/URLShortener.vue"
      ),
  },
  {
    path: "/tools/pdf-decorator",
    name: "PDF Decorator",
    component: () =>
      import(
        /* webpackChunkName: "Tools_Decorator" */ "./views/PDFDecorator.vue"
      ),
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "LoginGoogle" */ "./views/LoginNew.vue"),
  },
  {
    path: "/signup",
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "./views/SignUp.vue"),
  },
  {
    path: "/loginbda",
    component: () =>
      import(
        /* webpackChunkName: "BDALogin" */ "@/components/Login/BDALogin.vue"
      ),
  },
  {
    path: "/survey",
    name: "Survey",
    component: () =>
      import(/* webpackChunkName: "Survey" */ "./views/Survey.vue"),
  },
  {
    path: "/survey/new",
    name: "New Survey",
    component: () =>
      import(
        /* webpackChunkName: "NewSurvey" */ "@/components/Survey/AddSurvey.vue"
      ),
  },
  {
    path: "/survey/trash",
    name: "Trash Surveys",
    component: () =>
      import(
        /* webpackChunkName: "TrashSurveys" */ "@/components/Survey/TrashSurveys.vue"
      ),
  },
  {
    path: "/survey/:surveyId/edit",
    name: "Edit Surveys",
    component: () =>
      import(
        /* webpackChunkName: "EditSurvey" */ "@/components/Survey/EditSurvey.vue"
      ),
  },
  {
    path: "/survey/:surveyId/students",
    name: "View Survey Students",
    component: () =>
      import(
        /* webpackChunkName: "ViewSurveyStudents" */ "@/components/Survey/SurveyStudents.vue"
      ),
  },
  {
    path: "/survey/:surveyId/plans",
    name: "View Survey Plans",
    component: () =>
      import(
        /* webpackChunkName: "ViewSurveyPlans" */ "@/components/Survey/SurveyPlans.vue"
      ),
  },
  {
    path: "/survey/:surveyId/plans/add",
    name: "Add Plan to Survey",
    component: () =>
      import(
        /* webpackChunkName: "AddPlanToSurvey" */ "@/components/Survey/AddPlanSurvey.vue"
      ),
    props: true,
  },
  {
    path: "/survey/:surveyId",
    name: "View Survey",
    component: () =>
      import(
        /* webpackChunkName: "ViewSurvey" */ "@/components/Survey/ViewSurvey.vue"
      ),
  },
  {
    path: "/inapp-marketing",
    name: "InApp Marketing",
    component: () =>
      import(
        /* webpackChunkName: "InAppMarketing" */ "./views/InAppMarketing.vue"
      ),
  },
  {
    path: "/inapp-marketing/new",
    name: "New InApp Marketing",
    component: () =>
      import(
        /* webpackChunkName: "NewInAppMarketing" */ "./components/InAppMarketing/NewInAppMarketing.vue"
      ),
  },
  {
    path: "/inapp-marketing/:campaignId",
    name: "View InApp Marketing",
    component: () =>
      import(
        /* webpackChunkName: "ViewInAppMarketing" */ "./components/InAppMarketing/ViewInAppMarketing.vue"
      ),
  },
  {
    path: "/inapp-marketing/:campaignId/edit",
    name: "Edit InApp Marketing",
    component: () =>
      import(
        /* webpackChunkName: "EditInAppMarketing" */ "./components/InAppMarketing/EditInAppMarketing.vue"
      ),
  },
  {
    path: "/inapp-marketing/:campaignId/addStudent",
    name: "Add Student | InApp Marketing",
    component: () =>
      import(
        /* webpackChunkName: "AddStudentInAppMarketing" */ "./components/InAppMarketing/AddStudents.vue"
      ),
  },
  {
    path: "/digital-design",
    name: "Digital Design",
    component: () =>
      import(
        /* webpackChunkName: "DigitalDesign" */ "./views/DigitalDesign.vue"
      ),
  },
  {
    path: "/digital-design/new",
    name: "New Digital Design",
    component: () =>
      import(
        /* webpackChunkName: "AddDigitalDesign" */ "./components/DigitalDesign/AddDigitalDesign.vue"
      ),
  },
  {
    path: "/digital-design/:designId",
    name: "Edit Digital Design",
    component: () =>
      import(
        /* webpackChunkName: "EditDigitalDesign" */ "./components/DigitalDesign/EditDigitalDesign.vue"
      ),
  },
  {
    path: "/digital-design/:designId/new",
    name: "Add Digital Design Component",
    component: () =>
      import(
        /* webpackChunkName: "AddDigitalDesignComponent" */ "./components/DigitalDesign/AddDigitalDesignComponent.vue"
      ),
  },
  {
    path: "/print",
    name: "Print",
    component: () =>
      import(/* webpackChunkName: "PrintMedia" */ "./views/PrintMedia.vue"),
  },

  {
    path: "/admins",
    name: "Admins",
    component: () =>
      import(/* webpackChunkName: "Admins" */ "./views/Admins.vue"),
  },
  {
    path: "/admins/new",
    name: "New Admin",
    component: () =>
      import(
        /* webpackChunkName: "NewAdmin" */ "@/components/Admins/NewAdmin.vue"
      ),
  },
  {
    path: "/admins/:adminId",
    name: "Update Admin",
    component: () =>
      import(
        /* webpackChunkName: "NewAdmin" */ "@/components/Admins/UpdateAdmin.vue"
      ),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackChunkName: "Help" */ "./views/Help.vue"),
  },
  {
    path: "/loginold",
    component: () =>
      import(/* webpackChunkName: "Login" */ "./views/Login.vue"),
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "./views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

Vue.use(Router);
const router = new Router({
  // mode: 'history',
  routes: routePaths,
});
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line
  console.log("from " + from.path + " to " + to.path);
  if (
    to.path.split("/").length > 1 &&
    (to.path.split("/")[1] == "login" ||
      to.path.split("/")[1] == "signup" ||
      to.path.split("/")[1] == "loginbda" ||
      to.path.split("/")[1] == "loginold" ||
      to.path.split("/")[1] == "404" ||
      to.path.split("/")[1] == "publicationplans" ||
      (localStorage.getItem(STORAGE.ROUTE_LIST) || "")
        .split(",")
        .includes(to.path.split("/")[1]) ||
      to.path.split("/")[1] == "coupon" ||
      to.path.split("/")[1] == "editor" ||
      to.path.split("/")[1] == "help" ||
      to.path.split("/")[1] == "fb" ||
      to.path.split("/")[1] == "question-bank" ||
      to.path.split("/")[1] == "printer" ||
      to.path.split("/")[1] == "institute-app")
  ) {
    NProgress.start();
    // eslint-disable-next-line
    const tooltips = document.getElementsByClassName("material-tooltip");
    let i = tooltips.length;
    while (i--) {
      tooltips[i].parentNode.removeChild(tooltips[i]);
    }
    next();
  } else {
    if (from.path == "/") {
      router.replace(
        "/" +
          (localStorage.getItem(STORAGE.ROUTE_LIST) || "login").split(",")[0]
      );
    } else {
      // eslint-disable-next-line
      M.toast({ html: "You dont have access to that page" });
    }
  }
});
router.afterEach((to) => {
  document.title = to.name + " | Mobishaala Teacher";
  NProgress.done();
});
export default router;

<template>
  <div class="my-card">
    <h4>Edit Logo</h4>
    <p>Upload new logo. Select a square image of size 1024x1024</p>
    <div class="row">
      <div class="col s12">
        <img
          height="250"
          width="250"
          class="bordered"
          v-bind:src="institutionDetails.logo"
          v-if="institutionDetails.logo"
        />
        <img
          height="250"
          width="250"
          class="bordered"
          id="selectedImage"
          v-if="imageFile != null"
        />
      </div>

      <div class="col s12">
        <img
          style="width: 35px; margin-bottom: -14px"
          :src="imageFile != null ? img_done : img_done_grey"
        />
        <a
          @click="$refs.input_image.click()"
          class="waves-effect waves-light blue btn"
          >Upload logo</a
        >
        <span style="margin: 0 20px"> or </span>
        <a
          @click="$refs.imagePicker.open()"
          class="waves-effect waves-light blue btn"
          >Create logo</a
        >
      </div>
      <br />
      <NextButton @on-click="submit($event)" :isBusy="isBusy">Save</NextButton>
      <!-- <a v-on:click="submit()" class=" right waves-effect waves-light blue btn-large">Update</a> -->
      <router-link
        to="/app"
        class="right waves-effect waves-light red btn-large"
        style="margin-right: 16px"
        >Cancel</router-link
      >
    </div>
    <input
      class="hidden"
      type="file"
      ref="input_image"
      @change="onLogoFileSelected"
      accept="image/x-png, image/gif, image/jpeg"
    />
    <EditorModal
      categoryTypeId="2"
      ref="imagePicker"
      @image-generated="imageSelected"
      :prefillObject="prefill"
      v-if="prefill != null"
    />
  </div>
</template>
<style scoped>
.hidden {
  display: none;
}
.bordered {
  border: 1px solid black;
}
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 30px;
  line-height: 64px;
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}
</style>
<script>
import axios from "axios";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import NextButton from "@/components/Common/NextButton.vue";
import img_done from "@/assets/img/done.svg";
import img_done_grey from "@/assets/img/done_grey.svg";
import EditorModal from "@/components/TemplateGenerator/EditorModal.vue";

export default {
  props: ["institutionDetails"],
  mounted() {
    // document
    //   .querySelector("#logo")
    //   .addEventListener("change", this.onLogoFileSelected);
    // eslint-disable-next-line
    console.log("mounted");
    // eslint-disable-next-line
    M.updateTextFields();
    
    this.prefill = {
      name: this.institutionDetails.name,
    };
  },
  updated() {
    // eslint-disable-next-line
    console.log("updated");
    // eslint-disable-next-line
    M.updateTextFields();
  },
  watch: {
    institutionDetails(newVal) {
      this.prefill = {
        name: newVal.name,
      };
    },
  },
  components: {
    NextButton,
    EditorModal,
  },
  data() {
    return {
      prefill: null,
      isBusy: false,
      imageFile: null,
      img_done: img_done,
      img_done_grey: img_done_grey,
    };
  },
  methods: {
    imageSelected: function (blob) {
      const vm = this;
      vm.imageFile = blob;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(blob);
    },
    onLogoFileSelected: function (e) {
      if (e.target.value == "") {
        // eslint-disable-next-line
        console.log("Nothing selected");
        return;
      }
      var file = e.target.files[0];
      this.imageFile = file;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    submit: function () {
      if (this.imageFile == null) {
        // eslint-disable-next-line
        M.toast({ html: "Select Logo" });
        return;
      }
      const formData = new FormData();
      formData.append("name", this.institutionDetails.name);
      // formData.append("logo", logo.files[0]);
      formData.append(
        "logo",
        this.imageFile,
        "image" + new Date().getTime() + ".jpg"
      );

      const vm = this;
      vm.isBusy = true;
      axios
        .patch(URL.ORG_APP, formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
            "content-type": "multipart/form-data",
          },
        })
        .then(function (response) {
          vm.isBusy = false;
          vm.$emit("institute-data-update");
          vm.$router.push("/app");
          // eslint-disable-next-line
          M.toast({ html: "Success" });
          // eslint-disable-next-line
          console.log(response);
        })
        .catch((error) => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
  },
};
</script>


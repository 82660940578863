<template>
  <div>
    <BaseModal ref="modal" class="baseModal" @onClose="onModalClose">
      <div class="modal-content" style="padding: 0">
        <div class="row">
          <div class="col s12" style="padding: 16px">
            Current Action : {{ action }}
          </div>

          <ul id="tabs-swipe" class="tabs" ref="tabs">
            <li class="tab">
              <a class="blue-text" href="#test-swipe-1">Web URL</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-2">Whatsapp Link</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-3">Dial</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-4">Email</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-5">Plan</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-6">All Plans</a>
            </li>
            <li class="tab">
              <a class="blue-text" href="#test-swipe-7">Popup Video</a>
            </li>
          </ul>
          <div id="test-swipe-1" class="col s12">
            <div class="input-field col s12">
              <input ref="webURL" id="webURL" type="text" class="validate" />
              <label for="webURL">Web URL</label>
            </div>
            <a
              class="waves-effect waves-light btn blue right"
              @click="generate(1)"
              >Save</a
            >
          </div>
          <div id="test-swipe-2" class="col s12">
            <div class="waHolder">
              <div class="input-field col s12">
                <input id="waNumber" ref="waNumber" type="text" />
                <label for="waNumber">
                  10 digit Mobile Number
                  <span class="red-text">&nbsp;*</span>
                </label>
              </div>
              <div class="input-field col s12">
                <textarea
                  id="waMessage"
                  ref="waMessage"
                  class="materialize-textarea"
                ></textarea>
                <label for="waMessage">Message</label>
              </div>
              <a
                class="waves-effect waves-light btn blue right"
                @click="generate(2)"
                >Save</a
              >
            </div>
          </div>
          <div id="test-swipe-3" class="col s12">
            <div class="input-field col s12">
              <input id="dialNumber" ref="dialNumber" type="text" />
              <label for="dialNumber">
                10 digit Mobile Number
                <span class="red-text">&nbsp;*</span>
              </label>
            </div>
            <a
              class="waves-effect waves-light btn blue right"
              @click="generate(3)"
              >Save</a
            >
          </div>
          <div id="test-swipe-4" class="col s12">
            <div class="waHolder">
              <div class="input-field col s12">
                <input id="email" ref="email" type="text" />
                <label for="email"> Email </label>
              </div>
              <div class="input-field col s12">
                <textarea
                  id="emailSubject"
                  ref="emailSubject"
                  class="materialize-textarea"
                ></textarea>
                <label for="emailSubject">Subject</label>
              </div>
              <div class="input-field col s12">
                <textarea
                  id="emailMessage"
                  ref="emailMessage"
                  class="materialize-textarea"
                ></textarea>
                <label for="emailMessage">Message</label>
              </div>
              <a
                class="waves-effect waves-light btn blue right"
                @click="generate(4)"
                >Save</a
              >
            </div>
          </div>
          <div id="test-swipe-5" class="col s12">
            <div class="input-field col s12">
              <select v-model="planSelectedId" ref="planSelect">
                <option value="" disabled selected>Choose plan</option>

                <optgroup
                  :label="
                    categoryPlan.categoryOrder + '. ' + categoryPlan.category
                  "
                  v-for="categoryPlan in categoryPlanList"
                  :key="categoryPlan.id"
                >
                  <option
                    :value="plan.id"
                    v-for="plan in categoryPlan.plans"
                    :key="plan.id"
                  >
                    <span
                      :class="{
                        'grey-text': !plan.is_visible,
                        'orange-text': plan.is_private,
                      }"
                    >
                      {{ plan.order }}. {{ plan.name }} : ₹
                      {{ plan.price / 100 }}
                      {{ plan.is_visible ? "" : " (disabled)"
                      }}{{ plan.is_private ? " (private)" : "" }}
                    </span>
                  </option>
                </optgroup>
              </select>
              <label>Plan</label>
            </div>
            <div class="input-field col s12">
              <input id="coupon" ref="coupon" type="text" />
              <label for="coupon"> Coupon Code </label>
            </div>

            <a
              class="waves-effect waves-light btn blue right"
              @click="generate(5)"
            >
              Save
            </a>
          </div>
          <div id="test-swipe-6" class="col s12">
            <p>Redirect User to plan list page</p>
            <a
              class="waves-effect waves-light btn blue right"
              @click="generate(6)"
            >
              Save
            </a>
          </div>
          <div id="test-swipe-7" class="col s12">
            <p>Show a popup video</p>
            <div class="col s12">
              <div class="col s12 m4">
                <MultiSourceVideo
                  v-if="popupVideoVideo != null"
                  style="border: 1px lightgrey solid"
                  :isCleanMode="true"
                  :videoData="popupVideoVideo"
                  :currentSelectedVideoId="popupVideoVideo.id"
                />
              </div>
              <div class="btn blue col s8" @click="$refs.videoPicker.open()">
                Pick Video
              </div>
            </div>
            <p>
              <label>
                <input type="checkbox" v-model="popupVideoIsMandatory" />
                <span
                  >Mandatory - Cannot Close, have to wait till the video is
                  completed</span
                >
              </label>
            </p>
            <p>
              <label>
                <input
                  type="checkbox"
                  v-model="popupVideoIsVideoControlsShown"
                />
                <span>Video Controls</span>
              </label>
            </p>
            <p>
              <label>
                <input type="checkbox" v-model="popupVideoIsActionAvailable" />
                <span>Action Button</span>
              </label>
            </p>
            <div v-if="popupVideoIsActionAvailable" class="row">
              <div class="input-field col s12">
                <input placeholder="Action" v-model="popupVideoIsAction" id="action_url" type="text">
                <label for="action_url">Action</label>
              </div>
              <div class="input-field col s12">
                <input placeholder="Action Button Name" id="action_url" v-model="popupVideoIsActionButton" type="text">
                <label for="action_url">Action Button Name</label>
              </div>
            </div>

            <a
              class="waves-effect waves-light btn blue right"
              @click="generate(7)"
            >
              Save
            </a>
          </div>
        </div>
      </div>
    </BaseModal>
    <VideoPickerModal @selectedVideo="onSelectedVideo" ref="videoPicker" />
  </div>
</template>
<script>
import VideoPickerModal from "@/components/Tests/VideoPickerModal.vue";
import MultiSourceVideo from "@/components/VideoPlayer/MultiSourceVideo.vue";
import BaseModal from "@/components/Common/BaseModal.vue";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import axios from "axios";
import { handleNetworkError } from "@/network/ErrorHandler.js";

export default {
  components: {
    BaseModal,
    MultiSourceVideo,
    VideoPickerModal,
  },
  data() {
    return {
      isOpen: false,
      action: "",
      page: 1,
      categoryPlanList: [],
      planSelectedId: "",

      popupVideoIsMandatory: false,
      popupVideoIsVideoControlsShown: false,
      popupVideoIsActionAvailable: false,
      popupVideoIsAction: "",
      popupVideoIsActionButton: "Click Here",
      popupVideoVideo: null,
    };
  },
  mounted() {
    //eslint-disable-next-line
    M.Tabs.init(this.$refs.tabs, {});
    this.getAllPlans();
  },
  watch: {
    popupVideoIsActionAvailable(newVal){
      if(newVal){
        this.popupVideoIsAction = ""
      }
    }
  },
  methods: {
    actionGenerated(action) {
      this.popupVideoIsAction = action;
    },
    onSelectedVideo(topic) {
      this.popupVideoVideo = topic.video || null;
    },
    generate(type) {
      if (type == 1) {
        let value = this.$refs.webURL.value;
        if (value == "") {
          // eslint-disable-next-line
          M.toast({ html: "Enter URL" });
          return;
        }
        this.emit(value);
      } else if (type == 2) {
        let mobile = this.$refs.waNumber.value;
        let message = this.$refs.waMessage.value;
        if (mobile == "" || mobile.length != 10) {
          // eslint-disable-next-line
          M.toast({ html: "Enter 10 digit number" });
          return;
        } else if (message == "") {
          // eslint-disable-next-line
          M.toast({ html: "Enter message" });
          return;
        }

        this.emit(
          "https://wa.me/91" + mobile + "/?text=" + encodeURIComponent(message)
        );
      } else if (type == 3) {
        let mobile = this.$refs.dialNumber.value;
        if (mobile == "" || mobile.length != 10) {
          // eslint-disable-next-line
          M.toast({ html: "Enter 10 digit number" });
          return;
        }
        this.emit("tel:" + mobile);
      } else if (type == 4) {
        let email = this.$refs.email.value;
        let emailSubject = this.$refs.emailSubject.value;
        let emailMessage = this.$refs.emailMessage.value;
        if (email == "") {
          // eslint-disable-next-line
          M.toast({ html: "Enter email" });
          return;
        }

        if (emailSubject == "") this.emit("mailto:" + email);
        else
          this.emit(
            "mailto:" +
              email +
              "?subject=" +
              encodeURIComponent(emailSubject) +
              "&body=" +
              encodeURIComponent(emailMessage)
          );
      } else if (type == 5) {
        let planId = this.planSelectedId;
        let coupon = this.$refs.coupon.value;

        if (planId == "") {
          // eslint-disable-next-line
          M.toast({ html: "Choose Plan" });
          return;
        }
        if (coupon == "")
          this.emit(
            "https://student.mobishaala.com/link/" +
              localStorage.getItem(STORAGE.ORG_APP) +
              "/plans/" +
              "?id=" +
              encodeURIComponent(planId)
          );
        else
          this.emit(
            "https://student.mobishaala.com/link/" +
              localStorage.getItem(STORAGE.ORG_APP) +
              "/plans/" +
              "?id=" +
              encodeURIComponent(planId) +
              "&code=" +
              encodeURIComponent(coupon)
          );
      } else if (type == 6) {
        this.emit(
          "https://student.mobishaala.com/link/" +
            localStorage.getItem(STORAGE.ORG_APP) +
            "/plan-list/"
        );
      } else if (type == 7) {
         if (this.popupVideoVideo == null) {
          // eslint-disable-next-line
          M.toast({ html: "Choose Video" });
          return;
        }
        this.emit(
          "https://student.mobishaala.com/link/" +
            localStorage.getItem(STORAGE.ORG_APP) +
            "/popup-video/?url="+encodeURIComponent(this.popupVideoVideo.dash)+
            "&mandatory="+encodeURIComponent(this.popupVideoIsMandatory)+
            "&controls="+encodeURIComponent(this.popupVideoIsVideoControlsShown)+
            "&button="+encodeURIComponent(this.popupVideoIsActionButton)+
            "&action="+encodeURIComponent(this.popupVideoIsAction)
        );
      }
    },
    emit(value) {
      this.$emit("generated", value);
      this.close();
    },
    onModalClose() {
      this.isOpen = false;
    },
    open(currentAction) {
      this.action = currentAction;
      //   this.$refs.webURL.value = currentAction;
      this.isOpen = true;
      this.$refs.modal.open();
    },
    close() {
      this.isOpen = false;
      this.$refs.modal.close();
    },
    getAllPlans: function () {
      const vm = this;
      vm.listLoaderState = 0;
      axios
        .get(URL.PLANS, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
          },
        })
        .then(function (response) {
          // eslint-disable-next-line
          console.log(response);

          var categoryPlanList = response.data.data
            .map(function (x) {
              var xx = x;
              if (!xx.category || !xx.category.id) {
                xx.category = {
                  name: "No category",
                  id: "-1",
                  order: -1,
                };
              }
              return xx;
            })
            .sort(function (a, b) {
              if (a.category.id < b.category.id) return -1;
              if (a.category.id > b.category.id) return 1;
              return 0;
            });
          var previousTag = null,
            currentTag = null;
          var categorizedList = [];
          var categorizedSubList = [];
          for (var i = 0; i < categoryPlanList.length; i++) {
            if (
              categoryPlanList[i].category &&
              categoryPlanList[i].category.id
            ) {
              currentTag = categoryPlanList[i].category;
            } else {
              currentTag = {
                name: "No category",
                id: "-1",
                order: -1,
              };
            }

            if (previousTag == null) {
              //ignore case
            } else if (currentTag.id != previousTag.id) {
              categorizedList.push({
                id: previousTag.id,
                category: previousTag.name,
                categoryOrder: previousTag.order,
                plans: categorizedSubList.sort(function (a, b) {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                }),
              });
              categorizedSubList = [];
            }
            previousTag = currentTag;
            categorizedSubList.push(categoryPlanList[i]);
          }
          if (categorizedSubList.length != 0) {
            categorizedList.push({
              id: previousTag.id,
              category: previousTag.name,
              categoryOrder: previousTag.order,
              plans: categorizedSubList.sort(function (a, b) {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              }),
            });
            categorizedSubList = [];
          }
          vm.categoryPlanList = categorizedList.sort(function (a, b) {
            if (a.categoryOrder < b.categoryOrder) return -1;
            if (a.categoryOrder > b.categoryOrder) return 1;
            return 0;
          });

          vm.$nextTick(() => {
            // eslint-disable-next-line
            M.FormSelect.init(vm.$refs.planSelect, {
              dropdownOptions: {
                container: document.body,
                constrainWidth: false,
              },
            });
          });
        })
        .catch((error) => {
          //   vm.listLoaderState = 0;
          handleNetworkError(error, vm);
        });
    },
  },
};
</script>



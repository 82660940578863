export default [
    {
        name: "'Roboto', sans-serif",
        family: "Roboto"
    },
    {
        name: "'Hind', sans-serif",
        family: "Hind"
    },
    {
        name: "'Cabin', sans-serif",
        family: "Cabin"
    },
    {
        name: "'Prompt', sans-serif",
        family: "Prompt"
    },
    {
        name: "'Acme', sans-serif",
        family: "Acme"
    },
    {
        name: "'Bree Serif', serif",
        family: "Bree Serif"
    },
    {
        name: "'Concert One', cursive",
        family: "Concert One"
    },
    {
        name: "'Passion One', cursive",
        family: "Passion One"
    },
    {
        name: "'Niconne', cursive",
        family: "Niconne"
    },
    {
        name: "'Secular One', sans-serif",
        family: "Secular One"
    },
    {
        name: "'Changa', sans-serif",
        family: "Changa"
    },
    {
        name: "'Philosopher', sans-serif",
        family: "Philosopher"
    },
    {
        name: "'Carter One', cursive",
        family: "Carter One"
    },
    {
        name: "'Mitr', sans-serif",
        family: "Mitr"
    },
    {
        name: "'Sunflower', sans-serif",
        family: "Sunflower"
    },
    {
        name: "'Tenali Ramakrishna', sans-serif",
        family: "Tenali Ramakrishna"
    },
    {
        name: "'Rosario', sans-serif",
        family: "Rosario"
    },
    {
        name: "'Averia Serif Libre', cursive",
        family: "Averia Serif Libre"
    },
    {
        name: "'Montaga', serif",
        family: "Montaga"
    },
    {
        name: "'Radley', serif",
        family: "Radley"
    },
    {
        name: "'Mirza', cursive",
        family: "Mirza"
    }
]
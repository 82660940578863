import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyload from "vue-lazyload";
// import './registerServiceWorker'
import "materialize-css/dist/js/materialize.min.js";
import "materialize-css/dist/css/materialize.min.css";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

// Vue.use(axios)

Vue.use(VueLazyload);

Vue.mixin({
  methods: {
    disableInputScroll: function() {
      var inputList = document.getElementsByTagName("input");
      for (var i = 0; i < inputList.length; i++) {
        if (inputList[i].getAttribute("type") == "number") {
          inputList[i].addEventListener("mousewheel", function() {
            this.blur();
          });
        }
      }
    },
  },
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

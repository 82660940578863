<template>
  <div>
    <div class="my-card">
      <div class="row center">
        <h4>
          Add your banner
          <div style="color: rgba(0, 0, 0, 0.4); font-size: 1rem">1280x600</div>
        </h4>
        <div class="col s12 banner-holder">
          <vue-aspect-ratio ar="1280:600" class="banner">
            <img
              height="100%"
              width="100%"
              class="bordered"
              id="selectedImage"
            />
          </vue-aspect-ratio>
        </div>

        <div class="col s12" style="margin-top: 16px">
          <a
            @click="$refs.input_image.click()"
            class="waves-effect waves-light blue btn"
            >Upload banner</a
          >
          <br />
          <!-- <div style="margin: 10px 0px">or</div>
          <a
            @click="$refs.imagePicker.open()"
            class="waves-effect waves-light blue btn"
            >Create banner</a
          > -->
        </div>
        <div
          class="col s12"
          style="display: flex; justify-content: center; margin-top: 24px"
          v-if="imageFile != null"
        >
          <NextButton @on-click="submit($event)" :isBusy="isBusy"
            >Next</NextButton
          >
        </div>
      </div>
      <input
        class="hidden"
        type="file"
        ref="input_image"
        @change="onLogoFileSelected"
        accept="image/x-png, image/gif, image/jpeg"
      />
      <EditorModal
        categoryTypeId="1"
        ref="imagePicker"
        :prefillObject="prefillObject"
        @image-generated="imageSelected"
        v-if="prefillObject != null"
      />
    </div>
    <div class="my-card">
      <h4>Choose Banner</h4>
      <template-picker-generated
        categoryTypeId="1"
        :prefillObject="prefillObject"
        @template-selected="imageSelected"
        v-if="prefillObject != null"
      />
    </div>
  </div>
</template>
<style scoped>
.banner-holder {
  display: flex;
  justify-content: center;
}
.banner-holder .banner {
  width: 70%;
}
.hidden {
  display: none;
}
.my-card {
  margin: 16px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.my-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
  font-size: 30px;
  line-height: 64px;
}
.bordered {
  background: rgba(0, 0, 0, 0.1);
}
h4 {
  margin: 16px 0;
  color: #212121;
  font-size: 22px;
}

@media only screen and (max-width: 600px) {
  .banner-holder .banner {
    width: 100%;
  }
}
</style>

<script>
import VueAspectRatio from "vue-aspect-ratio";
import axios from "axios";
import URL from "@/constants/url.js";
import STORAGE from "@/constants/storage.js";
import { handleNetworkError } from "@/network/ErrorHandler.js";
import EditorModal from "@/components/TemplateGenerator/EditorModal.vue";
import NextButton from "@/components/Common/NextButton.vue";
import TemplatePickerGenerated from "@/components/TemplateGenerator/TemplatePickerGenerated.vue";

export default {
  props: ["institutionDetails", "bannerList"],
  components: {
    NextButton,
    VueAspectRatio,
    EditorModal,
    TemplatePickerGenerated,
  },
  data() {
    return {
      isBusy: false,
      imageFile: null,
      prefillObject: null,
    };
  },
  mounted() {
    this.prefillObject = {
      name: this.institutionDetails.name,
      logo: this.institutionDetails.logo,
      admin_name: this.institutionDetails.contact_person_name,
      admin_number: this.institutionDetails.contact_person_mobile,
      admin_email: this.institutionDetails.contact_person_email,
      address: this.institutionDetails.address,
    };
  },
  methods: {
    imageSelected: function (blob) {
      const vm = this;
      vm.imageFile = blob;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(blob);

      window.scrollTo(0, 0);
    },
    onLogoFileSelected: function (e) {
      if (e.target.value == "") {
        // eslint-disable-next-line
        console.log("Nothing selected");
        return;
      }
      var file = e.target.files[0];
      this.imageFile = file;
      var fileReader = new FileReader();
      fileReader.onload = function (evt) {
        document.getElementById("selectedImage").src = evt.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    emitInstituteDataUpdate: function () {
      this.$emit("institute-data-update");
    },
    submit() {
      if (this.imageFile == null) {
        // eslint-disable-next-line
        M.toast({ html: "Select banner" });
        return;
      }
      var biggestOrder = 0;
      if (this.bannerList && this.bannerList.data) {
        for (let i = 0; i < this.bannerList.data.length; i++) {
          if (this.bannerList.data[i].order > biggestOrder)
            biggestOrder = this.bannerList.data[i].order;
        }
      }
      biggestOrder = biggestOrder + 1;

      const formData = new FormData();
      formData.append(
        "image",
        this.imageFile,
        "image" + new Date().getTime() + ".png"
      );
      formData.append("order", biggestOrder);
      this.isBusy = true;
      const vm = this;
      axios
        .post(URL.BANNER_ADD, formData, {
          headers: {
            Authorization: "Token " + localStorage.getItem(STORAGE.TOKEN),
            "content-type": "multipart/form-data",
          },
        })
        .then(function () {
          vm.isBusy = false;
          vm.$emit("institute-data-update");
        })
        .catch((error) => {
          vm.isBusy = false;
          handleNetworkError(error, vm);
        });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"canvasHolder"},[_c('vue-aspect-ratio',{staticClass:"canvas-holder",attrs:{"ar":_vm.width + ':' + _vm.height,"width":_vm.calculatedCanvasWidth + 'px'}},[_c('div',{staticClass:"_canvas-back"},[_c('div',{ref:"_canvas",staticClass:"_canvas"},_vm._l((_vm.blocks),function(block,index){return _c('div',{key:block.id,ref:block.id,refInFor:true,staticClass:"item",class:{ active: _vm.selectedBlock.id == block.id },style:('z-index:' + index),attrs:{"id":block.id,"data-id":block.id},on:{"click":function($event){return _vm.selectBlock(block)},"contextmenu":function($event){return _vm.showContextMenuInit($event, block)}}},[(block.type == 'text')?_c('div',{style:('color:' +
                block.text.color +
                ';' +
                'background:' +
                block.text.background +
                ';' +
                'text-align:' +
                block.text.alignment +
                ';' +
                'font-size:' +
                block.text.fontSize +
                'px;' +
                'font-family:' +
                block.text.font +
                ';')},[(!block.text.isEdit)?_c('span',{class:{
                  'block-text-bold': block.text.isBold,
                  'block-text-italics': block.text.isItalics,
                },attrs:{"contentEditable":"true"},domProps:{"innerHTML":_vm._s(block.text.value)},on:{"input":function($event){return _vm.handleInput($event, block)}}}):_vm._e()]):_vm._e(),(block.type == 'image')?_c('img',{staticClass:"image-block",attrs:{"draggable":"false","crossOrigin":"anonymous","src":block.image.value}}):_vm._e(),(block.type == 'rectangle')?_c('div',{staticClass:"rectangle-block",style:('background:' + block.rectangle.background + ';')}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle vright vbottom js-resize-right js-resize-bottom"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle vright vtop js-resize-right js-resize-top"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle vleft vbottom js-resize-left js-resize-bottom"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle vleft vtop js-resize-left js-resize-bottom"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle eright js-resize-right"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle etop js-resize-top"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle ebottom js-resize-bottom"}):_vm._e(),(_vm.selectedBlock.id == block.id)?_c('div',{staticClass:"resize-handle eleft js-resize-left"}):_vm._e()])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
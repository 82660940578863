<template>
    <div class="holder" ref="holder">
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: ["aspectRatio"],
  mounted() {
    this.adjustHeight();
  },
  updated() {
    this.adjustHeight();
  },
  methods: {
    adjustHeight: function() {
      var currentWidth = this.$refs.holder.offsetWidth;
      this.$refs.holder.style.height = currentWidth / this.aspectRatio + "px";
    }
  }
};
</script>

<style scoped>
.holder {
  width: 100%;
}
</style>


import STORAGE from "@/constants/storage"

class Settings {

    updateAllSettings(settings){
        if('doubt_enabled' in settings) this.setDoubtEnabled(settings.doubt_enabled)
        if('max_video_file_size' in settings) this.setMaxVideoSize(settings.max_video_file_size)
        if('plan_enrollment' in settings) this.setPlanEnrolmentEnabled(settings.plan_enrollment)
        if('plan_enrollment_request' in settings) this.setPlanEnrolmentRequestEnabled(settings.plan_enrollment_request)
        if('plan_deactivation' in settings) this.setPlanDeactivationEnabled(settings.plan_deactivation)
    }


    getDoubtEnabled(){
        return (localStorage.getItem(STORAGE.SETTINGS_DOUBT_ENABLED) || false) == 'true'
    }
    setDoubtEnabled(value){
        return localStorage.setItem(STORAGE.SETTINGS_DOUBT_ENABLED, value)
    }
    getMaxVideoSize(){
        return parseInt(localStorage.getItem(STORAGE.SETTINGS_MAX_VIDEO_FILE_SIZE) || 10)
    }
    setMaxVideoSize(value){
        return localStorage.setItem(STORAGE.SETTINGS_MAX_VIDEO_FILE_SIZE, value)
    }
    getPlanEnrolmentRequestEnabled(){
        return (localStorage.getItem(STORAGE.SETTINGS_PLAN_ENROLMENT_REQUEST_ENABLED) || false) == 'true'
    }
    setPlanEnrolmentRequestEnabled(value){
        return localStorage.setItem(STORAGE.SETTINGS_PLAN_ENROLMENT_REQUEST_ENABLED, value)
    }
    getPlanEnrolmentEnabled(){
        return (localStorage.getItem(STORAGE.SETTINGS_PLAN_ENROLMENT_ENABLED) || false) == 'true'
    }
    setPlanEnrolmentEnabled(value){
        return localStorage.setItem(STORAGE.SETTINGS_PLAN_ENROLMENT_ENABLED, value)
    }
    getPlanDeactivationEnabled(){
        return (localStorage.getItem(STORAGE.SETTINGS_PLAN_DEACTIVATION_ENABLED) || false) == 'true'
    }
    setPlanDeactivationEnabled(value){
        return localStorage.setItem(STORAGE.SETTINGS_PLAN_DEACTIVATION_ENABLED, value)
    }
}

export default new Settings()
<template>
  <div>
    <m-canvas
      v-if="height != 10"
      ref="canvas"
      :initBlocks="initBlocks"
      :height="height"
      :width="width"
      :zoom="zoom"
      :isDebug="isDebug || templateId == ''"
      :prefillObject="prefillObject"
      :preRender="preRender"
      :key="templateId"
      @image-generated="handleImageGenerated"
    />
  </div>
</template>
<script>
// import WebFont from "webfontloader";
import fonts from "./fonts";
import MCanvas from "@/components/TemplateGenerator/MCanvas";
// import ZoomControl from "@/components/TemplateGenerator/navigational/ZoomControl";
import templates from "@/components/TemplateGenerator/templates";
import PouchDB from "pouchdb-browser";
var dbHistory = new PouchDB("template-history-db");
export default {
  props: {
    templateId: {
      type: String,
      default: "",
    },
    prefillObject: {
      type: Object,
      default: () => {},
    },
    isDebug: {
      type: Boolean,
      default: false,
    },
    preRender: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MCanvas,
    //  ZoomControl
  },
  data: () => ({
    fonts: fonts,
    showDrawer: true,
    zoom: -1,
    width: 10,
    height: 10,
    initBlocks: [],
    currentCategory: "1",
    downloadTypes: [
      {
        id: 0,
        name: "PNG",
        description: "High Quality, Big Size",
      },
      {
        id: 1,
        name: "JPEG",
        description: "Standard Quality, Small Size",
      },
    ],
  }),
  mounted() {
    this.loadStylesheet(
      "https://fonts.googleapis.com/css2?" +
        this.fonts
          .map((item) => "family=" + encodeURIComponent(item.family))
          .join("&") +
        "&display=swap"
    );

    // WebFont.load({
    //   google: {
    //     families: this.fonts.map((item) => item.family),
    //   },
    // });
    let tId = this.templateId || "live_broadcast_blank";
    let template = {};
    if (tId.substring(0, 4) == "hist") {
      this.getTemplateFromHistory(tId);
    } else {
      template = templates[tId].template;
      this.zoom = template.zoom;
      this.width = template.width;
      this.height = template.height;
      this.currentCategory = templates[tId].categoryId;
      this.initBlocks = template.blocks;
    }
  },
  methods: {
    handleImageGenerated($event) {
      this.$emit("image-generated", $event.url);
      if(this.preRender) return;
      let template = {
        _id: "hist_" + new Date().getTime()+"_"+ this.templateId,
        name: "History",
        rendered: $event.url,
        category: "",
        categoryId: this.currentCategory,
        template: $event.template,
        created_at: new Date().getTime()
      };
      dbHistory.put(template, function callback(err) {
        if (!err) {
          // eslint-disable-next-line
          console.log("Successfully posted a image!");
        } else {
          console.log(err);
        }
      });
    },
    async getTemplateFromHistory(tId) {
      try {
        let doc = await dbHistory.get(tId);
        let template = doc.template;
        this.zoom = template.zoom;
        this.width = template.width;
        this.height = template.height;
        this.currentCategory = doc.categoryId;
        this.initBlocks = template.blocks;
      } catch (e) {
        console.log(e);
      }
    },
    loadStylesheet(href) {
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.setAttribute("crossorigin", "anonymous");
      link.href = href;
      document.head.appendChild(link);
    },
    generateImage(type) {
      this.$refs.canvas.generateImage(type);
    },
  },
};
</script>